export const rowClass = ({opMode, confirmedUserID}) => {

return (data) => {

    return {
        'defaultRow': true,
        'dataTableNewMessageRow': opMode ?
            data?.hospCases?.some(hospCase => hospCase?.flags?.UnreadMessageOp) ? true : false
            :
            data?.hospCases?.find(hospCase => hospCase?.userID === confirmedUserID)?.flags?.UnreadMessageUser ? true : false
        ,
        'dataTableRejectedRow': opMode ?
            data?.hospCases?.some(hospCase => hospCase?.flags?.RejectedGOPbyUser) ? true : false
            :
            (data?.hospCases?.find(hospCase => hospCase?.userID === confirmedUserID)?.flags?.RejectedInvoicebyOp
                ||
                data?.hospCases?.find(hospCase => hospCase?.userID === confirmedUserID)?.flags?.RejectedProformabyOp
                ||
                data?.hospCases?.find(hospCase => hospCase?.userID === confirmedUserID)?.flags?.MissingDocs

            )

                ? true : false
    }
}

}