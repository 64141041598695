import React from 'react';
import { ActivityLogItem } from './ActivityLogItem';
import { usePatientActivityLogQuery } from '../../../hooks/usePatientActivityLogQuery';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

export const ActivityLog = ({
    RBKCaseNo,
    setformOpen,
    setselectedRow,
    setSelectedTab,
    setmodalPosition,
    setFormCreateMode,
    mainActivityLog=true,
    disableClick=false
 }) => {

    const activityLogFetchQuery = usePatientActivityLogQuery({RBKCaseNo: !!RBKCaseNo ? RBKCaseNo : null } );

    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    return (<div className='InfoActivityLogSection'>

    <div style={{width:'100%', justifyContent:'flex-end'}}>
        <Typography variant='h6' sx={{
            color: 'white',
            fontWeight: 'bold',
            borderBottom: '1px solid white',
            marginBottom: '1rem'
        }}>Recent Activity</Typography>
    </div>

        {activityLogFetchQuery.isSuccess && activityLogFetchQuery?.data?.data?.map((log, index) => {
            return (




                <ActivityLogItem key={`ActivityLogItem-${index}`}
                setformOpen={setformOpen}
                setselectedRow={setselectedRow}
                setSelectedTab={setSelectedTab}
                setmodalPosition={setmodalPosition}
                setFormCreateMode={setFormCreateMode}
                disableClick={disableClick}

                    log={log}
                    index={index}
                    hospitalLabel={
                        hospitalUserListData?.data?.find((hosp) => {return (hosp.value === log.hospital)})?.label

                    }
                    mainActivityLog={mainActivityLog}
                    />


            );
        })}

    </div>);
}
