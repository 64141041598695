

import React from 'react';

import {
    Typography,

    Button


} from '@mui/material';

import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';

const MailSelectorButton = styled(Button)({

    background: 'transparent',
    padding: '0px',
    border: 'none',
    width: '100%',
    borderRadius: '0px',
    '&:hover': {
        background: 'rgba(255,255,255,0.2)',
        boxShadow: 'none',
        border: 'none',
        outline: 'none'
    }

});

const circleRadius = 4;
const elementHeight = 35;
const subjectStringCap = 45;

const MailSelector = ({
    selectedRow,
    selectedMailID,
    setSelectedMailID,
    orderedMailTreeArray
}) => {


    const handleMailSelection = (mailID) => () => {
        setSelectedMailID(mailID);
    };

    const strokeColor = 'white';

    return (<>


        <div style={{ justifyContent: 'center', padding: '14px 4px', width: '100%', maxWidth: '100%',background:'linear-gradient(19deg, #00000066, #2b2e3569)' }}>

            <div style={{
                background: 'transparent',
                padding: '0px',
                border: 'none',
                width: '100%',
                borderRadius: '0px',
            }} >
                <div key={`mailTitle-root`} className='mailSelectorElement'>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: { strokeColor }, }}>
                        {`Incoming Mail`}
                    </Typography>
                    <EmailIcon sx={{ color: { strokeColor }, fontSize: '1.5rem', marginLeft: '5px' }} />
                </div>
            </div>
            <div style={{
                background: 'transparent',
                padding: '0px',
                border: 'none',
                width: '100%',
                borderRadius: '0px',
                borderBottom: '3px solid white',
            }} >
                <div key={`mailTitle-root`} className='mailSelectorElement'>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', color: { strokeColor }, }}>
                        {`NEW PATIENT / ${selectedRow.name} / ${selectedRow.RBKCaseNo}`}
                    </Typography>
                </div>
            </div>


            <div className='mailSelectionContainer'>


                {
                    orderedMailTreeArray.map((mail, index) => {
                        const nestedLevel = mail?.references?.length;
                        const nestedDepth = 25 + (nestedLevel - 1) * (25);
                        const parsedDate = mail?.date?.split('T')[0];
                        const parsedTime = mail?.date?.split('T')[1]?.split('.')[0]?.split(':')[0] + ':' + mail?.date?.split('T')[1]?.split('.')[0]?.split(':')[1];
                        const subjectLine = mail?.subject?.length > subjectStringCap ? mail?.subject?.slice(0, subjectStringCap).concat('...') : mail?.subject;

                        const isConnectedAbove = (index > 0 && (mail?.inReplyTo === orderedMailTreeArray[index - 1].messageId)) ? true : false;
                        const isConnectedBelow = (index < orderedMailTreeArray.length - 1 && (mail?.messageId === orderedMailTreeArray[index + 1].inReplyTo)) ? true : false;
                        const isLastElement = index === orderedMailTreeArray.length - 1 ? true : false;
                        const nestedLine = mail?.nestedLine; // [ 2, 3 ,5] array of numbers for each nested line to render

                        return (
                            <MailSelectorButton
                                sx={{
                                    background: selectedMailID === mail?.messageId ? 'rgba(255,255,255,0.4)' : 'transparent',
                                }}
                                onClick={handleMailSelection(mail?.messageId)}>
                                <div key={`mailTitle-${index}`} className='mailSelectorElement'>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>

                                        <svg height={elementHeight} width={`${nestedDepth}`} >
                                            <line x1="1" y1={0} x2="1" y2={isLastElement ? elementHeight / 2 : elementHeight} stroke={strokeColor} strokeWidth={2} />

                                            <line x1="0" y1={elementHeight / 2} x2={`${nestedDepth}`} y2={elementHeight / 2} stroke={strokeColor} strokeWidth={2} strokeDasharray={'2,2'} />

                                            {nestedLine && nestedLine.length > 0 &&
                                                nestedLine.map(({ depth, type }) => {
                                                    return (
                                                        <line x1={`${(depth - 1) * (25) - circleRadius}`} y1={type === 'top' ? elementHeight / 2 : elementHeight} x2={`${(depth - 1) * (25) - circleRadius}`} y2={type === 'bottom' ? elementHeight / 2 : 0} stroke={strokeColor} strokeWidth={2} />
                                                    )
                                                })
                                            }

                                            {isConnectedBelow && <line x1={`${nestedDepth - circleRadius}`} y1={elementHeight / 2} x2={`${nestedDepth - circleRadius}`} y2={elementHeight} stroke={strokeColor} strokeWidth={2} />}

                                            <circle cx={`${nestedDepth - circleRadius}`} cy={elementHeight / 2} r={circleRadius} fill="white" />

                                            {isConnectedAbove &&
                                                <line x1={`${nestedDepth - (25 + circleRadius)}`} y1={elementHeight / 2} x2={`${nestedDepth - (25 + circleRadius)}`} y2={0} stroke={strokeColor} strokeWidth={2} />}

                                        </svg>

                                    </div>
                                    <span style={{
                                        fontSize: '1rem', fontWeight: 'bold', color: { strokeColor }, marginLeft: '5px',
                                        overflow: 'hidden', maxWidth: '350px', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}>
                                        {subjectLine}
                                    </span>

                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: { strokeColor }, marginLeft: 'auto' }}>
                                        {`${parsedDate}  ${parsedTime}`}
                                    </Typography>

                                </div>
                            </MailSelectorButton>
                        )
                    })
                }



            </div>

        </div>

    </>)

}

/*
<svg height="50" width="5" >
                                        <line x1="2.5" y1="0" x2="2.5" y2="50" style={{ stroke: "white", strokeWidth: 5 }} />
                                    </svg>

                                    <svg height="5" width={`${nestedDepth}px`} >
                                        <line x1="0" y1="2.5" x2={`${nestedDepth}px`} y2="2.5" style={{ stroke: "white", strokeWidth: 5 }} />
                                    </svg>

                                    {isConnectedAbove && <svg width="25" height="50">
                                        <path d="M 25,25
                                                 L 0,25
                                                 L 0,0
                                                 "
                                            fill="none"
                                            stroke="white"
                                            strokeWidth={5}
                                        />

                                    </svg>}
                                    <svg width="25" height="50">
                                        <circle cx="12.5" cy="25" r="8" fill="white" />
                                        <path d="M 12.5,25
                                                 L 0,25"
                                            fill="none"
                                            stroke="white"
                                            strokeWidth={5}
                                        />

                                       {isConnectedBelow &&  <path d="M 12.5,25
                                                 L 12.5,50"
                                            fill="none"
                                            stroke="white"
                                            strokeWidth={5}
                                        />}

                                    </svg>




 <svg height="50" width="25" >
                                        <line x1="2.5" y1="0" x2="2.5" y2="25" style={{ stroke: "white", strokeWidth: 2 }} />
                                        <line x1="0" y1="25" x2="25" y2="25" style={{ stroke: "white", strokeWidth: 2 }} />
                                    </svg>
*/
export default MailSelector;

























