

import React, { useState, useEffect } from 'react';

import {
    Typography,
    IconButton,
    Divider,
    Button


} from '@mui/material';

import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import MoreIcon from '@mui/icons-material/More';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const MailAccordion = styled((props) => ( // Accordion Settings
    <Accordion disableGutters elevation={12} square {...props} />
))(({ theme }) => ({

    background: 'rgb(73 73 75)',
    border: `none`,
    outline: '2px solid rgba(34, 34, 37, 0.5)',
    boxShadow: 'inset 0px -15px 15px -10px rgba(0, 0, 0, 0.25)',
    '&:not(:last-child)': {
        borderBottom: '2px solid rgba(34, 34, 37, 0.5)',
    },
    '&:before': {
        display: 'none',
    },
}));



const MailDisplay = ({


    orderedMailTreeArray,
    selectedRow,
    selectedMailID,
    setSelectedMailID

}) => {

    const [mailResponseDisplay, setMailResponseDisplay] = useState(false);

    const handleMailSelection = (mailID) => () => {
        setSelectedMailID(mailID);
    };


    return (<>


        <div className='mailWrapper'>
            <div
                style={{ position: 'absolute', top: '10px', left: '-10px', zIndex: '1000' }}
            >


            </div>

            {orderedMailTreeArray?.map((mail, index) => {

                const innerHTML = { __html: mail?.html }
                const parsedDate = mail?.date?.split('T')[0];
                const parsedTime = mail?.date?.split('T')[1]?.split('.')[0]?.split(':')[0] + ':' + mail?.date?.split('T')[1]?.split('.')[0]?.split(':')[1];

                return (

                    <MailAccordion
                        key={`mail-accordion-root-${mail?.messageId}`}
                        onClick={handleMailSelection(mail?.messageId)}
                        expanded={selectedMailID === mail?.messageId}
                        sx={{ background: selectedMailID === mail?.messageId ? 'rgb(73, 73, 85)' : 'rgb(63, 63, 64)', width: '100%' }}

                    >
                        <AccordionSummary

                            expandIcon={<ExpandMoreIcon />}

                            id={`mail-accordion-${mail?.messageId}`}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', width: '100%', paddingRight: '12px',gap:'1rem' }}>

                                <div style={{ display: 'flex', flexDirection: 'row' , gap:'0.25rem'}}>
                                    <Typography>
                                        {mail?.subject}
                                    </Typography>
                                    {selectedMailID === mail?.messageId &&
                                    <Button
                                        disableRipple
                                        sx={{
                                            background: 'transparent',
                                            '&:hover': { background: 'transparent', border: 'none', outline: 'none', boxShadow: 'none' },
                                        }} onClick={() => { setMailResponseDisplay(!mailResponseDisplay) }}>
                                        {mailResponseDisplay ?
                                            <MoreIcon
                                                sx={{ transform: 'rotate(180deg)' }}
                                            />
                                            :
                                            <MoreIcon sx={{ transform: 'rotate(180deg)', color: 'gray' }} />}
                                    </Button>}
                                </div>
                                <Typography sx={{ fontSize: '0.8rem', color: '#b5b5b5' }}>
                                    {`${parsedDate} ${parsedTime}`}
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                id='mailContent'
                                className={`mailContainer ${!mailResponseDisplay ? 'disableResponse' : ''}`}
                                dangerouslySetInnerHTML={innerHTML}>
                            </div>
                         {   /*
                            <iframe
                            width={'100%'}
                            height={2200}
                            srcdoc={mail?.html}

                            />
*/}

                        </AccordionDetails>
                    </MailAccordion>


                )

            })}


        </div>

    </>)

}

export default MailDisplay;

























