
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useUploadReceiptMutation = ({setsnackbarStatus, setUploadProgress}) => {

    const queryClient = useQueryClient();



    return useMutation({
        mutationFn: ({ formData, selectedHospUpload }) => {


            return axios.post(
                `${config.API_URL}/payment/pushBlob?selectedUserID=${selectedHospUpload}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'] });
            queryClient.invalidateQueries({ queryKey: ['ActivePaymentListGroup'] });
            queryClient.invalidateQueries({ queryKey: ['ActivePaymentListAggr']});


        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File`,
                    severity: 'error'

                }
            )
        },

    })






}