import React from "react";
import { Typography } from '@mui/material';
import Error from "@mui/icons-material/Error";

export const formatLDMCaseNo = (patientRow) => {

    return (
        <div style={{display:'flex',flexDirection:'row'}}>
            <Typography sx={{ fontSize: '1rem' }}>
                {`${patientRow?.LDMCaseNo ? patientRow.LDMCaseNo : 'N/A'}`}
            </Typography>
          { !patientRow?.caseNumber &&  <Error sx={{ ml: '5px' }} htmlColor='#ff9900' /> }
        </div>
    )
}
