import React from "react";
import { Typography, Badge } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import MailIcon from '@mui/icons-material/Mail';
import CommentIcon from '@mui/icons-material/Comment';

export const formatCaseNo = ({ confirmedUserID, opMode, enableBadge = true }) => {
    return (patientRow) => {
        const userCase = patientRow?.hospCases?.find(hospCase => hospCase.userID === confirmedUserID);

        let newMessageCount = 0;

        if (opMode && patientRow?.hospCases?.some(hospCase => hospCase.comments?.length > 0)) {
            patientRow?.hospCases?.forEach(hospCase => {
                for (let i = hospCase?.comments?.length - 1; i >= 0; i--) {
                    if (isNaN(parseInt(hospCase?.comments[i].by))) { // Count until the first comment by current User (for Operators it would be a string, hosps are of the form 100##)
                        //console.log(`Condition met at index ${i}`);
                        break;
                    } else { newMessageCount++; }
                }
            });
        } else if (userCase?.comments?.length > 0) {
            for (let i = userCase?.comments?.length - 1; i >= 0; i--) {
                if (userCase?.comments[i].by !== confirmedUserID) {
                    //console.log(`Condition met at index ${i}`);
                    break;
                } else { newMessageCount++; }
            }
        }

        return (

            patientRow?.caseNumber ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '100%', justifyContent:'flex-start' }}>
                    <Typography sx={{ marginRight: '15px', fontSize: '1rem' }}>
                        {`${patientRow?.RBKCaseNo}`}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem', justifyContent:'flex-end' }}>

                    {( opMode && enableBadge) &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            {
                                patientRow?.mail?.sort((a, b) => new Date(b.date) - new Date(a.date))[0].mailRead ?
                                    <Badge badgeContent={1} size='small' max={9} sx={{ transform: 'scale(0.95) translateY(4px)' }} color='error'
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MailIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} htmlColor='lightgreen' />
                                    </Badge>
                                    :
                                    patientRow?.mail &&
                                        <MailIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />
                                        }
                        </div>
                    }




                    {enableBadge &&
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {opMode ?
                                patientRow?.hospCases?.some(hospCase => hospCase.flags?.RejectedGOPbyUser) &&
                                    <ErrorIcon sx={{ mr: '5px' }} htmlColor='#ff9900' />


                                :
                                (userCase?.flags?.RejectedProformabyOp || userCase?.flags?.RejectedInvoicebyOp || userCase?.flags?.MissingDocs) &&
                                    <ErrorIcon sx={{ mr: '5px' }} htmlColor='#ff9900' />

                                    }
                            {opMode ?
                                patientRow?.hospCases?.some(hospCase => hospCase.flags?.UnreadMessageOp) ?
                                    <Badge badgeContent={newMessageCount} size='small' max={9} sx={{ transform: 'scale(0.95) translateY(4px)' }} color='error'
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <CommentIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} htmlColor='lightgreen' />
                                    </Badge>
                                    :
                                    patientRow?.hospCases?.some(hospCase => hospCase.comments?.length > 0) &&
                                        <CommentIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />


                                :
                                userCase?.flags?.UnreadMessageUser ?
                                    <Badge badgeContent={newMessageCount} max={9} sx={{ transform: 'scale(0.95)' }} color='error'
                                        size='small'
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <CommentIcon className='newMessageFlag' sx={{ transform: 'translateY(1px)' }} htmlColor='lightgreen' />
                                    </Badge>
                                    :
                                    userCase?.comments?.length > 0 && <CommentIcon sx={{ transform: 'translateY(1px)' }} htmlColor='white' />

                                        }
                        </div>
                    }




                </div>




            </div> : null


        );
    };
}
