
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';


const useUserDataMutation = ({ setsnackbarStatus }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ newData, username, reset }) => {

            return axios.post(
                `${config.API_URL}/operator/updateUserData`,
                 { newData, username, reset },
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (variables) => {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Password Updated: ${variables.data.password}`,
                    severity: 'success',
                    duration: 10000
                }
            )
            queryClient.invalidateQueries({ queryKey: ['userData'] });
        },
        onError: (error, variables) => {
            if (error.response.status !== 403) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Updating User Data`,
                        severity: 'error'
                    }
                )

            }
        },

    })
}

export { useUserDataMutation };