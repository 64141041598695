
import React from 'react';



import GOPDocument from '../../../PDF/GOPDocument';

import PDFCreator from '../../../PDF/PDFCreator';

import GOPInputForm from './GOPInputForm';
import FileSelectorHeader from '../../components/FileSelectorHeader';



const GOPPageComponent = ({
    lang,

    opMode,
    selectedhospCaseID,
    handleFieldChange,

    patientForm,
    dispatch,
   // handleReplaceForm,
    selectedRow,
    setselectedRow,
    setsnackbarStatus,

    onRowFileURLs,
    displayfileIndex,
    setdisplayfileIndex,
    docsMissingFlag,
    setdocsMissingFlag,
    updateFlagsMutation,
    fileDisplayPdfUrl,

}) => {



    return (
        (
            (
                <div className='GOPDocumentPageContainer'>

                    {opMode && <GOPInputForm
                        lang={lang}
                       // handleReplaceForm={handleReplaceForm}

                        setsnackbarStatus={setsnackbarStatus}
                        patientForm={patientForm}
                        dispatch={dispatch}
                        handleFieldChange={handleFieldChange}
                        selectedhospCaseID={selectedhospCaseID}
                        selectedRow={selectedRow}
                        setselectedRow={setselectedRow}
                    />
                    }
                    <div className='GOPpdfContainer'>

                        {opMode && <div className='HorizontalpdfContainer' style={{width:'50vw !important'}}>

                            <FileSelectorHeader
                                switchMode={false}
                                onRowFileURLs={onRowFileURLs}
                                displayfileIndex={displayfileIndex}
                                setdisplayfileIndex={setdisplayfileIndex}
                                docsMissingFlag={docsMissingFlag}
                                setdocsMissingFlag={setdocsMissingFlag}
                                selectedhospCaseID={selectedhospCaseID}
                                opMode={opMode}
                                updateFlagsMutation={updateFlagsMutation}

                            />

                            {onRowFileURLs?.length > 0 &&
                                <div className='docspdfContainer' >
                                    <iframe id='docsPage-pdf-docs' title="PDF Viewer Invoice" width="100%" height="600px" src={fileDisplayPdfUrl}></iframe>
                                </div>

                            }



                        </div>}



                        <div className='HorizontalpdfContainer' style={{width:'50vw !important'}}>
                            <PDFCreator
                                DocComponent={GOPDocument}
                                selectedRow={selectedRow}
                                hospCaseID={selectedhospCaseID}

                            />
                        </div>
                    </div>

                </div>
            ))
    )



}



export default GOPPageComponent;




