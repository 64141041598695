import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

export const useUpdateFiletypeMutation = ({ setselectedRow }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ RBKCaseNo, selectedUserID, filetype, blobUrl }) => {


            return axios.post(
                `${config.API_URL}/data/updateFiletype`,
                { RBKCaseNo, selectedUserID, filetype, blobUrl },
                {
                    withCredentials: true
                }
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['fileTokens'] });
            }, 1000);


            //setselectedRow(data.data);
        }
    });
}

