import React, { useRef, useState } from 'react';
import TextLanguage from '../../../../../data/TextLanguage.json';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import UploadIcon from '@mui/icons-material/Upload';
import { BorderLinearProgress } from './BorderLinearProgress';
import { ContextMenu } from 'primereact/contextmenu';
import {
    Button,
    Typography,
    useTheme,

} from '@mui/material';
import { useUpdateFiletypeMutation } from '../hooks/useUpdateFiletypeMutation';
import { TransparentButton } from '../../../../TransparentButton';
import { useUploadMutation } from '../hooks/useUploadMutation';
import { fileUploader } from '../../../../../functions/fileUploader';
import uploadTypes from '../../../../../data/uploadTypes';


const fileTypes = ['patientDoc', 'proforma', 'invoice', 'GOP', 'mailAttachment'];
const flagDisplayNames = {
    patientDoc: 'MissingDocuments',
    proforma: 'ProformaError',
    invoice: 'InvoiceError',
    GOP: 'GOPError',

};
const flagNames = {
    patientDoc: 'MissingDocs',
    proforma: 'RejectedProformabyOp',
    invoice: 'RejectedInvoicebyOp',
    GOP: 'RejectedGOPbyUser',

};
const fileDisplayNames = {
    patientDoc: 'Documents',
    proforma: 'Proforma',
    invoice: 'Invoice',
    GOP: 'GOP',
    mailAttachment: 'MailAttachment',
};


/* Flags
                UnreadMessageOp: false,
                UnreadMessageUser: false,
                RejectedProformabyOp: false,
                RejectedGOPbyUser: false,
                RejectedInvoicebyOp: false,
                MissingDocs: false,
*/
export const UploadedFilesComponent = ({ onRowFileURLs, selectedhospCaseID, selectedRow, setselectedRow, opMode, lang, setsnackbarStatus }) => {



    const [uploadFormProgress, setUploadFormProgress] = useState(0);
    const [uploadingFileType, setUploadingFileType] = useState(null);

    const uploadMutation = useUploadMutation({ setUploadFormProgress, setsnackbarStatus, setselectedRow })

    const updateFiletypeMutation = useUpdateFiletypeMutation({ setselectedRow });

    const [selectedFileBlobUrl, setSelectedFileBlobUrl] = useState(null);

    const handleFiletypeChange = (filetype) => {
        console.log({
            RBKCaseNo: selectedRow?.RBKCaseNo,
            selectedUserID: selectedhospCaseID,
            filetype: filetype,
            blobUrl: selectedFileBlobUrl.split('?')[0],

        });


        updateFiletypeMutation.mutate({
            RBKCaseNo: selectedRow?.RBKCaseNo,
            selectedUserID: selectedhospCaseID,
            filetype: filetype,
            blobUrl: selectedFileBlobUrl.split('?')[0],

        });

    }


    const handleFileUploader = (filetype) => () => {
        setUploadingFileType(filetype);
        fileUploader(uploadMutation, uploadTypes, { uploadingFileType: filetype, selectedhospCaseID, clickedRowCaseNo: selectedRow?.RBKCaseNo })();
    }


    const theme = useTheme();

    const contextMenuRef = useRef(null);
    const menuModel =  [

        {
            label: (<Typography sx={{ color: theme.palette.text.primary, fontSize: '0.85rem', fontWeight: 'bolder', p: 0, m: 0 }}>
                {`${TextLanguage['ConvertTo'][lang]}..`}
            </Typography>),
            items: [
                {
                    label: `${TextLanguage['Documents'][lang]}`,
                    command: () => handleFiletypeChange('patientDoc'),
                },
                {
                    label: `${TextLanguage['Proforma'][lang]}`,
                    command: () => handleFiletypeChange('proforma'),
                },
                {
                    label: `${TextLanguage['Invoice'][lang]}`,
                    command: () => handleFiletypeChange('invoice'),
                },
                {
                    label: `${TextLanguage['MailAttachment'][lang]}`,
                    command: () => handleFiletypeChange('mailAttachment'),
                }]
        }];


    const groupedFiles = onRowFileURLs?.reduce((result, file) => {
        const filetype = file['filetype'];
        // Create an array for the key if it doesn't exist
        if (!result[filetype]) { result[filetype] = [] }

        result[filetype].push(file);
        return result;
    }, {});



    return (
        <>
            {fileTypes.map((filetype, index) => {
                const isGOP = (filetype === 'GOP'); // GOP is not uploaded by the user, only render flag display
                const isMail = (filetype === 'mailAttachment');
                if (!opMode && isMail) return null;
                const fileExists = (groupedFiles && groupedFiles[filetype]?.length > 0) ? true : false;

                return (
                    <div key={`fileType-root-${index}-${filetype}`} style={{display:'flex',flexDirection:'column',width:'100%'}}>

                        {// Display Flags Section
                            (selectedhospCaseID && selectedRow?.hospCases?.find(hospCase => hospCase.userID === selectedhospCaseID)?.flags) &&
                                opMode ?
                                <>
                                    {selectedRow?.hospCases?.find(hospCase => hospCase.userID === selectedhospCaseID)?.flags?.[flagNames[filetype]] &&
                                        <div className='flagContainer'>
                                            <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                                            <Typography sx={{ color: 'yellow' }}>
                                                {TextLanguage[flagDisplayNames[filetype]][lang]}
                                            </Typography>
                                        </div>}
                                </>
                                :
                                <>
                                    {selectedRow?.hospCases?.find(hospCase => hospCase.userID === selectedhospCaseID)?.flags?.[flagNames[filetype]] &&
                                        <div className='flagContainer'>
                                            <WarningRoundedIcon sx={{ color: 'red', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                                            <Typography sx={{ color: 'yellow' }}>
                                                {TextLanguage[flagDisplayNames[filetype]][lang]}
                                            </Typography>
                                        </div>}
                                </>

                        }

                        {!isGOP &&
                            <>
                                <div className='formUploadTitleRow'>
                                    <span className='formUploadTextSpan'>
                                        {`${TextLanguage[fileDisplayNames[filetype]][lang]}`}
                                    </span>
                                    <div className='formUploadProgressBarContainer'>
                                        {(uploadMutation.isPending && uploadingFileType === filetype) &&
                                            <BorderLinearProgress variant='determinate' value={uploadFormProgress} sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} />}
                                    </div>
                                    {!isMail && <Button id='DocUploadButton' onClick={handleFileUploader(filetype)  } className='formUploadButton'>
                                        <UploadIcon sx={{ marginRight: '2px' }} />
                                        <Typography variant="button">
                                            {TextLanguage['Upload'][lang]}
                                        </Typography>
                                    </Button>}
                                </div>

                                {fileExists && <div className='formUploadTitleRow' style={{
                                    padding: '0.5rem 0.5rem',
                                    display: 'flex'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {groupedFiles[filetype]?.map((file, index) => {
                                            const fileName = file.originalname.length > 45 ? decodeURIComponent(file.originalname).slice(0, 45).concat('...') : decodeURIComponent(file.originalname);

                                            return (
                                                <div key={`fileType-div-${index}-${file?.originalname}`} style={{ display: 'flex', width: '100%', }}>
                                                   {opMode && <ContextMenu key={`filecm-${index}`} model={menuModel} ref={contextMenuRef} onHide={() => { }} />}
                                                    <TransparentButton
                                                        href={file.fileToken}
                                                        sx={{ borderRadius: 0, width: '100%', justifyContent: 'flex-start' }}
                                                        onContextMenu={(e) => {
                                                            if (!opMode) return;
                                                            setSelectedFileBlobUrl(file.fileToken);
                                                            e.preventDefault();
                                                            contextMenuRef.current.show(e);
                                                            console.log(e);
                                                        }}
                                                        className='fileLinks'>
                                                        {fileName}
                                                    </TransparentButton>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>}
                            </>}
                    </div>
                );
            })}
        </>
    );
};

  /*
                                                                                                        <a className='fileLinks' href={file.fileToken} download target="_self" rel="noopener noreferrer">
                                                                                                            {fileName}
                                                                                                        </a>
                                                                                                 */