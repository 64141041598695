import React, { useEffect, useState } from 'react';

import {
    Button,
    Typography,
    IconButton
} from '@mui/material';

import DateFilter from '../DateFilter';
import TextFilter from '../TextFilter';

import TextLanguage from '../../../data/TextLanguage.json';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useProcessCaseMutation } from '../../../hooks/useProcessCaseMutation';
import { useCompletePaymentListMutation } from '../../../hooks/useCompletePaymentListMutation';

import { PaymentListHeader } from './PaymentListHeader';

import { useUploadExcelMutation } from '../../../hooks/useUploadExcelMutation';
import { FilterSelector } from './FilterSelector';
import { useQueryClient } from '@tanstack/react-query';
import { fileUploader } from '../../../functions/fileUploader';
import { useSearchParams } from 'react-router-dom';
import { InlineGraph } from './InlineGraph';

const payrollTypeList = [
    { value: 0, label: 'Type A' },
    { value: 1, label: 'Type B' },

]

export const exrColor = '#f4452a';
export const usdColor = '#9cf42a';
export const countColor = '#ffffff';


export const DataTablePaymentHeader = ({
    setFilterToggle, filterToggle, lang, setSearchFilter, searchFilter, selectedRows,
    setActivePaymentList, setsnackbarStatus,

    ActivePaymentQuery, ActivePaymentState,

    parsedPaymentListHospQuery,


    archivePaymentListQuery,
    setPaymentListModalOpen, setmodalPosition, selectedArchiveHospitalID,
    setselectedArchiveHospitalID, selectedActiveHospitalID, setselectedActiveHospitalID,
    opMode
}) => {


    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    const currentPage = 'payment';
    const ProcessMutation = useProcessCaseMutation({ setsnackbarStatus, setActivePaymentList });
    const CompleteListMutation = useCompletePaymentListMutation({ setsnackbarStatus, setActivePaymentList, setPaymentListModalOpen });

    const handleCommitProcessed = async () => { // Move to processed after all files are uploaded to LDM
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'moveToProcessed' });
    }
    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadExcelMutation = useUploadExcelMutation({ setsnackbarStatus, setUploadProgress });

    const handleCreateActivePaymentList = async () => { // Create Active Payment List for Hospitals
        setPaymentListModalOpen(true);
        setmodalPosition('0%');
        /*
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'createActivePaymentList' });
*/
    };

    const handleCompletePayment = () => { //  Finish Payments on Active Payment List => Archive current Active Payment List
        CompleteListMutation.mutate({});
    }


    const onDateFilter = (filter) => {
        setSearchFilter({ ...searchFilter, ...filter });
        console.log(searchFilter);
    };

    const onTextFilter = (field) => (filter) => {
        setSearchFilter({ ...searchFilter, ...{ [field]: filter } });
        console.log(searchFilter);
    }




    /*
        const [payrollType, setPayrollType] = useState(payrollTypeList[0]?.value);
        const handlePayrollTypeSelect = (e) => {
            setPayrollType(e.target.value);
        }

    */

    return <div className='datatableHeader' id='divDTHeader'>



        <div className='datatableHeaderFilterRowContainer'>



            {([0, 1].includes(currentTab)) && <IconButton onClick={() => { setFilterToggle(!filterToggle); }}> <FilterAltIcon fontSize='large' htmlColor='white' /></IconButton>}

            {([0, 1].includes(currentTab)) && <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignContent: 'center', alignItems: 'center' }}>
                {hospitalUserListData?.status === 200 && <FilterSelector
                    filterLabel={"hospID"}
                    selectLabel={TextLanguage["FilterByHospital"][lang]}
                    optionsList={hospitalUserListData?.data}
                    setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter}
                />}
                {InsuranceCompanyListData?.status === 200 && <FilterSelector
                    filterLabel={"InsCo"}
                    selectLabel={TextLanguage["FilterByInsurance"][lang]}
                    optionsList={InsuranceCompanyListData?.data}
                    setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter}
                />}



            </div>}



            {currentTab === 0 && <Button onClick={fileUploader(uploadExcelMutation, '.xlsx,.xls,.xlsm,.xlsb')}
                className='DocDownloadButton'
                disableRipple={true}
                sx={{
                    height: 'auto', minWidth: '25px', minHeight: '25px', p: '0.5rem 1rem', m: '0px', zIndex: 355,
                    background: '#0c4eb045', border: 'none', outline: '1px solid #787878', boxShadow: 'none', color: 'white',
                    gap: '0.25rem',
                    '&:hover': {
                        background: '#0062f5a1',
                        boxShadow: 'none',
                        outline: '1px solid white',
                        border: 'none',
                    }
                }}
            >
                <Typography>
                    {TextLanguage["Payroll"][lang]}
                </Typography>
                <FileUploadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
            </Button>

            }



            {
                [2, 3, 4].includes(currentTab) &&
                <PaymentListHeader
                    lang={lang}
                    currentList={
                        currentTab === 2 ? ActivePaymentQuery?.data?.data
                            :
                            currentTab === 3 ? archivePaymentListQuery?.data?.data
                                :
                                parsedPaymentListHospQuery?.data?.data
                    }
                    selectedItem={
                        currentTab === 2 ? selectedActiveHospitalID
                            :
                            currentTab === 3 ? selectedArchiveHospitalID
                                :
                                null
                    }
                    setSelectedItem={
                        currentTab === 2 ? setselectedActiveHospitalID
                            :
                            currentTab === 3 ? setselectedArchiveHospitalID
                                :
                                null
                    }

                    opMode={opMode}

                />
            }




            {currentTab === 0 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCommitProcessed} className="CommitButton">
                    <Typography>
                        {TextLanguage["CommitAsProcessed"][lang]}
                    </Typography>
                </Button>
            </div>}
            {currentTab === 1 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCreateActivePaymentList} className="CommitButton" disabled={(ActivePaymentQuery.isSuccess && ActivePaymentQuery?.data?.data?.paymentList?.length > 0)}>
                    <Typography>
                        {TextLanguage["CreatePaymentList"][lang]}
                    </Typography>
                </Button>
            </div>}


            {currentTab === 2 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCompletePayment} className="CommitButton"
                    disabled={!ActivePaymentState}
                >
                    <Typography>
                        {TextLanguage["CompletePaymentList"][lang]}
                    </Typography>
                </Button>
            </div>}


            {(currentTab === 4) && (parsedPaymentListHospQuery?.isSuccess) &&
                <InlineGraph
                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                    lang={lang}

                />}







        </div>

        {(filterToggle) ? null : <div className='datatableHeaderFilterRowContainer' style={{ justifyContent: 'flex-start' }}>
            <TextFilter labelName={`RBK ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('caseNumber')} />
            <TextFilter labelName={`LDM ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('LDMCaseNo')} />
            <TextFilter labelName={TextLanguage["FilterByName"][lang]} onFilter={onTextFilter('name')} />
            <DateFilter onFilter={onDateFilter} />

        </div>}
    </div>
}





