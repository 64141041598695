
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';


const useRBACDataMutation = ({ setsnackbarStatus }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ newData, role }) => {

            return axios.post(
                `${config.API_URL}/operator/updateRBACData`,
                 { newData, role },
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (variables) => {
            queryClient.invalidateQueries({ queryKey: ['rbacData'] });
        },
        onError: (error, variables) => {
            if (error.response.status !== 403) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Updating RBAC Data`,
                        severity: 'error'
                    }
                )

            }
        },

    })
}

export { useRBACDataMutation };