import React from 'react';
import { LinearProgress, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { BorderLinearProgress } from '../../PatientFormContainer/pages/PatientInfoPage/components/BorderLinearProgress';






export const DashboardCard = ({ cardTitle, cardCount }) => {

    const queryClient = useQueryClient();
    const dashboardQueryData = queryClient.getQueryData(['dashboardCardData']);
    const totalCount = dashboardQueryData?.data["Active Patient Cases"];

    const theme = useTheme();

    const cardColorMap = {
        'Active Patient Cases':  theme.palette.dashboardTheme.Patients,
        'Missing Documents': theme.palette.dashboardTheme.Docs,
        'Missing Proforma Count': theme.palette.dashboardTheme.Proforma,
        'Awaiting GOP': theme.palette.dashboardTheme.GOP,
        'Missing Invoice Count': theme.palette.dashboardTheme.Invoice,
    }


    return (<div className='dashboardCard' style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '0.5rem' }} >

            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: cardColorMap[cardTitle] }}>
                    {cardTitle}
                </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%',gap:'0.4rem' }}>
                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: theme.palette.text.primary }} >
                    {`${cardCount}`}
                </Typography>

              {(totalCount !== cardCount) &&  <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' , color: theme.palette.text.secondary}} >
                    {`- ${parseFloat(cardCount / totalCount * 100).toFixed(1)}%`}
                </Typography>}

            </div>

                   { (totalCount !== cardCount) && <div style={{ width: '100%', }}>
                <BorderLinearProgress
                    variant="determinate"
                    value={cardCount / totalCount * 100}
                    sx={{ height: '0.5rem', borderRadius: '0.25rem' }}
                    style={{  marginTop: '0.5rem' }}
                />
            </div>}


    </div>);
}
