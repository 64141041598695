import React from 'react';
import TextLanguage from '../../../data/TextLanguage.json';
import { Box } from '@mui/material';
import { FormTabs } from './FormTabs';
import { FormTab } from './FormTab';

const FormTabSelector = ({ formCreateMode, selectedhospCaseID, selectedTab, setSelectedTab, lang, opMode, selectedRow }) => {


    const hideTab = selectedhospCaseID && !formCreateMode ? 'inline-flex' : 'none';

    return (
        <Box sx={{ width: '100%' }}>
            <FormTabs
                value={selectedTab}
                onChange={(event, newValue) => { setSelectedTab(newValue); }}

            >
                <FormTab value="PatientCaseInformation" label={TextLanguage["PatientCaseInformation"][lang]} />
                <FormTab value="CommPage" label={TextLanguage["Comm"][lang]} sx={{ display: hideTab }} />
                <FormTab value="DocumentsPage" label={TextLanguage["Documents"][lang]} sx={{ display: hideTab }}
                    disabled={opMode ?
                        (selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.files?.length > 0 ? false : true)
                        :
                        (selectedRow?.hospCases[0].files?.length > 0 ? false : true)} />
{
/*
                <FormTab value="ProformaPage" label={TextLanguage["Proforma"][lang]} sx={{ display: hideTab }}
                    disabled={opMode ?
                        (selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.Proforma_Date ? false : true)
                        :
                        (false)} />
*/
                        }
                <FormTab value="GOPDocumentPage" label={TextLanguage["GOP"][lang]} sx={{ display: hideTab }}
                    disabled={opMode ?
                        ( (selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.files?.length > 0) ? false : true)
                        :
                        (selectedRow?.hospCases[0].GOP_Date ? false : true)} />
                {(opMode && selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.Invoice_Date) ?
                    <FormTab value="ReviewPage" label={TextLanguage["Review"][lang]} sx={{ display: hideTab }} /> : null}

            </FormTabs>
        </Box>
    );
}

export default FormTabSelector;