import React from "react";
import { Typography } from '@mui/material';

export const formatPatientName = (patientRow) => {
    return (
        <Typography sx={{ fontSize: '1rem' }}>
            {patientRow?.name}
        </Typography>
    )
}
