
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useUserDataQuery = ({selectedUserType}) => {


    async function userFetchFn({ queryKey }) {
        const [_key, query] = queryKey;


        return await axios.get(`${config.API_URL}/operator/userData?type=${query.type}`,
            {
            withCredentials: true
        });



    }

    return useQuery({
        queryKey: ['userData',
            {
               type: selectedUserType
            }

        ],
        queryFn: userFetchFn,
    });




}