
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';


export const useUploadExcelFilteredList = ({setsnackbarStatus, setUploadProgress, setNewFilePatientList}) => {

    return useMutation({
        mutationFn: ({ formData, filter }) => {

            return axios.post(
                `${config.API_URL}/table/filteredList?filter=${filter}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSettled: (data, error, variables, context) => {
           // console.log('data:',data, 'error:', error, 'variables:', variables, 'context:', context);
            if (error){
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Uploading File`,
                        severity: 'error'

                    }
                )

            }
        },
        onSuccess: (data, variables) => {
            if (data.data && data.data.length > 0) {
                setNewFilePatientList(data.data);
            } else {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `No New Files Uploaded For These Patients`,
                        severity: 'success'

                    }
                )
            }
        },

    })






}