
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';


export const useDownloadPDFSplitQuery = ({ setsnackbarStatus, RBKCaseNo, selectedhospCaseID, displayfileIndex, filetype , setSplitPDFURL, selectedPages }) => {


    async function fetchSplitFile({ queryKey }) {
        const [_key, filtersData] = queryKey;
        const { RBKCaseNo ,selectedPages, selectedhospCaseID, displayfileIndex, filetype } = filtersData;
        console.log('qKey:', queryKey)
        return await axios.get(
            `${config.API_URL}/pdf/splitPDF?selectedPages=${selectedPages}&filetype=${filetype}&RBKCaseNo=${RBKCaseNo}&selectedUserID=${selectedhospCaseID}&fileIndex=${displayfileIndex}`,

            {
                withCredentials: true
            }
        );

    }



    return useQuery({
        queryKey: ['splitPDF',
            {
                selectedPages: selectedPages,
                RBKCaseNo: RBKCaseNo,
                selectedhospCaseID: selectedhospCaseID,
                displayfileIndex: displayfileIndex,
                filetype: filetype
            }

        ],
        queryFn: fetchSplitFile,
        staleTime: Infinity,
        gcTime: 5000,
        refetchInterval: false,
        retry: 2,
        enabled: (!!selectedhospCaseID && selectedPages.length > 0),

    })






}