

import React, { useState } from 'react';

import FileSelectorHeader from '../../components/FileSelectorHeader';


import TextLanguage from '../../../../data/TextLanguage.json';


import {
    TextField,
    Button,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    Typography,

} from '@mui/material';


import CustomListItem from '../../components/CustomListItem';


import { useReviewCaseMutation } from './hooks/useReviewCaseMutation';
import { useQueryClient } from '@tanstack/react-query';



const ReviewPageComponent = ({
    lang,
    setformOpen,
    onRowFileURLs,
    selectedhospCaseID,
    displayfileIndex,
    setdisplayfileIndex,
    fileDisplayPdfUrl,
    selectedRow,
    docsMissingFlag,
    setdocsMissingFlag,
    opMode,
    setsnackbarStatus,
}) => {

    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const isLDM =  InsuranceCompanyListData?.data
    .filter((insCo) => insCo?.type === "LDM")
    .map((insCo) => insCo?.value)
    .includes(selectedRow.InsuranceCo);





    const [invoicePrice, setInvoicePrice] = useState(0);
    const [proformaPrice, setProformaPrice] = useState(0);

    const [caseType, setCaseType] = useState(null);
    const [diagnosis, setDiagnosis] = useState('');
    const [serviceFee, setServiceFee] = useState(0);

    const reviewCaseMutation = useReviewCaseMutation({ setsnackbarStatus: setsnackbarStatus, setformOpen: setformOpen });

    const handleReviewConfirmSubmit = async () => {

        const updaterData = {
            caseType: caseType,
            RBKCaseNo: selectedRow?.RBKCaseNo,
            selectedhospCaseID: selectedhospCaseID,
            diagnosis: diagnosis,
            serviceFee: serviceFee,
            proformaPrice: proformaPrice,
            invoicePrice: invoicePrice
        }

        reviewCaseMutation.mutate({ updaterData: updaterData })
    }

    return (
        (

            <div className='ReviewPageContainer'>
                <div className='ReviewPageListContainer'>

                    <CustomListItem index={0} label={TextLanguage["HospitalClinic"][lang]} value={hospitalUserListData?.data?.find((hosp) => hosp.value === selectedhospCaseID)?.label} />
                    <CustomListItem index={1} label={TextLanguage["InvoiceDate"][lang]} value={selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.Invoice_Date?.split('T')[0]} />
                    <CustomListItem index={2} label={`${TextLanguage["GOPPrice"][lang]} (USD)`} value={`$ ${selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.GOPPriceUSD}`} />
                    <CustomListItem index={3} label={`${TextLanguage["GOPPrice"][lang]} (TL)`} value={`${selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.GOPPriceTL/100 } TL`} />


{
                  !isLDM && <>
                        <div className='formRowReview' style={{ marginTop: '2rem' }}>
                            <FormControl sx={{ minWidth: 150, width: '100%', maxWidth: '100%' }}>
                                <InputLabel
                                    id="select-helper-label"
                                    sx={{ color: 'white' }}
                                    shrink={true}
                                >
                                    {TextLanguage["CaseType"][lang]}
                                </InputLabel>
                                <Select
                                    sx={{
                                        width: '100%'
                                    }}
                                    value={caseType}
                                    onChange={(e) => { setCaseType(e.target.value) }}
                                >
                                    <MenuItem key={`Outpatient-MenuItem`} value={'Outpatient'}>
                                        Outpatient
                                    </MenuItem>
                                    <MenuItem key={`Outpatient-(In Clinic)-MenuItem`} value={'Outpatient (In Clinic)'}>
                                        Outpatient (In Clinic)
                                    </MenuItem>
                                    <MenuItem key={`Inpatient-MenuItem`} value={'Inpatient'}>
                                        Inpatient
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='formRowReview'>
                            <TextField
                                label={TextLanguage["Diagnosis"][lang]}
                                multiline
                                rows={2}
                                value={diagnosis}
                                onChange={(e) => { setDiagnosis(e.target.value) }}
                            />
                        </div>
                        <div className='formRowReview'>
                            <TextField
                                type="number"
                                label={TextLanguage["ServiceFee"][lang]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={serviceFee}
                                onChange={(e) => { setServiceFee(e.target.value) }}
                            />
                        </div>
                    </>

                    }
                    <div className='formRowReview'>
                        <TextField
                            type="number"
                            label={TextLanguage["ProformaPrice"][lang]}
                            InputLabelProps={{
                                shrink: true,
                            }}

                            value={proformaPrice}
                            onChange={(e) => { setProformaPrice(e.target.value) }}
                        />

                    </div>
                    <div className='formRowReview'>
                        <TextField
                            type="number"
                            label={TextLanguage["InvoicePrice"][lang]}
                            InputLabelProps={{
                                shrink: true,
                            }}

                            value={invoicePrice}
                            onChange={(e) => { setInvoicePrice(e.target.value) }}
                        />

                    </div>

                    <div className='formRowReview' style={{}}>

                        <Button
                            sx={{
                                height: '2.5rem', width: '6rem', fontSize: '1rem', background: 'rgb(0, 107, 0) !important',
                                '&:hover': { // button hover override
                                    outline: '2px solid green',
                                    boxShadow: '0px 0px 8px 2px rgba(14, 225, 14, 0.65)',
                                    background: 'rgb(0, 107, 0) !important',
                                    filter: 'brightness(1.25) saturate(1.25)',
                                },


                            }}
                            onClick={handleReviewConfirmSubmit}
                        >
                            {TextLanguage["Approve"][lang]}
                        </Button>
                        <Button
                            sx={{
                                background: 'red', height: '2.5rem', width: '6rem', fontSize: '1rem', background: 'rgb(128, 0, 0) !important',
                                '&:hover': { // button hover override
                                    outline: '2px solid rgbA(223, 92, 92 ,0.9)',
                                    boxShadow: '0px 0px 8px 2px rgba(227, 2, 2, 0.5)',
                                    background: 'rgb(214, 0, 0) !important',
                                    filter: 'brightness(1.25) saturate(1.25)',
                                },
                            }}
                            onClick={() => {
                                setCaseType(null);
                                setDiagnosis('');
                            }}
                        >
                            {TextLanguage["Cancel"][lang]}
                        </Button>
                    </div>


                </div>



                <div>

                    <FileSelectorHeader
                        onRowFileURLs={onRowFileURLs}
                        displayfileIndex={displayfileIndex}
                        setdisplayfileIndex={setdisplayfileIndex}
                        docsMissingFlag={docsMissingFlag}
                        setdocsMissingFlag={setdocsMissingFlag}
                        selectedhospCaseID={selectedhospCaseID}
                        opMode={opMode}
                        switchMode={false}
                        filetype={'all'}
                    />
                    <div className='ProformapdfContainer' style={{ width: '50vw' }}>
                        <iframe id='pdf-invoice' title="PDF Viewer Invoice" width="100%" height="580px" src={fileDisplayPdfUrl}></iframe>
                    </div>
                </div>

            </div>)
    )



}



export default ReviewPageComponent;
