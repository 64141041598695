import React from "react";
import { Typography } from '@mui/material';

export const formatHospitalUserID = (HospitalUserID) => (patientRow) => {

    return (

        patientRow?.hospCases?.length === 1 ?
            <div key={`hospCase-ID:${patientRow?.RBKCaseNo}`} style={{ fontWeight: 'bold' }} >
                {HospitalUserID?.find(hosp => hosp?.value === patientRow?.hospCases[0]?.userID)?.label}
            </div>
            :
            patientRow?.hospCases?.map(hospCase => HospitalUserID?.find(hosp => hosp?.value === hospCase?.userID)?.label).map(
                (hospCase, index) => {
                    return (
                        <div key={`hospCase-${index}-${patientRow?.RBKCaseNo}`} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ color: index % 2 ? 'rgb(110, 179, 252)' : 'rgb(46, 214, 208)', fontWeight: 'bold', fontSize: '0.85rem' }}>{hospCase}</Typography>
                        </div>
                    )

                }
            )


    );

}
