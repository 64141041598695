import React from 'react';

import { PDFViewer } from '@react-pdf/renderer';

function PDFCreator({ selectedRow, DocComponent, hospCaseID }) {


    return (
        <PDFViewer width={'100%'} height={'100%'} >
            <DocComponent selectedRow={selectedRow} hospCaseID={hospCaseID} />
        </PDFViewer>
    );
}

export default PDFCreator;
