import { ListItemButton } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';


const StyledButton = styled(ListItemButton)(({ theme }) => ({
    color: 'white',
    background: 'rgb(37, 38, 40)',
    transitionDuration: '220ms',
    borderRadius: 0,
    padding: '0px',
    height: '40px',
    minHeight: '40px',
    width: '8rem',
    minWidth: '8rem !important',
    alignItems: 'center',
    alignSelf: 'center',

    fontWeight: 'bold',
    fontSize: '0.85rem',
    borderBottom: '1px solid rgba(22, 22, 22, 0.5)',
    borderRadius: '8px',
    boxShadow: 'inset 0px 0px 20px 0px rgba(38, 38, 38, 0.45),0px 0px 12px -2px rgba(22, 22, 22, 0.6)',
    zIndex: 200,
    justifyContent: 'center',

    transitionProperty: 'background',
    maxHeight: '45px',

    '& .Mui-disabled': {
        backgroundColor: `red`,
    },
    '&:hover, &.Mui-focusVisible': {
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.8), rgba(66, 151, 255, 0.78))',
        boxShadow: 'inset 0px 0px 18px -2px rgb(125,188,255)',
    },


    '& .Mui-selected': {
        color: `red !important`,
        background: `white`,
        '&:hover': {
            color: `red !important`,
            background: `white`,
        }
    },

}));


export const PaymentListSelector = ({archivePaymentListGroupQuery, setselectedPaymentID, selectedPaymentID}) => {

    return (
        archivePaymentListGroupQuery?.isSuccess &&
            archivePaymentListGroupQuery?.data?.data?.map((archiveEntry, index) => {
                const parsedDate = new Date(archiveEntry?.paymentDate);
                return (

                        <StyledButton
                        key={`paymentListEntry-${archiveEntry.paymentID}-${index}`}
                        selected={selectedPaymentID === archiveEntry.paymentID}
                        onClick={() => setselectedPaymentID(archiveEntry.paymentID)}
                            sx={{  fontSize: '0.85rem', borderRadius: '0px', flexWrap: 'nowrap', width: '100%' }}>
                            {`${archiveEntry.paymentID}: ${parsedDate.toISOString()?.split('T')[0]}`}
                        </StyledButton>

                )
            }))
}
