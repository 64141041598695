import React from "react";
import { Typography } from '@mui/material';

export const formatPatientCountPayments = (hospCaseRow) => {
    return (
        <Typography className='RowCountText'>
            {`${hospCaseRow.totalCount}`}
        </Typography>
    );
}
