import React from "react";
import { Typography } from '@mui/material';


export const formatInsuranceCompany = ( InsuranceCompanyList ) => ( patientRow ) => {

    return (
        <Typography sx={{ fontSize: '1rem', fontWeight:'bold' }}>
            {InsuranceCompanyList?.find(insuranceCo => insuranceCo?.value === patientRow?.InsuranceCo)?.label || '' }
        </Typography>
    );

};

