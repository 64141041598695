import React from 'react';

import { PaymentListDataDisplay } from './PaymentListDataDisplay';
import { BreadcrumbPayment } from './BreadcrumbPayment';


export const PaymentListHeader = ({
    selectedItem, setSelectedItem,
    currentList, lang, opMode,
}) => {



    const selectedPaymentList = !!selectedItem ?
        currentList?.paymentList?.find((hosp) => hosp.userID === selectedItem) : currentList;

    const parsedPaymentDate = currentList?.paymentDate ? new Date(currentList?.paymentDate) : null;


    const USDValue = selectedPaymentList?.GOPTotalUSD;
    const TLValue = selectedPaymentList?.GOPTotalTL / 100;
    const totalCount = selectedPaymentList?.totalCount;



    return (<div
    className='paymentListHeaderWrapper'
    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.65rem 0.25rem', }}>

        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'flex-start', width: '100%', flexGrow: 1 }}>

            {parsedPaymentDate && opMode && <div className='breadcrumbWrapper'>
                <BreadcrumbPayment
                    rootLabel={parsedPaymentDate?.toISOString()?.split('T')[0]}
                    selectedItem={selectedItem}
                    clearSelectedItem={() => setSelectedItem(null)}
                    lang={lang}

                />
            </div>}

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'flex-start', width: '100%', flexGrow: 1 }}>

            {selectedPaymentList && <PaymentListDataDisplay
                lang={lang}
                opMode={opMode}
                USDValue={USDValue}
                TLValue={TLValue}
                totalCount={totalCount}
            />}

        </div>
    </div>);
}



