import React, { useState, useRef } from 'react';
import MailDisplay from '../PatientInfoPage/components/MailDisplay';
import CommentsComponent from '../PatientInfoPage/components/CommentsComponent';

import {
    Typography,
    IconButton,
    Divider,
    Button
} from '@mui/material';

import { useMailFetchQuery } from '../../hooks/useMailFetchQuery';
import { mailTree } from '../PatientInfoPage/functions/mailTree';
import MailSelector from '../PatientInfoPage/components/MailSelector';
import { useQueryClient } from '@tanstack/react-query';


const CommPageComponent = ({
    selectedRow,

    setselectedRow,
    selectedhospCaseID,
    setsnackbarStatus,
    opMode,


}) => {


    const mailFetchQuery = useMailFetchQuery({ userID: selectedhospCaseID, RBKCaseNo: selectedRow?.RBKCaseNo, opMode })
    const mailData = opMode && mailFetchQuery.isSuccess && mailFetchQuery?.data?.data?.messagesArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    const orderedMailTreeArray = opMode && mailData?.length > 0 && mailTree(mailData);

    const [selectedMailID, setSelectedMailID] = useState(null);


    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    return (<div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            height: '100%',
        }}
    >


        <div className='HeaderUnderlined' style={{ justifyContent: 'center' }}>
            <Typography variant='h4' sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white' }}>
                {hospitalUserListData?.data.find(hosp => selectedhospCaseID === hosp.value)?.label}
            </Typography>

        </div>





        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                height: '100%',
            }}
        >

            { (opMode && mailFetchQuery?.isSuccess) &&
                (orderedMailTreeArray) &&
                <div className='mailSection'>
                    <MailSelector
                        selectedRow={selectedRow}
                        selectedMailID={selectedMailID}
                        setSelectedMailID={setSelectedMailID}
                        orderedMailTreeArray={orderedMailTreeArray}
                    />
                    <MailDisplay
                        selectedRow={selectedRow}
                        selectedMailID={selectedMailID}
                        setSelectedMailID={setSelectedMailID}
                        orderedMailTreeArray={orderedMailTreeArray}
                    />
                </div>}


            <CommentsComponent
                selectedRow={selectedRow}
                setselectedRow={setselectedRow}
                selectedhospCaseID={selectedhospCaseID}
                setsnackbarStatus={setsnackbarStatus}
                opMode={opMode}

            />
        </div>
    </div>);
}

export default CommPageComponent;



