

import React, { useState, useReducer, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import DocumentsPageComponent from './pages/DocumentsPage/DocumentsPageComponent';
import GOPPageComponent from './pages/GOPPage/GOPPageComponent';
//import ProformaPageComponent from './pages/ProformaPage/ProformaPageComponent';
import ReviewPageComponent from './pages/ReviewPage/ReviewPageComponent';
import PatientInfoPageComponent from './pages/PatientInfoPage/PatientInfoPageComponent';

import { useUpdateFlagsMutation } from './hooks/useUpdateFlagsMutation';
import { useFileFetchQuery } from './hooks/useFileFetchQuery';
import { useFileTokensQuery } from './hooks/useFileTokensQuery';
//import { useMailFetchQuery } from './hooks/useMailFetchQuery';


import FormTabSelector from './components/FormTabSelector';
import CommPageComponent from './pages/CommPage/CommPageComponent';

const initialState = {
    RBKCaseNo: '',
    userID: '',
    userIDInsert: '',
    name: '',
    admissionDate: '',
    dob: '',
    mobilePhone: '',
    hotelRoom: '',
    hotel: '',
    policyNo: '',
    InsuranceCo: '',
    complaints: '',
    notes: '',
    franchise: '30$',
    InsurancePeriodStart: '', // (DD.MM-DD.MM)YYYY
    InsurancePeriodEnd: '',

    // Missing DATA ------

    // These have to come in from the insurance db

    // These have to come in at the final step after invoice is submitted and the case if processed
    diagnosis: '', //
    caseType: '', // outpatient ,outpatient (in clinic), inpatient
    insuranceBill: '', // = GOPPriceUSD + expertise input



    // Hotel Location - import Hotel list from db? for all users or operator only? who is creating the files?
    // Hospital/Clinic - tied to accounts => hosp list on db
    // Home Address - redundant?
    // TourOperator - pegas etc.
    // DateExamination - DD.MM.YYYY
    // Blood Pressure - Pulse?
    // Diagnosis
    // Treatment




};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            console.log(action, {
                ...action.tempval
            });
            return {
                ...action.tempval
            };
        case 'RESET':
            return {
                ...initialState
            };
        default:
            return state;
    }
}


function PatientFormContainer({
    lang,
    onRowFileURLs, setonRowFileURLs,
    selectedRow,
    selectedTab,  opMode,
    confirmedUserID,
    selectedhospCaseID, setselectedhospCaseID,
    setsnackbarStatus,
    formCreateMode,
    setformOpen,
    setselectedRow,
    setSelectedTab,


}) {


    const [fileDisplayPdfUrl, setfileDisplayPdfUrl] = useState(null);
    const [displayfileIndex, setdisplayfileIndex] = useState(0);

    const [patientForm, dispatch] = useReducer(reducer, initialState);



    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleFieldChange = (fieldName) => (e) => {
        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });
    };



    const fileTokensQuery = useFileTokensQuery({ selectedRow, selectedhospCaseID })


    useEffect(() => {
        if (fileTokensQuery.isSuccess) {
            console.log(fileTokensQuery.data.data);
            if (fileTokensQuery.data.data.length === 0 || fileTokensQuery.data.data.includes("No Files Uploaded")) {
                setonRowFileURLs(null);
            } else {
                setonRowFileURLs(fileTokensQuery.data.data);
            }
        }
        if (fileTokensQuery.isError) {
            setonRowFileURLs(null);

            fileTokensQuery.error.response?.status !== 404 && setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching File Tokens`,
                    severity: 'error'
                }
            )
        }

    }, [fileTokensQuery.isSuccess, fileTokensQuery.isError, fileTokensQuery.data])

    useEffect(() => { // when selectedRow changes, update patientForm and ProformaList Hooks, If in userMode, strip the other hospitals case info

        if (opMode) {


            setdocsMissingFlag(selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.flags?.MissingDocs);

        } else {

            const authUsersHospCase = selectedRow.hospCases.find((hospCase) => hospCase.userID === confirmedUserID);

            setdocsMissingFlag(selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.flags?.MissingDocs);
            const { hospCases, ...strippedSelectedRow } = selectedRow;
            strippedSelectedRow.hospCases = [authUsersHospCase];


        }

    }, [selectedRow, selectedhospCaseID])

    useEffect(() => {
        setdisplayfileIndex(0);
    }, [selectedTab])


    const [fetchedFileType, setfetchedFileType] = useState('all');

    const fileFetchQuery = useFileFetchQuery({
        selectedRow,
        selectedhospCaseID,
        fetchedFileType,
        displayfileIndex,
        onRowFileURLs
    })

    const [maxPageCount, setMaxPageCount] = useState(0);

    useEffect(() => {
        if (fileFetchQuery.isSuccess) {

            // let contentType = 'application/pdf'
            const contentType = fileFetchQuery.data.headers['content-type'];
            console.log('contentType',contentType);
            const blob = new Blob([fileFetchQuery.data.data], { type: contentType });

            // Use the response data (blob) to display or download on the frontend
            const blobUrl = window.URL.createObjectURL(blob);
            setfileDisplayPdfUrl(blobUrl);
            console.log(blobUrl);
            contentType === 'application/pdf' && setMaxPageCount(fileFetchQuery.data.headers['x-page-count']);
        }
        if (fileFetchQuery.isError) {
            fileFetchQuery?.error?.response?.status === 409 && setfileDisplayPdfUrl("Wrong_Type")

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Files`,
                    severity: 'error'
                }
            )
        }
    }, [fileFetchQuery.data, fileFetchQuery.isSuccess, fileFetchQuery.isError])


    useEffect(() => {

        if (selectedhospCaseID && selectedTab === "DocumentsPage" && (onRowFileURLs?.length > 0)) {
            setfetchedFileType("all");
        }
        if (selectedhospCaseID && selectedTab === "Proforma" && (onRowFileURLs?.filter(file => file.filetype === "patientDoc")?.length > 0)) {
            setfetchedFileType("patientDoc");
        }
        if (opMode && selectedhospCaseID && selectedTab === "ReviewPage" && (onRowFileURLs?.filter(file => file.filetype === "invoice")?.length > 0)) {
            setfetchedFileType("all");
        }


    }, [selectedhospCaseID, onRowFileURLs, selectedTab, displayfileIndex])



    useEffect(() => {

        if (opMode && selectedhospCaseID) {
            updateFlagsMutation.mutate({ flag: "UnreadMessageOp", state: false })
        }

    }, [selectedhospCaseID])



    const updateFlagsMutation = useUpdateFlagsMutation({
        selectedRow,
        selectedhospCaseID,
        setsnackbarStatus
    })

    useEffect(() => {

        if (!opMode && selectedhospCaseID) {
            updateFlagsMutation.mutate({ flag: "UnreadMessageUser", state: false })
        }

        if (selectedRow?.hospCases?.length === 1 && opMode) {
            setselectedhospCaseID(selectedRow?.hospCases[0]?.userID);
        }


        return () => {
            if (opMode) {
                setselectedhospCaseID(null);
            }
            window.URL.revokeObjectURL(fileDisplayPdfUrl);
        }


    }, [])






    //const Proforma = useRef(null);
    const PatientCaseInfo = useRef(null);
    const CommPage = useRef(null);
    const DocumentsPage = useRef(null);
    const GOPDocumentPage = useRef(null);
    const ReviewPage = useRef(null);


    //const [totalTreatmentPrice, settotalTreatmentPrice] = useState(0); // Has to be at this level to be accessible by GOPDocumentPage

/*
    useEffect(() => {
        if (selectedTab === "GOPDocumentPage") {
            dispatch({
                type: 'SET_FIELD',
                fieldName: 'GOPPriceUSD',
                payload: totalTreatmentPrice,
            });
            dispatch({
                type: 'SET_FIELD',
                fieldName: 'GOPPriceExr',
                payload: exchangeRate,
            });

            dispatch({
                type: 'SET_FIELD',
                fieldName: 'GOPPriceTL',
                payload: (totalTreatmentPrice * exchangeRate).toFixed(2),
            });
        }

    }, [totalTreatmentPrice, selectedTab, exchangeRate])
*/



    const [docsMissingFlag, setdocsMissingFlag] = useState(false);

    PatientCaseInfo.current =

        <PatientInfoPageComponent
            formCreateMode={formCreateMode}
            selectedRow={selectedRow}
            opMode={opMode}
            lang={lang}
            selectedhospCaseID={selectedhospCaseID}
            setselectedhospCaseID={setselectedhospCaseID}
            setselectedRow={setselectedRow}
            setsnackbarStatus={setsnackbarStatus}
            patientForm={patientForm}
            handleFieldChange={handleFieldChange}

            onRowFileURLs={onRowFileURLs}
            setformOpen={setformOpen}


            dispatch={dispatch}


        />

    DocumentsPage.current =
        <DocumentsPageComponent
            onRowFileURLs={onRowFileURLs}
            opMode={opMode}
            selectedhospCaseID={selectedhospCaseID}
            displayfileIndex={displayfileIndex}
            setdisplayfileIndex={setdisplayfileIndex}
            setdocsMissingFlag={setdocsMissingFlag}
            docsMissingFlag={docsMissingFlag}
            updateFlagsMutation={updateFlagsMutation}
            fileDisplayPdfUrl={fileDisplayPdfUrl}

            setsnackbarStatus={setsnackbarStatus}
            selectedRow={selectedRow}
            maxPageCount={maxPageCount}
        />


    CommPage.current =
    <CommPageComponent
    selectedRow={selectedRow}
    setselectedRow={setselectedRow}
    selectedhospCaseID={selectedhospCaseID}
    setsnackbarStatus={setsnackbarStatus}
    opMode={opMode}

    />

/*
    Proforma.current =
        <ProformaPageComponent
            lang={lang}
            patientForm={patientForm}
            onRowFileURLs={onRowFileURLs}
            selectedhospCaseID={selectedhospCaseID}
            displayfileIndex={displayfileIndex}
            setdisplayfileIndex={setdisplayfileIndex}
            fileDisplayPdfUrl={fileDisplayPdfUrl}

            selectedRow={selectedRow}
            setselectedRow={setselectedRow}
            confirmedUserID={confirmedUserID}
            opMode={opMode}
            settotalTreatmentPrice={settotalTreatmentPrice}
        />;

*/


    GOPDocumentPage.current =
        <GOPPageComponent
            lang={lang}
            opMode={opMode}
            selectedhospCaseID={selectedhospCaseID}
            handleFieldChange={handleFieldChange}
           // handleReplaceForm={handleReplaceForm}
            patientForm={patientForm}
            dispatch={dispatch}
            selectedRow={selectedRow}
            setselectedRow={setselectedRow}
            setsnackbarStatus={setsnackbarStatus}
            onRowFileURLs={onRowFileURLs}
            displayfileIndex={displayfileIndex}
            setdisplayfileIndex={setdisplayfileIndex}
            docsMissingFlag={docsMissingFlag}
            setdocsMissingFlag={setdocsMissingFlag}
            updateFlagsMutation={updateFlagsMutation}
            fileDisplayPdfUrl={fileDisplayPdfUrl}


        />




    ReviewPage.current =
        <ReviewPageComponent
            lang={lang}
            setformOpen={setformOpen}
            onRowFileURLs={onRowFileURLs}
            selectedhospCaseID={selectedhospCaseID}
            displayfileIndex={displayfileIndex}
            setdisplayfileIndex={setdisplayfileIndex}
            fileDisplayPdfUrl={fileDisplayPdfUrl}

            selectedRow={selectedRow}
            opMode={opMode}
            setsnackbarStatus={setsnackbarStatus}

        />



    return (




        <div className="form-container">

            <FormTabSelector
                formCreateMode={formCreateMode}
                selectedhospCaseID={selectedhospCaseID}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                lang={lang}
                opMode={opMode}
                selectedRow={selectedRow}
            />



            <div className='extruded'></div>

            {selectedTab === "PatientCaseInformation" && PatientCaseInfo.current}

            {selectedTab === "CommPage" && !formCreateMode && CommPage.current}

            {selectedTab === "DocumentsPage" && !formCreateMode && DocumentsPage.current}

            {
                //selectedTab === "ProformaPage" && !formCreateMode && Proforma.current
            }

            {selectedTab === "GOPDocumentPage" && !formCreateMode && GOPDocumentPage.current}

            {selectedTab === "ReviewPage" && opMode && !formCreateMode && ReviewPage.current}


        </div>


    );
};



export default PatientFormContainer;








