
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";


export const useWhatsappChatQuery = ({limit, page}) => {

    async function fetchMessageData({ queryKey }) {

        const [key, { limit, page }] = queryKey;

        return await axios.get(`${config.API_URL}/operator/WhatsAppMessageLog?limit=${limit}&page=${page}`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['messageData',
            {
                limit: limit,
                page: page,
            }
            ],
            queryFn: fetchMessageData,
            staleTime: 1000 * 60 * 3,
            refetchInterval: 1000 * 60 * 6,
        }


    )

}