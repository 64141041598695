
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useMailFetchQuery = ({userID, RBKCaseNo, opMode}) => {


    async function MailFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        console.log(QuerySelection);
        const { userID, RBKCaseNo } = QuerySelection;

        return await axios.get(`${config.API_URL}/mail/mailContent?userID=${userID}&RBKCaseNo=${RBKCaseNo}`, {
            withCredentials: true
        });



    }

    return useQuery({
        queryKey: ['mailFetch',
            {
                RBKCaseNo: RBKCaseNo,
                userID: userID,
            }

        ],
        queryFn: MailFetchFn,
        enabled: (!!opMode && !!userID && !!RBKCaseNo),


    });




}