import { Tab, Tabs, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";


export const DataTableAdminHeader = ({
    selectedAdminTab,
    setSelectedAdminTab,
    setSelectedUserType,
    userDataQuery
}) => {


    const theme = useTheme();



    const handleTabSelection = (e, newVal) => {
        setSelectedAdminTab(newVal);
        if (newVal === 'Operator') {
            setSelectedUserType('Operator');
        } else if (newVal === 'Client') {
            setSelectedUserType('Client');
        }
    }


    const isImapConnected = userDataQuery.data?.data.IMAPStatus.isConnected;

    const imapConnectionDate = new Date(userDataQuery.data?.data?.IMAPStatus?.latestConnectionTime).toLocaleString();
    const imapErrorDate = userDataQuery.data?.data?.IMAPStatus?.latestErrorTime ?
        new Date(userDataQuery.data?.data?.IMAPStatus?.latestErrorTime).toLocaleString()
        :
        'No Error';

    return (<div className="InsuranceHeaderContainer">

        <div className="InsuranceUploadHeader">
            <Tabs value={selectedAdminTab} onChange={handleTabSelection} >
                <Tab label="Operators" value={'Operator'} />
                <Tab label="Clients" value={'Client'} />
                <Tab label="Permissions" value={'Permissions'} />

            </Tabs>
        </div>

        <div>
            {userDataQuery.isSuccess &&
                <Tooltip
                    arrow
                    title={isImapConnected ? imapConnectionDate : imapErrorDate}
                    placement='top'
                >
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{
                            borderRadius: '100%',
                            height: '15px', width: '15px',
                            background: isImapConnected ?
                                `radial-gradient(circle, ${theme.palette.successColor.light} 0%, ${theme.palette.successColor.dark} 100%)`
                                :
                                `radial-gradient(circle, ${theme.palette.errorColor.dark} 35% , ${theme.palette.shadow[0]} 100% )`,
                            boxShadow: isImapConnected ? `0px 0px 4px 0px ${theme.palette.successColor.light}` : '0px 0px 6px 0px rgba(0,0,0,0.5)',
                        }} />
                        <Typography>
                            {'IMAP Status'}
                        </Typography>
                    </div>

                </Tooltip>

            }


        </div>



    </div>);
}

