import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextLanguage from '../data/TextLanguage.json';
import useLocalStorage from '../hooks/useLocalStorage';

import CompLogo from '../resources/RBKLogo.png';


import { useLoginQuery } from '../hooks/useLoginQuery';
//import LanguageSelector from '../components/LanguageSelector';


import { styled } from '@mui/material/styles';
import { useVerificationQuery } from '../hooks/useVerificationQuery';
import { CountdownDisplay } from '../components/CountdownDisplay';
import { useQueryClient } from '@tanstack/react-query';
import { CustomSnackbar } from '../components/CustomSnackbar';

const LoginInputField = styled(OutlinedInput)(({ theme }) => ({
    height: '100%',
    width: '100%',
    borderRadius: '8px 8px 0px 0px',
    color: 'white',
    boxShadow: 'inset 0px 0px 12px 0px rgba(0, 0, 0, 0.35)',
    background: 'transparent !important',

    '& .MuiFormControl-root:hover .MuiInput-underline::before': { borderBottom: 'none' },
    '& .MuiInput-underline:before': { border: 'none' },
    '& .MuiInput-underline:hover': { borderBottom: 'none', },
    '& label.Mui-focused': { color: '#A0AAB4', },
    '& .MuiInput-underline:after': { borderBottom: 'none', },
    '& fieldset': { border: '1px solid #ffffff1a' },
    '&:hover fieldset': { border: '1px solid #ffffff3b' },
    '&.Mui-focused fieldset': { border: '1px solid #ffffff3b' },
    '& input': {
        background: 'transparent',
        outline: 'none',
        border: 'none',
    },
    '& input:focus': {
        background: 'transparent',
        outline: 'none',
        border: 'none',
        boxShadow: 'inset 0 0 16px 2px rgba(0, 123, 255, 0.5)',
    }

}));


function LoginPage() {


    const navigate = useNavigate();


    const [username, setName] = useState('');

    const [password, setPassword] = useState('');

    const [verificationCode, setVerificationCode] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });


    const handleClickShowPassword = () => { setShowPassword((show) => !show) }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const [submitted, setSubmitted] = useState(false);
    const [verificationSubmitted, setVerificationSubmitted] = useState(false);

    const [awaitingVerification, setAwaitingVerification] = useState(false);
    const loginQuery = useLoginQuery({ loginInfo: { username, password }, submitted });
    const verificationQuery = useVerificationQuery({ loginInfo: { username, verificationCode }, verificationSubmitted });


    useEffect(() => {
       submitted && setSubmitted(false);
    }, [username, password])

    const [verificationTime, setVerificationTime] = useState(null);

    useEffect(() => {
        if (loginQuery.isSuccess && loginQuery.data?.status === 200) {

            navigate("/fileshare");
            setSubmitted(false);
        } else if (loginQuery.isError) {
            setSubmitted(false);

            if( loginQuery.error.request.status === 429 ) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Too many requests. Please try again later.`,
                        severity: 'error'
                    }
                )
            }

        } else if (loginQuery.isSuccess && loginQuery.data?.status === 206) {
            setAwaitingVerification(true);
            setVerificationTime(Date.now());

            setSubmitted(false);
        }

    }, [loginQuery.data, loginQuery.isSuccess, loginQuery.isError])

    useEffect(() => {
        if (verificationQuery.isSuccess && verificationQuery.data?.status === 200) {

            setAwaitingVerification(false);
            navigate("/fileshare");

        } else if (verificationQuery.isError) {
            setVerificationSubmitted(false);
        }

    }, [verificationQuery.data, verificationQuery.isSuccess, verificationQuery.isError])

    const queryClient = useQueryClient();

    useEffect(() => {
        setName(null);
        setPassword(null);
        setVerificationCode(null);
        setSubmitted(false);
        setAwaitingVerification(false);
        queryClient.removeQueries({ queryKey: ['login'] });
        queryClient.removeQueries({ queryKey: ['verify'] });
    }, []);

    return (


        <div className='LoginPageContainer'>

            <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

            <div className="LoginBox" >
                <div className='LoginTopContainer'>


                    {!awaitingVerification ?
                        <>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined">{TextLanguage["Username"][lang]}</InputLabel>
                                <LoginInputField
                                    id='usernameInput'
                                    value={username}
                                    onChange={(e) => setName(e.target.value)}
                                    error={loginQuery.isError}
                                    type={'text'}
                                    label="Username"
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel shrink sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined-adornment-password">{TextLanguage["Password"][lang]}</InputLabel>
                                <LoginInputField
                                    value={password} onChange={(e) => setPassword(e.target.value)}
                                    id='passwordInput'
                                    error={loginQuery.isError}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '1rem', width: '100%' }}>

                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel sx={{ color: 'rgb(125,188,255)', fontWeight: 'bold' }} htmlFor="outlined">{"Verification Code"}</InputLabel>
                                <LoginInputField
                                    id='verificationInput'
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    error={verificationQuery.isError}
                                    type={'text'}
                                    label="Verification Code"
                                />
                            </FormControl>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', maxHeight: '50px' }}>
                                <div style={{ padding: '0px 16px', background: '#3b4e7b', borderRadius: 12, border: '1px solid rgba(0, 161, 255, 0.63)' }}>
                                    <CountdownDisplay
                                        initialCount={verificationTime}
                                        timer={300}
                                        setSubmitted={setSubmitted}
                                        setVerificationSubmitted={setVerificationSubmitted}
                                        setAwaitingVerification={setAwaitingVerification}

                                    />
                                </div>
                            </div>


                        </div>


                    }

                    {!awaitingVerification ?
                        <Button
                        data-testid='loginSubmit'
                         sx={{ color: 'white', background: 'rgb(49,80,125)', borderRadius: 2, height: 56, width: '100%' }} onClick={() => { setSubmitted(true) }}>
                            <Typography sx={{ fontWeight: 700, fontSize: '1.4rem' }}>{TextLanguage["Login"][lang]}</Typography>
                        </Button>
                        :
                        <Button
                        data-testid='verificationSubmit'
                         sx={{ color: 'white', background: 'rgb(49,80,125)', borderRadius: 2, height: 56, width: '100%' }} onClick={() => {
                            setVerificationSubmitted(true);

                        setSubmitted(true) }}>
                            <Typography sx={{ fontWeight: 700, fontSize: '1.4rem' }}>{TextLanguage["Login"][lang]}</Typography>
                        </Button>

                    }

                </div>

                <div className='LoginLogoContainer'>
                    <img src={CompLogo} alt="CompLogo" style={{ height: '2.4rem', width: 'auto' }} />
                </div>
            </div>

        </div >
    );
}

export default LoginPage;



