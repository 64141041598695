

import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../../../config.js";

export const useInsuredPatientListQuery = ({ insuranceCo, page = 0, limit = 100 }) => {

    async function fetchInsuredPatientList() {
        return await axios.get(
            `${config.API_URL}/operator/insuredPatientList?InsCo=${insuranceCo}&page=${page}&limit=${limit}`,  // Pass the filters
            {
                withCredentials: true
            }
        );
    }
    return useQuery(
        {
            queryKey: ['insuredPatientList', {
                InsuranceCo: insuranceCo,
                page: page,
                limit: limit
            }],
            queryFn: fetchInsuredPatientList,
            staleTime: Infinity,
        }
    )
}