import React from 'react';

import { Page, Text, View, Document, Font, StyleSheet, ReactPDF, PDFViewer, Image } from '@react-pdf/renderer';
import robotoFont from '../../resources/fonts/Roboto-Regular.ttf';
import logo from '../../resources/RBKLogo.png'


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff'
    },
    section: {
        margin: 25,
        padding: 15,
        flexGrow: 1,

    }
});

Font.register({
    family: 'Roboto',
    src: robotoFont,

});
const logoScaler = 4;
//


const GOPDocument = ({ selectedRow , hospCaseID}) => {


   const selectedUserCase = selectedRow?.hospCases.find(hospCase => hospCase.userID === hospCaseID);



return (



    selectedUserCase?.GOPPriceUSD ? <Document title={'RBK Assistance GOP Document'} pageMode={'useOutlines'} >
        <Page size="A4" style={styles.page}>
            <View style={[styles.section, { marginBottom: '10pt', padding: '5pt', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }]}>
            <Image src={logo} style={{ width: `${986/logoScaler}pt`, height: `${208/logoScaler}pt` }} ></Image>

            </View>

            <View style={[styles.section, { marginTop: '5t' ,marginBottom:'0pt'}]}  >


                <Text style={{ fontFamily: 'Roboto', fontSize: '18pt', textAlign: 'center', marginTop: '10pt',color:'#c30404' }}>
                    {`TAAHH\u00DCT MEKTUBU`}
                </Text>

                <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }}>
                    {`
          RBK ASSISTANCE \u00C7A\u011ERI H\u0130ZMETLER\u0130 VE DANI\u015EMANLIK TUR\u0130ZM A.\u015E. a\u015Fa\u011F\u0131da ismi yaz\u0131l\u0131 bulunan sigortal\u0131lar i\u00E7in kurulu\u015Funuz taraf\u0131ndan yap\u0131lan tedavi giderlerine ili\u015Fkin fatura bedelini, faturan\u0131n \u015Firketimizce tebell\u00FC\u011F edildi\u011Fi tarihten en ge\u00E7 60(altm\u0131\u015F) g\u00FCn i\u00E7inde \u00F6denmesini temin edece\u011Fini taahh\u00FCt eder.
          `}
                </Text></View>
                <View style={[styles.section, { marginTop: '5t', marginBottom: '5t',flexDirection:'row', }]}  >
                    <View style={{flexDirection:'column',flexGrow:'1'}}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt',color:'#c30404' ,marginBottom:'8pt'}} >{`Ref`}  </Text>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }} >{`${selectedRow?.RBKCaseNo}` }</Text>
                    </View>
                    <View style={{flexDirection:'column',flexGrow:'1'}}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt',color:'#c30404'  ,marginBottom:'8pt'}} >{`Sigortal\u0131n\u0131n Ad\u0131, Soyad\u0131`}  </Text>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }}>{`${selectedRow?.name}` }</Text>
                    </View>
                    <View style={{flexDirection:'column',flexGrow:'1'}}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt',color:'#c30404' ,marginBottom:'8pt' }} >{` Garanti Edilen Tutar`}  </Text>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }} >{`${selectedUserCase?.GOPPriceUSD}$ x (${selectedUserCase?.GOPPriceExr}) = ${parseFloat(selectedUserCase?.GOPPriceUSD*selectedUserCase?.GOPPriceExr).toFixed(2)}TL` }</Text>
                    </View>
                    <View style={{flexDirection:'column',flexGrow:'1'}}>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' ,color:'#c30404' ,marginBottom:'8pt'}} >{`Garanti Tarihi`}  </Text>
                    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }} >{`${selectedUserCase?.GOP_Date?.slice(0, 10)}` }</Text>
                    </View>


                </View>

<View style={[styles.section, { marginTop: '5t', marginBottom: '5t',paddingBottom:'0pt',paddingTop:'0pt' }]} >
    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }}>
    {`G\u00F6nderilen garantiler ile ilgili itirazlar\u0131n\u0131z\u0131, \u00F6deme garantisinin size ula\u015Ft\u0131\u011F\u0131 tarihten itibaren 5 g\u00FCn i\u00E7erisinde `}

    <Text style={{ fontFamily: 'Roboto', fontSize: '10pt',color:'blue' }}>{`expertise@rbkassistance.com `}</Text>
    {`adresine bildirmenizi ve mutabakat sa\u011Flaman\u0131z\u0131 rica ederiz. Bu s\u00FCre ge\u00E7tikten sonra itirazlar\u0131n\u0131z kabul edilmeyecektir. `}</Text>
</View>

            <View style={[styles.section, { paddingTop:'0pt',marginTop: '0pt', marginBottom: '5pt', marginLeft: '45pt' }]}  >
                <Text style={{ fontFamily: 'Roboto', fontSize: '10pt' }}>
                    {`
A) FATURANIZI A\u015EA\u011EIDA BEL\u0130RT\u0130LD\u0130\u011E\u0130 \u015EEK\u0130LDE D\u00DCZENLEY\u0130N\u0130Z
1- Faturan\u0131z\u0131 do\u011Frudan do\u011Fruya tedavi etti\u011Finiz sigortal\u0131 ad\u0131na d\u00FCzenlemenizi,
2- Tedavi etti\u011Finiz her sigortal\u0131 i\u00E7in ayr\u0131 fatura d\u00FCzenlemenizi,
3- Faturan\u0131zda; Sigortal\u0131n\u0131n ad\u0131, soyad\u0131, adresi, \u015Firketimizin referans numaras\u0131n\u0131,
4- Serbest Meslek Makbuzu d\u00FCzenleyen hekimlerin stopaj uygulamas\u0131 yapmalar\u0131n\u0131 rica ederiz.
5- Faturalar\u0131n\u0131z\u0131, \u00F6deme garantisi verdi\u011Fimiz kur \u00FCzerinden d\u00FCzenlemenizi,
6- Sigortal\u0131 taraf\u0131ndan do\u011Frudan do\u011Fruya kurulu\u015Funuza yap\u0131lan \u00F6demeyi(kat\u0131l\u0131m pay\u0131n\u0131) faturan\u0131za yans\u0131tmaman\u0131z\u0131,
7- Faturan\u0131z\u0131 d\u00FCzenlemekte gecikmeye mahal vermemenizi,
8-  Faturan\u0131z\u0131 elden veya posta yolu ile \u015Firket merkezimize ula\u015Ft\u0131rman\u0131z\u0131 \u00F6nemle rica ederiz.

B) S\u0130GORTALI ADINA D\u00DCZENLEYECEN\u0130Z FATURANIZ \u0130LE B\u0130RL\u0130KTE

1- Sigortal\u0131 ve tedavi eden doktor taraf\u0131ndan imzalanan tam ve eksiksiz t\u0131bbi rapor.
2- Sigorta poli\u00E7esi fotokopisi.
3- Size g\u00F6ndermi\u015F oldu\u011Fumuz i\u015Fbu taahh\u00FCt mektubunun fotokopisini g\u00F6ndermenizi rica ederiz.

Say\u0131n \u0130\u015F Orta\u011F\u0131m\u0131z,
Faturalar\u0131n\u0131z\u0131 yukar\u0131da belirtilen d\u00FCzen i\u00E7inde d\u00FCzenlemeniz halinde cari hesap takibi ve \u00F6demelerde gecikme meydana gelmeyece\u011Fini bildirir, kar\u015F\u0131l\u0131kl\u0131 g\u00FCven prensibine dayal\u0131 i\u015Fbirli\u011Fimizin artarak devam etmesini dileriz.

Sayg\u0131lar\u0131m\u0131zla,
RBK Assistance`
                    }
                </Text>
            </View>
            <View style={[styles.section, { marginTop: '5t', marginBottom: '45t', marginLeft: '35pt',border:'solid black 12pt' }]}>
            <Text style={{ fontFamily: 'Roboto', fontSize: '10pt', color: 'rgb(15, 105, 215)', textAlign: 'center', marginTop: '0pt' }}>{`\u015Eirinyal\u0131 Mahallesi, 1502/1 Sokak, Villa RBK, Sit No: 5/1/1, Muratpa\u015Fa, Antalya,Turkey. Tel: +90 242 324 25 90, Fax: +90 242 324 25 95`}</Text>
            </View>

        </Page>
    </Document>
    :
    <div style={{background:'black' , width:'100%',height:'100%'}}>

    </div>
)};

export default GOPDocument;



