


export const fileUploader = (uploadMutationFn, extTypes, queryObject={}) => () => {
    if (!uploadMutationFn) {
        console.error('uploadMutationFn not provided');
        return;
    }
    console.log(queryObject);

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = extTypes;

    fileInput.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file, file?.name);
            console.log({ formData, ...queryObject });
            uploadMutationFn.mutate({ formData, ...queryObject });

        }
    };
    fileInput.click();
};
