
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

const useUpdateConvCaseMutation = ({ setsnackbarStatus }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ RBKCaseNo, phoneNumber }) => {

            return axios.post(
                `${config.API_URL}/operator/assignConversation`,
                { RBKCaseNo, phoneNumber },
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (variables) => {
            queryClient.invalidateQueries({ queryKey: ['messageData'] });
        },
        onError: (error, variables) => {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error During Update`,
                    severity: 'error'
                }
            )

        },

    })
}

export { useUpdateConvCaseMutation };