import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useRemoveCaseMutation = ({setsnackbarStatus, setloadingPatientsList}) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {

            setloadingPatientsList(true);
            return axios.post(
                `${config.API_URL}/data/removeCase`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });

            setsnackbarStatus(
                {
                    open: true,
                    message: `Successfully Removed ${variables?.updaterData?.RBKCaseNo}`,
                    severity: 'success'
                }
            );


        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Removing Case ${variables?.updaterData?.RBKCaseNo}`,
                    severity: 'error'
                }
            );


        },
        onSettled: (data, error, variables, context) => {
            setloadingPatientsList(false);
        }
    })





}