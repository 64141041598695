
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";
import { useNavigate } from "react-router-dom";

export const useInsuranceCompanyListQuery = ({ opMode }) => {

    const navigate = useNavigate();

    async function fetchInsuranceCompanyList() {
        return await axios.get(
            `${config.API_URL}/operator/insuranceCompanyList`,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );
    }

    return useQuery(
        {
            queryKey: ['insCoList'],
            queryFn: fetchInsuranceCompanyList,
            staleTime: Infinity,
            enabled: (!!opMode),

        }
    )
}