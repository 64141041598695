
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";


export const useDashboardQuery = ({limit}) => {

    async function fetchDashboardData({ queryKey }) {

        const [_key, filterObj] = queryKey;

        return await axios.get(`${config.API_URL}/operator/dashboard?limit=${filterObj.limit}`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['dashboardData',

            { limit }


            ],
            queryFn: fetchDashboardData,
            staleTime: 1000 * 60 * 60 * 24, // 6 hours
        }


    )

}