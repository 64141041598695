import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';

import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import useLocalStorage from '../hooks/useLocalStorage';
import { useValidateQuery } from '../hooks/useValidateQuery';
import { CustomSnackbar } from '../components/CustomSnackbar';
import NavigationBar from '../components/NavigationBar';
import { useRefreshQuery } from '../hooks/useRefreshQuery';
import { Autocomplete, Badge, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import parsePhoneNumber from 'libphonenumber-js'
import CommentsComponent from '../components/PatientFormContainer/pages/PatientInfoPage/components/CommentsComponent';
import { useWhatsappChatQuery } from '../components/Chat/hooks/useWhatsappChatQuery';
import WhatsappChatComponent from '../components/Chat/WhatsappChatComponent';
import { useSocket } from '../components/contextProviders/SocketProvider';

import RBKIcon from '../resources/icon.png';
import { PatientInfoList } from '../components/PatientFormContainer/pages/PatientInfoPage/components/PatientInfoList';
import { usePatientsQuery } from '../hooks/usePatientsQuery';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Popper } from '@mui/material/';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useUpdateConvCaseMutation } from '../components/Chat/hooks/useUpdateConvCaseMutation';
import TextLanguage from '../data/TextLanguage.json';

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        background: 'transparent',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
}));




function remToPx(rem) {
    return (rem * parseFloat(getComputedStyle(document.documentElement).fontSize))
}




const RowButton = styled(Button)(({ theme }) => ({


    textTransform: 'none',




    borderRadius: 0,
    height: '65px',
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center',
    background: 'transparent',
    fontWeight: 'bold',
    fontSize: '0.85rem',
    padding: 0,

    boxShadow: `inset 0px 0px 20px 0px ${theme.palette.shadow[2]}, 0px 0px 12px -2px ${theme.palette.shadow[0]}`,

    background: theme.palette.gradient.b,

    '&:hover, &.Mui-focusVisible': {

        color: theme.palette.successColor.main,
        outline: 'none',

        boxShadow: `inset 0px 0px 18px -2px ${theme.palette.buttonGlow[1]}`,

    },


}));







function ChatPage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();
    const [opMode, setopMode] = useState(false);

    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });


    // Context Menu End

    const navigate = useNavigate();


    const [confirmedUserID, setconfirmedUserID] = useState(null);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
          queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])



    const { data, isSuccess } = useWhatsappChatQuery({ limit: 100, page: 0 });

    useEffect(() => {
        if (validateQuery.isSuccess) {
            setconfirmedUserID(validateQuery.data.data?.userID);

            if (["Admin", "Expertise", "Operator"].includes(validateQuery.data.data?.userID)) {
                setopMode(true);
            } else {
                setopMode(false);
                navigate("/");
            }
        }
        if (validateQuery.isError) {
           // queryClient.invalidateQueries(['refresh'])
          //  console.log('Access Token Expired');
        }
    }, [validateQuery.data, validateQuery.isSuccess, validateQuery.isError])

    const [selectedChat, setSelectedChat] = useState(0);

    const socket = useSocket();
    const [notificationState, setNotificationState] = useState(true);

    useEffect(() => {

        if (notificationState && Notification.permission !== "granted") {
            Notification.requestPermission();
        }

    }, [notificationState])

    const invalidateDebounceRef = useRef(null);
    useEffect(() => {

        socket.on('update', (msg) => {

            console.log('socket', msg);


            invalidateDebounceRef.current && clearTimeout(invalidateDebounceRef.current);
            invalidateDebounceRef.current = setTimeout(() => {
                queryClient.invalidateQueries({ queryKey: ['messageData'] });
            }, 250);

            if (msg.type === 'NewMessage') {

                if ('Notification' in window && notificationState) {

                    Notification.requestPermission().then(function (permission) {

                        if (permission === 'granted') {
                            const contentType = msg?.value?.content?.type;

                            var notification = new Notification(`+${msg?.value?.from}`, {
                                body: `${contentType === 'text' ? msg?.value?.content?.text : 'Media'}`,
                                icon: RBKIcon,
                                silent: true
                            });


                            notification.onclick = function () {

                                navigate('/chat');
                                const findChat = data?.data?.findIndex((chat) => chat.conversation === msg?.value?.from) || 0;
                                setSelectedChat(findChat);

                                window.focus();
                            };

                        } else {
                            console.log('Notification permission denied');
                        }
                    });
                } else {

                    console.log('Browser does not support notifications');
                }


            }


        })

        return () => {
            socket.off('update');
        }

    }, [socket])

    const isConversationAssigned = data?.data[selectedChat]?.RBKCaseNo ? true : false;

    const [patientSearchText, setPatientSearchText] = useState('');

    const singlePatientQuery = usePatientsQuery({ disableClick: false, page: 0, pageSize: 1, sortField: null, sortOrder: null, searchFilter: { caseNumber: data?.data[selectedChat]?.RBKCaseNo || patientSearchText } });

    const searchPatientQuery = usePatientsQuery({ disableClick: false, page: 0, pageSize: 1, sortField: null, sortOrder: null, searchFilter: { caseNumber: patientSearchText } });

    const queryCaseNoList = searchPatientQuery?.data?.data?.patientList?.map((patient) => patient?.RBKCaseNo) || [];

    const updateConvMutation = useUpdateConvCaseMutation({ setsnackbarStatus });
    const handleAssignToConversation = () => {
        updateConvMutation.mutate({
            RBKCaseNo: searchPatientQuery?.data?.data?.patientList[0]?.RBKCaseNo,
            phoneNumber: data?.data[selectedChat]?.conversation
        })


    }



    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <NavigationBar
                    currentPage='chat'
                    lang={lang}
                    opMode={opMode}
                    notificationState={notificationState}
                    setNotificationState={setNotificationState}
                />




                <div className='ChatPageContainer'>
                    <div className='ChatSelectionTable'>


                        <div className='ChatTableHeader'>
                            <Typography variant='h4' sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white' }}>
                                Incoming Messages
                            </Typography>
                        </div>



                        {isSuccess && <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            {data?.data.map((messageData, index) => {
                                return (
                                    <ChatButton
                                        index={index}
                                        key={index}
                                        selectedChat={selectedChat}
                                        setSelectedChat={setSelectedChat}
                                        messageData={messageData}
                                    />
                                )
                            })}
                        </div>}



                    </div>
                    <div style={{ width: '100%', height: '100%', }}>
                        {isSuccess &&
                            <WhatsappChatComponent
                                selectedChat={selectedChat}
                                setsnackbarStatus={setsnackbarStatus}
                                messageQueryData={data}

                            />
                        }
                    </div>

                    <div style={{
                        background: '#353535',
                        borderLeft: `1px solid black`,
                        boxShadow: `inset 0px 0px 20px 0px ${theme.palette.shadow[1]}`
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{
                                width: '100%', padding: '1rem',
                                background: theme.palette.gradient.d,
                                boxShadow: `inset 0px 0px 20px 0px ${theme.palette.shadow[1]}`,
                                borderBottom: `1px solid ${theme.palette.light[3]}`
                            }}>
                                <Typography> {
                                    isConversationAssigned ?
                                TextLanguage["ConnectedPatientCase"][lang]
                                :
                                TextLanguage["AssignToPatientCase"][lang]
                                }</Typography>
                            </div>
                            <div style={{padding:8, borderBottom: `1px solid ${theme.palette.light[3]}`, background: theme.palette.dark[0], display:'flex', flexDirection:'row',gap:8

                            }}>

                                <Autocomplete
                                    sx={{
                                        '.MuiFormControl-root': {
                                            width: '100%', background: theme.palette.dark[4], color: 'white', borderRadius: 0, border: 'none'
                                        }
                                    }}
                                    className="CaseSearchAutocomplete"

                                    onInputChange={(event, newInputValue) => { setPatientSearchText(newInputValue) }}
                                    onChange={(event, value) => { setPatientSearchText(value) }}
                                    size="small"

                                    value={patientSearchText}
                                    PopperComponent={StyledPopper}
                                    //getOptionLabel={(patient) => `${patient?.RBKCaseNo}`}
                                    options={queryCaseNoList}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.toLowerCase() === value?.toLowerCase()
                                    }
                                    // ListboxComponent={ListboxComponent}
                                    noOptionsText={"No Matches"}

                                    renderInput={(params) => <TextField {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position="start">
                                                        <SearchIcon sx={{ marginLeft: '4px' }} />
                                                    </InputAdornment>
                                                    {params.InputProps.startAdornment}
                                                </>
                                            )
                                        }}
                                        variant="outlined"
                                    />}
                                />
                                 <Button
                                    onClick={handleAssignToConversation}
                                    sx={{
                                        borderRadius: 0,
                                        padding:'4px 16px !important',
                                        background: theme.palette.gradient.a,
                                        color: 'white',
                                        '&:hover': {
                                            boxShadow: `inset 0px 0px 12px -2px ${theme.palette.buttonGlow[3]}`,
                                            background: theme.palette.gradient.a,
                                            outline: 'none',
                                            border: 'none',
                                            filter: 'brightness(1.25) contrast(1.1)',
                                        },
                                    }}>
                                        {isConversationAssigned ? TextLanguage["Reassign"][lang] : TextLanguage["Assign"][lang]}
                                    </Button>
                            </div>
                           {isConversationAssigned && singlePatientQuery?.data?.data?.patientList[0] && <>

                               <div className='patientInfoHeader' >
                                   <Typography sx={{ color: 'white', fontSize: '1rem', fontWeight: 'bold'}}>
                                        {`${singlePatientQuery?.data?.data?.patientList[0]?.RBKCaseNo}`}
                                    </Typography>

                               </div>

                                <PatientInfoList
                                    lang={lang}
                                    selectedRow={singlePatientQuery?.data?.data?.patientList[0]}
                                    selectedhospCaseID={null}
                                    onRowFileURLs={null}
                                    setselectedRow={null}
                                    opMode={true}
                                    setsnackbarStatus={setsnackbarStatus}
                                />
                           </>}


                        </div>
                    </div>



                </div>


            </div>
        </LocalizationProvider>
    );
}

export default ChatPage;

const ChatButton = ({ messageData, index, selectedChat, setSelectedChat }) => {


    const theme = useTheme();

    const phoneNumber = `+${messageData?.conversation}`
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'RU')
    let countryFlagSet = true;
    if (!parsedPhoneNumber) {
        countryFlagSet = false;
    }
    const latestMessage = messageData?.messages.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
    const latestMessageTime = new Date(latestMessage?.date);
    const parseLatestMessageTime = latestMessageTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return (
        (<RowButton
            onClick={() => setSelectedChat(index)}
            sx={{ color: selectedChat === index ? 'lightgreen' : 'white' }}>
            <div className='ChatButtonRow'>

                <div className='ChatButtonLeft'>

                    <div style={{ gap: '0.5rem', display:'flex', flexDirection:'row' }}>
                        {countryFlagSet ?
                            <>
                                <span className={`fi fi-${parsedPhoneNumber?.country?.toLowerCase()}`}></span>
                                <Typography>{`+${parsedPhoneNumber?.countryCallingCode}`}</Typography>
                                <Typography>{parsedPhoneNumber?.nationalNumber}</Typography>
                            </>
                            :
                            <Typography>{phoneNumber}</Typography>}
                    </div>
                    <div style={{ width: '100%', position: 'relative', textOverflow: 'ellipsis' }}>
                      { latestMessage?.message?.type.toUpperCase() === 'TEXT' &&
                      <p style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: 0, padding: 0, color: theme.palette.text.secondary, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {`${latestMessage?.message?.text}`}
                        </p>
                        }
                        { latestMessage?.message?.type.toUpperCase() === 'IMAGE' &&
                        <p style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: 0, padding: 0, color: theme.palette.text.secondary, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {`${latestMessage?.message?.caption || 'Image' }`}
                        </p>
                        }
                    </div>


                </div>
                <div className='ChatButtonRight'>

                    <Typography sx={{}}>
                        {parseLatestMessageTime}
                    </Typography>
                </div>



            </div>
        </RowButton>));
}

