import React from 'react';
import {FormControl,Select,MenuItem,Typography, InputLabel} from '@mui/material/';


const LanguageSelector = ({ lang, setlang }) => {
  const handleLangChange = (event) => { setlang(event.target.value); };
return(


<FormControl
variant='standard'
sx={{ marginLeft: 'auto', marginRight: '0',

border: 'none !important',
    outline: 'none !important',

'& .MuiInputBase-root': {
    height:"100%", width: '10rem' ,
    borderRadius: '8px 0px 0px 8px',
    border: 'none !important',
    outline: 'none !important',
    background: 'linear-gradient(0deg, #2f2f2fc2, #5455617a)',
    '&:before': {
        border: 'none !important',
        outline: 'none !important',
        },

    }

    }}>

<Select
  className="LangSelect"
  labelId="langselect-label"
  id="langselect"
  value={lang}


  onChange={handleLangChange}
>

  <MenuItem value={'trTR'}> <span className="fi fi-tr"></span> <Typography sx={{ color: 'white', m: 0, marginLeft: 1 }}>Türkçe</Typography></MenuItem>
  <MenuItem value={'enUS'}> <span className="fi fi-gb "></span> <Typography sx={{ color: 'white', marginLeft: 1 }}> English</Typography></MenuItem>
  <MenuItem value={'ruRU'}> <span className="fi fi-ru "></span> <Typography sx={{ color: 'white', marginLeft: 1 }}> Pусский</Typography></MenuItem>
</Select>
</FormControl>

);

};

export default LanguageSelector;