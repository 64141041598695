
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";


export const useActivePaymentListAggrQuery = ({userIDArray, dueDateValue}) => {




    async function fetchActivePaymentFiltered({queryKey}) {
        const [_key, filters] = queryKey;


        return await axios.post(`${config.API_URL}/payment/processedPatientAggregate`,
        filters,
        {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ActivePaymentListAggr',
        {
            userIDArray,
            dueDateValue
        }
        ],
            queryFn: fetchActivePaymentFiltered,
            enabled: userIDArray !== null && dueDateValue !== null,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


