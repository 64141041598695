
import React from "react";
import { Divider, Typography } from '@mui/material';

import TextLanguage from '../../../data/TextLanguage.json';

export const GOPPriceGroupHeader = ({lang}) =>
<div className="GOPPriceRowGroupHeader">
<div style={{gridRowStart:1,gridColumn:'span 5', width:'auto'}}>

    <Typography >
        {`${TextLanguage["GOPPrice"][lang]}`}
    </Typography>

</div>

<Divider orientation="horizontal" sx={{gridRowStart:'2 !important',gridColumn:'span 5', width:'100%'}}  />

                    <Typography className='GOPHeaderColumn' >
                        {`(USD)`}
                    </Typography>
                <Divider orientation="vertical"  sx={{gridRowStart:'3 !important'}}  />

                    <Typography className='GOPHeaderColumn' >
                    {`${TextLanguage["EXR"][lang]}`}
                </Typography>

                <Divider orientation="vertical" sx={{gridRowStart:'3 !important'}}  />

                <Typography className='GOPHeaderColumn'>
                    {`(TRY)`}
                </Typography>
                </div>


