
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useRBACDataQuery = () => {


    async function rbacFetchFn({ queryKey }) {

        return await axios.get(`${config.API_URL}/operator/rbacData`,
            {
            withCredentials: true
        });



    }

    return useQuery({
        queryKey: ['rbacData'],
        queryFn: rbacFetchFn,
    });




}