export const NivoTheme = {
    "text": {
        "fontSize": 21,
        "fill": "#ffffff",
        "outlineWidth": 3,
        "outlineColor": "#ff0000"
    },
    "axis": {
        "domain": {
            "line": {
                "stroke": "rgb(255,255,255)",
                "strokeWidth": 1,
                'strokeOpacity': 0.7
            }
        },
        "legend": {
            "text": {
                "fontSize": '1rem',
                "fill": "#ffffff",
                "outlineWidth": 0,
                "outlineColor": "transparent"
            },
        },
        "ticks": {
            "line": {
                "stroke": "rgb(255,255,255)",
                "strokeWidth": 1.5,
                'strokeOpacity': 1
            },
            "text": {
                "fontSize": 16,
                "fill": "#ffffff",
                "outlineWidth": 1,
                "outlineColor": "#00000047"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#dddddd",
            "strokeWidth": 0.5,
            'strokeOpacity': 0.25
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 18,
                "fill": "#333333",
            }
        },
        "text": {
            "fontSize": 20,
            "fill": "#ffffff",
        },
        "ticks": {
            "line": {},
            "text": {
                "fontSize": 18,
                "fill": "#333333",
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 18,
            "fill": "#333333",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "#000000",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "#000000",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "#000000",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        "container": {
            "background": "rgba(34, 34, 37, 0.8)",
            "fontSize": 16
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
};
