
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";


export const useDashboardPatientsQuery = () => {

    async function fetchDashboardPatientData() {



        return await axios.get(`${config.API_URL}/operator/dashboardPatients`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['dashboardCardData'],
            queryFn: fetchDashboardPatientData,
            staleTime: 1000 * 60 * 60 * 24, // 6 hours
        }


    )

}