import React, { useState } from "react";
import TextLanguage from "../../../data/TextLanguage.json";
import {
    IconButton} from '@mui/material';


import DateFilter from "../DateFilter";
import TextFilter from "../TextFilter";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { DashboardFilterButtons } from "./DashboardFilterButtons";


const filterThresholdWidth = 1860;

export const DataTableHeader = ({
    filterToggle, viewportWidth, lang, opMode,
    setSearchFilter,
    patientsQuery,
    setFilterToggle, searchFilter,
    handleSort
 }) => {





    const onDateFilter = (filter) => {
        setSearchFilter({ ...searchFilter, ...filter });

    };

    const onTextFilter = (field) => (filter) => {
        setSearchFilter({ ...searchFilter, ...{ [field]: filter } });

    }





    return (<div className='datatableHeader' id='divDTHeader'>

        {(filterToggle || viewportWidth >= filterThresholdWidth) ? null : <div className='datatableHeaderFilterRowContainer' style={{ justifyContent: 'flex-start' }}>
            <TextFilter dense={true} labelName={`RBK ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('caseNumber')} />
           {opMode && <TextFilter dense={true} labelName={`LDM ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('LDMCaseNo')} /> }
            <TextFilter dense={true} labelName={TextLanguage["FilterByName"][lang]} onFilter={onTextFilter('name')} />

        </div>}



        <div className='datatableHeaderFilterRowContainer'>


            {viewportWidth < filterThresholdWidth ?
                <IconButton onClick={() => { setFilterToggle(!filterToggle); }}> <FilterAltIcon fontSize='large' htmlColor='white' /></IconButton>
                :
                <>
                    <TextFilter labelName={`RBK ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('caseNumber')} />
                    {opMode && <TextFilter labelName={`LDM ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('LDMCaseNo')} />}
                    <TextFilter labelName={TextLanguage["FilterByName"][lang]} onFilter={onTextFilter('name')} />


                </>}

            <DateFilter onFilter={onDateFilter} startLabel={TextLanguage["StartDate"][lang]} endLabel={TextLanguage["EndDate"][lang]} />





            <div className='PipelineFilterRow'>

                <DashboardFilterButtons

                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}

                patientsQuery={patientsQuery}
                lang={lang}



            />
                      </div>



        </div>



    </div>);
}

