
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const usePatientActivityLogQuery = ({RBKCaseNo = null}) => {


    async function PatientLogFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        console.log(QuerySelection);
        const { RBKCaseNo } = QuerySelection;

        if(!!RBKCaseNo) {
            return await axios.get(`${config.API_URL}/data/activityLog?RBKCaseNo=${RBKCaseNo}`, {
                withCredentials: true
            });
        } else {
            return await axios.get(`${config.API_URL}/data/activityLog`, {
                withCredentials: true
            });
        }





    }

    return useQuery({
        queryKey: ['activityLog',
            {
                RBKCaseNo: RBKCaseNo ? RBKCaseNo : null
            }

        ],
        queryFn: PatientLogFetchFn,


    });




}