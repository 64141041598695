import React from "react";
import { Typography, Divider } from '@mui/material';
import numeral from "numeral";

export const formatGOPPriceGroupPayments = (hospCaseRow) => {

    const parsedPriceUSD = numeral(hospCaseRow.GOPTotalUSD).format('0,0.00');
    const parsedPriceTL = numeral(hospCaseRow.GOPTotalTL / 100).format('0,0.00');

    const parsedPriceUSDAvg = numeral(hospCaseRow.GOPTotalUSD / hospCaseRow.totalCount).format('0,0.00');
    const parsedPriceTLAvg = numeral((hospCaseRow.GOPTotalTL / 100) / hospCaseRow.totalCount).format('0,0.00');


    return (<div className="GOPPriceRowGroup">


        <div style={{display:'flex', flexDirection:'row',gap:'1rem'}}>
            <Typography className='RowPriceText'>
                {`$ ${parsedPriceUSD}`}
            </Typography>
            <Typography className='RowPriceText' style={{color: 'white'}}>
                {`($ ${parsedPriceUSDAvg})`}
            </Typography>
        </div>
        <Divider orientation="vertical" flexItem />

        <Typography className='RowPriceText'>
            {`${((hospCaseRow.GOPTotalTL / 100) / hospCaseRow.GOPTotalUSD).toFixed(1)}`}
        </Typography>

        <Divider orientation="vertical" flexItem />

        <div style={{display:'flex', flexDirection:'row',gap:'1rem'}}>
            <Typography className='RowPriceText'>
                {`${parsedPriceTL} ₺`}
            </Typography>
            <Typography className='RowPriceText' style={{color: 'white'}}>
                {`(${parsedPriceTLAvg} ₺)`}
            </Typography>
        </div>

        <Divider orientation="vertical" flexItem />

        {
            /*
            <Typography className='RowPriceText'>
            {`$`}
        </Typography>
        <Typography className='RowPriceText'>
            {`${AvgPriceStringSplit},${AvgPriceStringAfterComma}`}
        </Typography>
*/
}
    </div>
    )








}
