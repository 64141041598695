import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '0.5rem',
    width: '100%',
    borderRadius: 5,

    outline: '1px solid rgba(174, 241, 172, 0.8)',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgba(14, 129, 68, 0.56)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#066030a8' : 'rgba(33,194,27,0.8)',
    },
}));

export { BorderLinearProgress};