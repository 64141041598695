
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";
import { useNavigate } from "react-router-dom";

export const useHospitalTokenListQuery = () => {

    const navigate = useNavigate();

    async function fetchHospitaTokens() {

        return await axios.get(
            `${config.API_URL}/data/blobTokenList`,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );

    }

    return useQuery(
        {
            queryKey: ['hospitalTokenList'],
            queryFn: fetchHospitaTokens,
            staleTime: 1000 * 60 * 55, // 55 minutes
            refetchInterval: 1000 * 60 * 55, // 55 minutes


        }




    )



}