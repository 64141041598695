
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

import { useNavigate } from "react-router-dom";

export const useArchivedHospPatientsQuery = ({paymentID, selectedUserID}) => {


    const navigate = useNavigate();

    async function fetchPatients({ queryKey }) {
        const [_key, query] = queryKey;
        return await axios.get(
            `${config.API_URL}/payment/archivedPatientsList?paymentID=${query.paymentID}&selectedUserID=${query.selectedUserID}`,
            {
                withCredentials: true
            }
        );

    }



    return useQuery(
        {
            queryKey: ['archivedPatients',

                {
                    selectedUserID: selectedUserID,
                    paymentID: paymentID

                }

            ],
            queryFn: fetchPatients,
            enabled: (!!paymentID),
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes


        }




    )



}