import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

const useUpdatePatientGOPMutation = ({setselectedRow, setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {


            return axios.post(
                `${config.API_URL}/data/updateGOP`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setsnackbarStatus({ open: true, message: "Updated GOP", severity: 'success' })
            setselectedRow(data.data)
           // handleReplaceForm(data.data)
            console.log("Updated GOP", data.data);
        },
        onError: (error) => {
            console.log(error);
            setsnackbarStatus({ open: true, message: "Error Updating GOP", severity: 'error' })
        }
    });

}

export { useUpdatePatientGOPMutation };