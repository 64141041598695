
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";
import { useNavigate } from "react-router-dom";

export const useActivePaymentListQuery = ({opMode}) => {


    const navigate = useNavigate();

    async function fetchActivePayment({ queryKey }) {
        const [_key, filtersData] = queryKey;

        return await axios.get(`${config.API_URL}/payment/ActivePaymentList`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ActivePaymentList'],
            queryFn: fetchActivePayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes
            retry: (failureCount, error) => {
                return (failureCount < 3  && error.response?.status !== 406)
            },
            enabled: (!!opMode),

        }
    )





}


