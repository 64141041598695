import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import { useNavigate } from "react-router-dom";

import { NivoTheme } from '../components/Dashboard/NivoTheme'

// Custom Components

// Custom Functions

import { useExchangeRateQuery } from '../hooks/useExchangeRateQuery';

// Data (JSON)


import { useTheme } from '@mui/material/styles';


import { useQueryClient } from '@tanstack/react-query';


import useLocalStorage from '../hooks/useLocalStorage';

import { useValidateQuery } from '../hooks/useValidateQuery';

import { CustomSnackbar } from '../components/CustomSnackbar';

import NavigationBar from '../components/NavigationBar';

import { line, curveMonotoneX, area } from 'd3-shape';
import { rgb } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateWarm, interpolateCividis, interpolatePlasma, interpolatePiYG, interpolateCool, interpolateBlues } from 'd3-scale-chromatic';
import { useDashboardQuery } from '../components/Dashboard/hooks/useDashboardQuery';
import { NivoChartDataGenerator } from '../components/Dashboard/functions/NivoChartDataGenerator';
import { ResponsiveLine } from '@nivo/line'
import { ResponsivePie } from '@nivo/pie'
import { Button, CircularProgress, TextField } from '@mui/material';
import { UnderlinedButton } from '../components/Dashboard/components/UnderlinedButton';
import { usePatientActivityLogQuery } from '../components/PatientFormContainer/hooks/usePatientActivityLogQuery';
import { ActivityLog } from '../components/PatientFormContainer/pages/PatientInfoPage/components/ActivityLog';
import { styled } from '@mui/material/styles';
import { useDashboardPatientsQuery } from '../components/Dashboard/hooks/useDashboardPatientsQuery';
import { DashboardCard } from '../components/Dashboard/components/DashboardCard';
import { useRefreshQuery } from '../hooks/useRefreshQuery';


//import "primereact/resources/themes/lara-light-indigo/theme.css";


//core
const FormField = styled(TextField)(({ theme }) => ({
    color: theme.palette.text.primary,
    background: 'none',
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '800px',

    '&:hover, &.Mui-focusVisible': {

        background: 'none',
        borderRadius: 0,
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
    },
    '.MuiInputBase-formControl': {
        paddingInline: '0.25rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },

    '.MuiInputBase-inputMultiline': {
        paddingInline: '0.5rem 0.5rem !important',
        paddingBlock: '0.25rem !important'
    },
    '.MuiInputBase-root': {
        '&:before': {
            borderBottom: 'none !important'
        }
    },
    '.MuiSelect-select': {
        border: 'none !important',
        paddingInline: '0.5rem 2.5rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },
    '.MuiFormHelperText-root': { color: theme.palette.text.primary },
}));

function remToPx(rem) {
    return (rem * parseFloat(getComputedStyle(document.documentElement).fontSize))
}


function DashboardPage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();
    const [opMode, setopMode] = useState(false);

    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });



    const [dashboardLimit, setDashboardLimit] = useState(90);

    const dashboardQuery = useDashboardQuery({
        limit: dashboardLimit
    });

    const dashboardPatientsQuery = useDashboardPatientsQuery();

    const [displayedListName, setdisplayedListName] = useState('DailyCaseList');



    let seriesOperatorDaily = [];
    let seriesOperatorSum = 0;
    let seriesOperatorPie = [];
    const [dailyList, setDailyList] = useState([]);
    const [pieList, setPieList] = useState([]);
    const [listSum, setListSum] = useState(1);
    useEffect(() => {

        if (dashboardQuery.isSuccess) {



            if (displayedListName !== 'DailyCaseList') {

                [seriesOperatorDaily, seriesOperatorPie, seriesOperatorSum] = NivoChartDataGenerator(dashboardQuery?.data?.data?.dailyList[displayedListName]?.sort((a, b) => a.Sum - b.Sum));

                setDailyList(seriesOperatorDaily);
                setPieList(seriesOperatorPie);
                setListSum(seriesOperatorSum);


            } else if (displayedListName === 'DailyCaseList') {

                setDailyList([
                    {

                        id: 'Total Cases',
                        color: '#ffffff',

                        data: dashboardQuery?.data?.data?.caseList.map((record) => {
                            return { x: record["Date"], y: record['yTotal'] }
                        })
                    }
                ]);

                setPieList([]);
                setListSum(1);
            }



        } else if (dashboardQuery.isError) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Dashboard Data`,
                    severity: 'error'
                }
            )

        }




    }, [dashboardQuery.data, dashboardQuery.isSuccess, dashboardQuery.isError])


    useEffect(() => {
        if (!dashboardQuery.isSuccess || !displayedListName) return;

        if (displayedListName !== 'DailyCaseList') {

            [seriesOperatorDaily, seriesOperatorPie, seriesOperatorSum] = NivoChartDataGenerator(dashboardQuery?.data?.data?.dailyList[displayedListName]?.sort((a, b) => a.Sum - b.Sum));

            setDailyList(seriesOperatorDaily);
            setPieList(seriesOperatorPie);
            setListSum(seriesOperatorSum);


        } else if (displayedListName === 'DailyCaseList') {

            setDailyList([
                {

                    id: 'Total Cases',
                    color: '#ffffff',

                    data: dashboardQuery?.data?.data?.caseList.map((record) => {
                        return { x: record["Date"], y: record['yTotal'] }
                    })
                }
            ]);

            setPieList([]);
            setListSum(1);
        }




    }, [displayedListName, dashboardLimit])



    const activityLogQuery = usePatientActivityLogQuery({ RBKCaseNo: null });



    // Context Menu End

    const navigate = useNavigate();


    const [confirmedUserID, setconfirmedUserID] = useState(null);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
            queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])



    useEffect(() => {
        if (validateQuery.isSuccess) {
            setconfirmedUserID(validateQuery.data.data?.userID);

            if (["Admin"].includes(validateQuery.data.data?.userID)) {
                setopMode(true);
            } else {
                setopMode(false);
                navigate("/");
            }
        }

    }, [validateQuery.data, validateQuery.isSuccess, validateQuery.isError])
















    const exchangeRateQuery = useExchangeRateQuery({ opMode });

    useEffect(() => {
        if (exchangeRateQuery.isError && exchangeRateQuery?.error?.response?.status !== 401) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Exchange Rate`,
                    severity: 'error'
                }
            )

        }
    }, [exchangeRateQuery.data, exchangeRateQuery.isError]);

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <NavigationBar
                    currentPage='dashboard'
                    lang={lang}
                    opMode={opMode}
                />

                <div className='dashboardPageContainer' >

                    {<div style={{ maxHeight: '100%', overflowY: 'auto', width: '25%', minWidth: '350px', height: '100%' }} >

                        {activityLogQuery.isSuccess && <ActivityLog
                            mainActivityLog={true}
                            disableClick={true}
                        />}
                        {
                            activityLogQuery.isLoading && <div className='dashboardCard' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '42vh', width: '100%' }}>
                                <CircularProgress />
                            </div>
                        }

                    </div>}

                    <div className='dashboardGrid'>

                        {<div className='dashboardCard' style={{ flexGrow: 2 }} >


                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingInline: '100px' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '2px solid rgba(255,255,255,0.25)' }}>
                                    {dashboardQuery.isSuccess &&
                                        <FormField
                                            label="Type"
                                            select
                                            SelectProps={{ native: true }}
                                            variant="filled"
                                            size="small"
                                            style={{ width: '400px' }}
                                            onChange={(e) => { setdisplayedListName(e.target.value) }}
                                        >
                                            <option key={'DailyCaseList'} value={'DailyCaseList'}>
                                                {'Daily Case List'}
                                            </option>
                                            {Object.keys(dashboardQuery?.data?.data?.dailyList)?.map((option) => {

                                                return (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                )
                                            })}

                                        </FormField>}

                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                        <UnderlinedButton onClick={() => { setDashboardLimit(365) }} isActive={dashboardLimit === 365} label='Yearly' />
                                        <UnderlinedButton onClick={() => { setDashboardLimit(90) }} isActive={dashboardLimit === 90} label='3 Months' />
                                        <UnderlinedButton onClick={() => { setDashboardLimit(30) }} isActive={dashboardLimit === 30} label='Last Month' />
                                    </div>
                                </div>
                            </div>



                            {dashboardQuery.isSuccess && (dailyList?.length > 0) && <ResponsiveLine
                                data={dailyList.filter((op) => op.Sum !== 0)}
                                animate


                                curve="monotoneX"
                                //colors={{ datum: 'color' }}
                                enableSlices="x"

                                pointColor={{ theme: 'background' }}
                                enablePoints={false}

                                margin={{
                                    bottom: 80,
                                    left: 110,
                                    right: 60,
                                    top: 30
                                }}
                                theme={NivoTheme}

                                enableGridX={true}
                                enableGridY={true}
                                //enableArea={true}
                                //areaBlendMode='color-dodge'
                                lineWidth={1}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: 'auto',
                                    stacked: false,
                                    reverse: false
                                }}

                                xFormat="time:%Y-%m-%dT%H:%M:%S.%LZ"
                                xScale={{
                                    format: '%Y-%m-%dT%H:%M:%S.%LZ',
                                    precision: 'day',
                                    type: 'time',
                                }}



                                axisBottom={{
                                    format: (value) => {
                                        const date = new Date(value);
                                        return `${date.getMonth() + 1} / ${date.getDate()}`;
                                    },
                                    legend: 'time scale',
                                    legendOffset: -12,
                                    tickValues: 'every 30 days'
                                }}
                                axisLeft={{
                                    tickSize: 15,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Amount',
                                    legendOffset: -80,
                                    legendPosition: 'middle'
                                }}
                                axisTop={null}
                                axisRight={null}


                            />}


                            {
                                dashboardQuery.isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress />
                                </div>
                            }

                        </div>}

                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gridTemplateRows: 'minmax(0, 1fr)',
                            gap: '1.5rem',
                            width: '100%',
                            height: '100%',
                        }}>
                            {dashboardPatientsQuery.isSuccess && <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridTemplateRows: '1fr 1fr 1fr',
                                gap: '1.5rem',
                                width: '100%',
                                height: '100%',
                            }}>
                                {Object.keys(dashboardPatientsQuery?.data?.data)?.map((key) => {

                                    return (
                                        <DashboardCard
                                            cardTitle={key}
                                            cardCount={dashboardPatientsQuery?.data?.data[key]}
                                        />
                                    )
                                })}
                            </div>}




                            {pieList && pieList.length > 0 && <div className='dashboardCard' style={{ padding: 12 }} >
                                {dashboardQuery.isSuccess && (dailyList?.length > 0) && <ResponsivePie
                                    animate={true}
                                    theme={NivoTheme}
                                    data={pieList.reverse()}
                                    //colors={{ datum: 'data.color' }}
                                    margin={{ top: 35, right: 125, bottom: 35, left: 125 }}
                                    innerRadius={0.35}
                                    padAngle={1.5}
                                    startAngle={0}
                                    cornerRadius={4}
                                    sortByValue={true}
                                    activeOuterRadiusOffset={18}
                                    /*
                                    borderColor={{from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1
                                        ]
                                    ]}}
                                    */
                                    borderWidth={0}
                                    valueFormat={value => `${(value / listSum * 100).toFixed(2)}%`}
                                    arcLinkLabelsSkipAngle={8}
                                    arcLinkLabelsTextColor="#ffffff"
                                    arcLinkLabelsThickness={3}
                                    arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                                    arcLabelsSkipAngle={18}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                7
                                            ]
                                        ]
                                    }}
                                    motionConfig={{
                                        mass: 15,
                                        tension: 455,
                                        friction: 100,
                                        clamp: true,
                                        precision: 0.01,
                                        velocity: 0
                                    }}
                                    transitionMode='innerRadius'
                                />}
                                {
                                    dashboardQuery.isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CircularProgress />
                                    </div>
                                }
                            </div>}



















                        </div>






                    </div>




                </div>


            </div>

        </LocalizationProvider>










    );
}

export default DashboardPage;









