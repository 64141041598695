
import React, { useState, useEffect } from 'react';



import FileSelector from './FileSelector';
import CheckSwitch from '../pages/DocumentsPage/CheckSwitch';

import {
    Typography
} from '@mui/material';

import { PDFSplitterButton } from '../pages/DocumentsPage/PDFSplitterButton';
import { useQueryClient } from '@tanstack/react-query';

const FileSelectorHeader = ({
    switchMode = true,
    filetype = 'all',
    selectorEnabled = true,
    onRowFileURLs,
    displayfileIndex,
    setdisplayfileIndex,
    docsMissingFlag,
    setdocsMissingFlag,
    selectedhospCaseID,
    opMode,
    updateFlagsMutation = null,
    setsnackbarStatus,
    selectedRow,
    maxPageCount,

}) => {

    const queryClient = useQueryClient();

    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);


    const handleDocsSwitchChange = (event) => {
        setdocsMissingFlag(!event.target.checked);

        if (opMode && switchMode && selectedhospCaseID) {
            !event.target.checked ?
                updateFlagsMutation?.mutate({ flag: "MissingDocs", state: true })
                :
                updateFlagsMutation?.mutate({ flag: "MissingDocs", state: false })
        }
    };


    return (<div className='DocsButtonControlsGroup'>

        {selectorEnabled ?
            <FileSelector
                onRowFileURLs={onRowFileURLs}
                displayfileIndex={displayfileIndex}
                setdisplayfileIndex={setdisplayfileIndex}
                filetype={filetype}
                switchMode={switchMode}
            />
            : null
        }
        {hospitalUserListData?.status === 200 && <Typography variant='h4' sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: 'white', marginInline: 'auto' }}>
            {hospitalUserListData?.data.find(hosp => selectedhospCaseID === hosp.value)?.label}
        </Typography>}



        {opMode && switchMode ?
            <div className='DocsPageSwitchContainer'>
                <>
                    <Typography sx={{ color: docsMissingFlag ? '#fc2c2c' : 'white' }}>
                        {`Docs Missing`}
                    </Typography>
                    <CheckSwitch checked={!docsMissingFlag} onChange={handleDocsSwitchChange} />
                    <Typography sx={{ color: docsMissingFlag ? 'white' : '#95f995' }}>
                        {`Docs Complete`}
                    </Typography>

                </>

                <PDFSplitterButton
                    maxPageCount={maxPageCount}
                    RBKCaseNo={selectedRow.RBKCaseNo}
                    filetype={filetype}
                    selectedhospCaseID={selectedhospCaseID}
                    displayfileIndex={displayfileIndex}
                    setsnackbarStatus={setsnackbarStatus}
                    filename={onRowFileURLs[displayfileIndex].originalname}

            />
            </div>
            : null

        }


    </div>)



}



export default FileSelectorHeader;













