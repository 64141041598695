
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useUploadShortcutMutation = ({setsnackbarStatus, setloadingPatientsList, setUploadProgress}) => {

    const queryClient = useQueryClient();



    return useMutation({
        mutationFn: ({ formData, uploadingFileType, clickedRowCaseNo, selectedhospCaseID }) => {

            setloadingPatientsList(true);
            return axios.post(
                `${config.API_URL}/data/pushBlob?filetype=${uploadingFileType}&RBKCaseNo=${clickedRowCaseNo}&selectedUserID=${selectedhospCaseID}`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });

            queryClient.invalidateQueries({ queryKey: ['fileTokens', { RBKCaseNo: variables?.clickedRowCaseNo }, { selectedhospCaseID: variables?.selectedhospCaseID }] });

            setsnackbarStatus(
                {
                    open: true,
                    message: `Successfully Uploaded ${variables?.uploadingFileType === 'patientDoc' ? 'Documents' : 'Invoice'} for ${variables?.clickedRowCaseNo}`,
                    severity: 'success'

                }
            )
        },
        onError: (error, variables) => {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Uploading File for ${variables?.clickedRowCaseNo}`,
                    severity: 'error'

                }
            )
        },
        onSettled: (data, error, variables, context) => {
            setloadingPatientsList(false);
        }
    })






}