

import React, { useState, useEffect, useRef } from 'react';

import {
    Typography,
    IconButton,
    Divider
} from '@mui/material';



import NavigationIcon from '@mui/icons-material/Navigation';

import axios from 'axios';
import config from '../../../../../config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentTextField } from './CommentTextField';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)


const CommentsComponent = ({
    selectedRow,
    setselectedRow,
    selectedhospCaseID,
    setsnackbarStatus,
    opMode,

}) => {

    const [comment, setcomment] = useState('');

    const queryClient = useQueryClient();


    const updateCommentsMutation = useMutation({
        mutationFn: ({ updaterData }) => {

            return axios.post(
                `${config.API_URL}/data/updateComments`,
                updaterData,
                { withCredentials: true }
            )

        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setselectedRow(data.data)
            //TextLanguage["CommentAdded"][lang]
            setcomment('');
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: "Error Adding Comments", severity: 'error' })
        }
    });


    const handleAddComment = async (e) => {
        e.preventDefault();

        const updaterData = {
            comment: comment,
            selectedUserID: selectedhospCaseID,
            RBKCaseNo: selectedRow?.RBKCaseNo,
        };

        updateCommentsMutation.mutate({ updaterData: updaterData });

    };

    const lastCommentRef = useRef(null);

    useEffect(() => {
        if (lastCommentRef.current) {
          lastCommentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [selectedRow]);

    const renderComments = () => {

        const commentsArray = selectedRow?.hospCases?.find((hospCase) => hospCase.userID === selectedhospCaseID)?.comments;
        let groupedComments = [];
        let dateParseCurrent = null;
        let dateParsePrevious = null;
        let concatString = '';
        if (commentsArray?.length > 0) {
            groupedComments = commentsArray.reduce((acc, comment, index) => {

                const commentText = comment.comment;
                if (index === 0) {
                    return [{ ...comment }]
                } else {

                    dateParseCurrent = new Date(comment.commentDate);
                    dateParsePrevious = new Date(commentsArray[index - 1].commentDate);

                    if (comment.by?.type === commentsArray[index - 1].by.type && ((dateParseCurrent) - (dateParsePrevious)) <= 3600000) {
                        concatString = `${acc[acc.length - 1].comment} \n ${commentText}`
                        return [...acc.slice(0, acc.length - 1), { ...comment, comment: concatString }]
                    } else {
                        return [...acc, { ...comment }]
                    }

                }


            }, []
            )
        }
        const groupedCommentsArray = Object.values(groupedComments);

        {
            return (
                groupedCommentsArray?.map((comment, index) => {

                    const parseDate = new Date(comment.commentDate);

                    return (
                        <div
                        ref={index === groupedCommentsArray.length - 1 ? lastCommentRef : null}
                        key={`comment-root-${index}-${comment?.commentDate?.split('.')[1]}`}
                        className={`commentRow
                            ${opMode ?
                                ((comment.by?.type === "RBK") ? "commentRowMe" : "commentRowYou")
                                :
                                ((comment.by?.type === "RBK") ? "commentRowYou" : "commentRowMe")}`}

                        >
                            {(comment.by?.type === "RBK") ? // Operator Mode
                                <>
                                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: 'rgb(125,188,255)', marginRight: '5px', lineBreak: 'anywhere' }}>
                                        {`${comment.by?.label}`}
                                    </Typography>
                                    <div className={`DividerStampContainer ${opMode ? "" : "DividerStampYou"}`}>

                                        <div className='commentDateStamp'>
                                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#ffffff8a', paddingRight: '5px' }}>
                                                {`${dayjs(parseDate).fromNow()}`}
                                            </Typography>
                                        </div>
                                        <Divider orientation="horizontal" flexItem sx={{
                                            background: '#ffffff3b', width: `60%`
                                            , height: '2px', borderRadius: '16px', alignSelf: 'center'
                                        }} />

                                    </div>
                                </>
                                :
                                <>
                                    <Typography sx={{ fontSize: '1.3rem', fontWeight: 'bold', color: '#02f779bd', lineBreak: 'anywhere' }}>
                                        {`${comment.by?.label}`}
                                    </Typography>
                                    <div className={`DividerStampContainer  ${opMode ? "" : "DividerStampMe"}`} >

                                        <Divider orientation="horizontal" flexItem sx={{
                                            background: '#ffffff3b', height: '2px', width: `60%`,
                                            borderRadius: '16px', alignSelf: 'center', maxWidth: '200px'
                                        }} />
                                        <div className='commentDateStamp'>
                                            <Typography sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#ffffff8a', paddingRight: '5px' }}>
                                                {`${dayjs(parseDate).fromNow()}`}
                                            </Typography>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className={`commentElement ${comment.by?.type === "RBK" ? "commentElementOperator" : "commentElementUser"}
                                ${opMode ?
                                    (comment.by?.type === "RBK" ? "commentElementMe" : "commentElementYou")
                                    :
                                    comment.by?.type === "RBK" ? "commentElementYou" : "commentElementMe"}`}


                            >
                                <div className='commentTextContainer'>
                                    {
                                        comment?.comment?.split('\n').map((text, textIndex) => {
                                            return (
                                                <Typography key={`commLineBreakRow-${comment?.commentDate?.split('.')[1]}-${index}-${textIndex}`} sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>
                                                    {`${text}`}
                                                </Typography>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )


                })
            )

        }



    }









    return (<>

        <div className='commentsContainer'>


            <div className='commentsDisplay'>
                <div>
                    {selectedRow?.hospCases?.find(hospCase => hospCase.userID === selectedhospCaseID)?.comments?.length > 0 ? renderComments() : null}
                </div>

            </div>


            <div className='commentsInput'>

                <CommentTextField
                    id="standard-multiline-comment"
                    variant='standard'
                    multiline
                    rows={4}
                    value={comment}
                    onChange={(e) => setcomment(e.target.value)}
                />


                <div style={{ height: '100%', width: 'auto', display: 'flex', alignItems: 'center' }}>

                    <IconButton
                    disabled={updateCommentsMutation?.isPending}
                    onClick={handleAddComment}
                        sx={{

                            background: 'rgb(55, 107, 185)',
                            outline: '2px solid #7ca8c696',
                            '&:hover': {
                                transition: '0.25s ease-in-out', background: 'rgb(125,188,255)'
                            }
                        }}>



                        <NavigationIcon sx={{ transform: 'rotate(90deg)', fontSize: '1.25rem', }} />

                    </IconButton>

                </div>
            </div>


        </div>

    </>)

}



export default CommentsComponent;

























