import React, { useEffect } from 'react';
import TextLanguage from '../../../../data/TextLanguage.json';
import {
    Typography,
    TextField,
    Button
} from '@mui/material';

import { useUpdatePatientGOPMutation } from './hooks/useUpdatePatientGOPMutation';
import { useQueryClient } from '@tanstack/react-query';

const GOPInputForm = ({
    lang,
    setselectedRow,
    setsnackbarStatus,
    selectedRow,
   // handleReplaceForm,
    patientForm,
    selectedhospCaseID
}) => {


    const updatePatientGOPMutation = useUpdatePatientGOPMutation({ setselectedRow, setsnackbarStatus });


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.BanknoteSellingValue;

    /*
    const handleIntegerInput = (fieldName) => (event) => {

        const inputValue = event.target.value;

        // Check if the input is a valid integer before updating the state
        if (/^\d*$/.test(inputValue)) {

            dispatch({
                type: 'SET_FIELD',
                fieldName: fieldName,
                payload: inputValue,
            });

        }

    }
*/

    const [GOPPriceUSD, setGOPPriceUSD] = React.useState(0);
    const [GOPPriceExr, setGOPPriceExr] = React.useState(exchangeRate);
    //const [GOPPriceTL, setGOPPriceTL] = React.useState(0);


    const handleSubmitPostGOP = async (e) => { // patientForm: {RBKCaseNo, GOPPriceUSD, GOPPriceExr}
        e.preventDefault();

        updatePatientGOPMutation.mutate({
            updaterData: {
                RBKCaseNo: selectedRow?.RBKCaseNo,
                GOPPriceUSD: GOPPriceUSD,
                GOPPriceExr: GOPPriceExr,
                selectedUserID: selectedhospCaseID
            }
        })
    };

    const percentage = ((exchangeRate - GOPPriceExr) / exchangeRate * 100).toFixed(2);

    return (<div className="GOPDocumentPageInputWrapper">

        <div className='GOPDocumentPageInputContainer'>

            <div className="GOPInputFormRow">
                <TextField type="number" label="USD" InputLabelProps={{ shrink: true }}
                    value={GOPPriceUSD}
                    onChange={(e) => setGOPPriceUSD(e.target.value)} />
            </div>

            <div>
                <Typography>
                    x
                </Typography>
            </div>
            <div style={{ position: 'relative' }}>

                <div className="GOPInputFormRow">
                    <TextField type="number" label={TextLanguage["ExchangeRate"][lang]} InputLabelProps={{ shrink: true }}
                        value={GOPPriceExr}
                        onChange={(e) => setGOPPriceExr(Math.min(e.target.value, exchangeRate))} />
                </div>
                <div style={{ position: 'absolute', bottom: '-1.5rem', left: '-0.15rem' }}>

                    <Typography sx={{ fontSize: '1rem', color: 'lightgreen', fontWeight: 'bold' , textWrap:'nowrap', gap:'0.25rem'}}>
                        <span style={{color:'white'}}>{`${TextLanguage['CurrentExrRate'][lang]}:`}</span>
                        <span style={{color:'lightgreen'}}>{` ${exchangeRate} `}</span>
                        <span style={{color:'#f68128'}}>{`- ${percentage}%`}</span>
                    </Typography>
                </div>
            </div>

            <div>
                <Typography>
                    =
                </Typography>
            </div>

            <div className="GOPInputFormRow">
                <TextField type="number" label="TL" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }}
                    value={(GOPPriceUSD * GOPPriceExr).toFixed(2)}
                //onChange={}
                />
            </div>

            <div className="GOPInputFormRow">
                <Button sx={{
                    width: 150,
                    height: 50,
                    p: 2,
                    m: 2,
                    '&:hover': {
                        background: 'rgb(57, 125, 198)',
                        outline: 'rgba(191, 208, 227, 0.4) 1px solid',
                        boxShadow: '0px 0px 15px 6px rgba(125,188,255,0.15)'
                    }
                }} onClick={handleSubmitPostGOP}>
                    <Typography>{TextLanguage["Submit"][lang]}</Typography>
                    </Button>
            </div>
        </div>


    </div>);
}

export default GOPInputForm;
