import { getCookie } from '../../../functions/getCookie';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

const useUpdateFlagsMutation = ({selectedRow, selectedhospCaseID, setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ flag, state }) => {
            const token = getCookie('accessToken');
            const prevFlags = selectedRow?.hospCases.find(hospCase => hospCase.userID === selectedhospCaseID)?.flags;

            const updaterData = {
                selectedUserID: selectedhospCaseID,
                RBKCaseNo: selectedRow?.RBKCaseNo,
                flags: { ...prevFlags, [flag]: state }
            }

            return axios.post(
                `${config.API_URL}/data/updateFlags`,
                updaterData,
                {
                    withCredentials: true
                }
            )
        },
        onSuccess: (variables) => {
            //console.log(variables);

            // setselectedRow(variables.data);
            // update current row cache only? or invalidate all patients cache?
            queryClient.invalidateQueries({ queryKey: ['patients'] });
        },
        onError: (error, variables) => {
            if (error.response.status !== 403) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Updating Flags`,
                        severity: 'error'
                    }
                )

            }
        },

    })
}

export { useUpdateFlagsMutation };