import React from "react";
import { Typography } from '@mui/material';

export const formatInPatientCountPayments = (hospCaseRow) => {


    return (
        <Typography className='RowCountText'>

            {`
            ${hospCaseRow['Inpatient'] ? ((hospCaseRow['Inpatient'] / hospCaseRow.totalCount) * 100).toFixed(1) : ''}${hospCaseRow['Inpatient'] ? '%' : ''}`}

        </Typography>
    );
}