import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import {useTheme} from '@mui/material/styles';

const StyledUnderlinedButton = styled(Button)(({ ...props }) => (({ theme }) => ({

    color: theme.palette.text.primary,
    borderRadius: 0,
    height: '50px',
    width: '175px',
    alignItems: 'center',
    alignSelf: 'center',
    background: 'transparent',
    fontWeight: 'bold',
    fontSize: '0.85rem',



    zIndex: 200,


    '&:hover, &.Mui-focusVisible': {
        transition: '0.25s ease-in-out all',
        background: 'transparent',
        outline: 'none',

        boxShadow: `none`,

    },


})));


export const UnderlinedButton = ({ label, isActive,...props }) => {
    const theme = useTheme();
    return (
        <StyledUnderlinedButton disableRipple {...props}>
            <Typography sx={{
                color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
                fontWeight: 'bold',
                fontSize: '0.85rem',
                textDecoration: isActive ? 'underline' : 'none',
                '&:hover': {
                   color: theme.palette.primary.main,
                   textDecoration: 'underline',
                }

            }} variant="button">
                {label}
            </Typography>
        </StyledUnderlinedButton>
    );
}