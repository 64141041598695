import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextLanguage from '../data/TextLanguage.json';

import { useRemoveCaseMutation } from '../hooks/useRemoveCaseMutation';

export default function RemoveConfirmationDialog({
    confirmFormOpen, setConfirmFormOpen,
    rightselectedRowCaseNo, setloadingPatientsList, setsnackbarStatus, lang="enUS"
}) {

    const removeCaseMutation = useRemoveCaseMutation({setsnackbarStatus, setloadingPatientsList});



  const handleAcceptClose = () => {
    const updaterData = { RBKCaseNo: rightselectedRowCaseNo }
    removeCaseMutation.mutate({ updaterData: updaterData })
    setConfirmFormOpen(false);
  };

  const handleDeclineClose = () => {
    setConfirmFormOpen(false);
  };



  return (
    <>

      <Dialog
        open={confirmFormOpen}
        onClose={handleDeclineClose}
        aria-labelledby="remove-dialog-title"
      >
        <DialogTitle id="remove-dialog-title">
        {TextLanguage["RemoveCaseDialog"][lang]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color:'lightblue', fontWeight:'bold'}} id="alert-dialog-description">
           {`${rightselectedRowCaseNo}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeclineClose}>  {TextLanguage["No"][lang]}</Button>
          <Button onClick={handleAcceptClose} autoFocus>
              {TextLanguage["Yes"][lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}