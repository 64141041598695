import React, { useState } from "react";
import TextLanguage from "../../../data/TextLanguage.json";

import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Menu,
    MenuItem
} from '@mui/material';
import LanguageSelector from "../../LanguageSelector";

import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { BorderLinearProgress } from "../../PatientFormContainer/pages/PatientInfoPage/components/BorderLinearProgress";
import Paginator from "../Paginator";
import { FilterSelector } from "./FilterSelector";
import { useUploadExcelFilteredList } from "../../../hooks/useUploadExcelFilteredList";
import { useQueryClient } from "@tanstack/react-query";
import { fileUploader } from "../../../functions/fileUploader";


export const DataTableFooter = ({ patientList, opMode, handleformOpen, lang,
    setSearchFilter, searchFilter, uploadShortcutMutation, setsnackbarStatus,
    uploadProgress, setUploadProgress, setlang, patientDashboard
}) => {

    const queryClient = useQueryClient();


    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const [newFilePatientList, setNewFilePatientList] = useState([]);

    const uploadExcelFilteredListMutation = useUploadExcelFilteredList({ setsnackbarStatus, setUploadProgress, setNewFilePatientList });
    //onClick={fileUploader(uploadExcelFilteredListMutation, '.xls,.xlsx', { filter })}
    const [checkboxState, setCheckboxState] = useState([0, 0, 0, 0]);

    // Handle checkbox change
    const handleCheckboxChange = (index) => (event) => {
        const newState = [...checkboxState];
        newState[index] = event.target.checked ? 1 : 0;
        setCheckboxState(newState);
    };



    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleFilteredListUploadMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };


    const exportExcel = (array) => () => {
        const exportList = array.map(patient => {
            return {
                "RBK Case Number": patient.RBKCaseNo,
                "Name": patient.name,
                "Insurance Company": patient.InsuranceCo,
                "HospitalID": patient.userID,
                "Admission Date": patient.admissionDate,
                "Franchise": patient.franchise,
                "Policy No": patient.policyNo,
            }
        })

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(exportList); // Apply Filters here for filtered export!!!
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'patientList');
        });
    };

    return (<div className='maindataTableFooter'>

        <div className='dataTableHeaderBottom' style={{ padding: opMode ? '0px 0px 0px 2px' : '0px' }}>
            <div className='dataTableHeaderButtonGroup' style={{ margin: opMode ? '5px 0px' : '0px' }}>

                {opMode && <>
                    <Button sx={{ borderRadius: '0px', marginLeft: '5px', marginRight: '10px', padding: '10px 15px', maxHeight: '45px', width: 'auto' }} onClick={handleformOpen}>
                        {TextLanguage["NewPatient"][lang]}
                    </Button>
                    <Button sx={{
                        borderRadius: '0px',
                        marginLeft: '10px', marginRight: '10px', padding: '10px 15px', background: 'rgb(0, 107, 0)', maxHeight: '45px', width: 'auto',
                        '&:hover': {
                            outline: '2px solid green',
                            boxShadow: '0px 0px 8px 2px rgba(14, 225, 14, 0.65)',
                            background: 'rgb(0, 107, 0) !important',
                            filter: 'brightness(1.25) saturate(1.25)',
                        },
                    }} onClick={exportExcel(patientList)}>
                        <FileCopyIcon style={{ marginRight: "6px" }} />{TextLanguage["ExportToExcel"][lang]}
                    </Button>

                    <Button sx={{
                        borderRadius: '0px',
                        marginLeft: '10px', marginRight: '10px', padding: '10px 15px',
                        background: 'rgb(49,80,125)', maxHeight: '45px', width: 'auto',

                        '&:hover': {
                            outline: '2px solid rgba(125,188,255,0.25)',
                            boxShadow: '0px 0px 8px 2px rgb(49,80,125)',
                            background: 'rgb(49,80,125) !important',
                            filter: 'brightness(1.25) saturate(1.25)',
                        },
                    }}
                        onClick={handleFilteredListUploadMenuOpen}
                    >
                        {`${TextLanguage["UploadExcel"][lang]} LDM`}
                    </Button>
                    <Menu
                        sx={{
                            '& .MuiMenu-paper': {
                                backgroundColor: 'rgb(16, 16, 16)',
                                color: 'white',
                                borderRadius: '5px',
                            },
                            '& .MuiMenuItem-root': {
                                color: 'white',
                                padding: '0px 12px',
                            },
                            '& .MuiMenuItem-root:hover': {
                                backgroundColor: 'rgba(49,80,125,0.2)',
                            },
                            '& .MuiBackdrop-root': { background: 'transparent', backdropFilter: 'none' },
                        }}

                        id="pdfDownload-Menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >

                        <MenuItem sx={{ paddingBlock: '8px !important' }} >
                            <FormControlLabel control={<Checkbox checked={checkboxState[0] === 1} onChange={handleCheckboxChange(0)} />} label={`${TextLanguage["Documents"][lang]}`} />
                        </MenuItem>
                        <MenuItem sx={{ paddingBlock: '8px !important' }} >
                            <FormControlLabel control={<Checkbox checked={checkboxState[1] === 1} onChange={handleCheckboxChange(1)} />} label={`${TextLanguage["GOP"][lang]}`} />
                        </MenuItem>
                        <MenuItem sx={{ paddingBlock: '8px !important' }} >
                            <FormControlLabel control={<Checkbox checked={checkboxState[2] === 1} onChange={handleCheckboxChange(2)} />} label={`${TextLanguage["Proforma"][lang]}`} />
                        </MenuItem>
                        <MenuItem sx={{ paddingBlock: '8px !important' }} >
                            <FormControlLabel control={<Checkbox checked={checkboxState[3] === 1} onChange={handleCheckboxChange(3)} />} label={`${TextLanguage["Invoice"][lang]}`} />
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={fileUploader(uploadExcelFilteredListMutation, '.xls,.xlsx', { filter: JSON.stringify(checkboxState) })}
                            sx={{ paddingBlock: '8px !important' }}
                        >
                            <FileUploadIcon style={{ marginRight: "6px" }} />{TextLanguage["UploadExcel"][lang]}
                        </MenuItem>

                    </Menu>


                    {hospitalUserListData?.status === 200 &&
                    <div style={{marginInline:'0.5rem'}}>
                        <FilterSelector
                            filterLabel={"hospID"}
                            selectLabel={TextLanguage["FilterByHospital"][lang]}
                            optionsList={hospitalUserListData?.data}
                            setSearchFilter={setSearchFilter}
                            searchFilter={searchFilter}
                        />
                    </div>}




                </>

                }




            </div>

        </div>

        {(uploadShortcutMutation.isPending) &&

            <div className="dataTableFooterLoadingBar">
                <BorderLinearProgress variant='determinate' value={uploadProgress}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }} />
            </div>

        }

        <div style={{ display: 'flex', flexDirection: 'row' }}>

            <LanguageSelector lang={lang} setlang={setlang} />

            <Paginator
                component="div"
                count={patientDashboard?.totalPatientCount || 0}


                rowsPerPageOptions={[25, 50, 100]} />

        </div>

    </div>);
}
