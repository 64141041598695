
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

export const useValidateQuery = () => {


    async function validate() {


            return await axios.get(`${config.API_URL}/login/validate`, {
                withCredentials: true
            });


    };

    return useQuery(
        {
            queryKey: ['validate'],
            queryFn: validate,
            staleTime: 1000 * 60 * 30,
            refetchInterval: 1000 * 60 * 60,
        });




}