
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

export const useExchangeRateQuery = ({ opMode = true }) => {


    async function fetchExchangeRate() {
        return await axios.get(`${config.API_URL}/operator/exchangeRate`, {
            withCredentials: true
            });

    };


    return useQuery(
        {
            queryKey: ['exchangeRate'],
            queryFn: fetchExchangeRate,
            enabled: !!opMode,
            staleTime: 1000 * 60 * 60 * 1, // 6 hours
        });




}