
import TextLanguage from '../data/TextLanguage.json';
import axios from 'axios';
import config from '../config';

export default function usePaymentListExcel({lang, paymentID='active'}) {
   return async () => {

        try {

            const response = await axios.get(`${config.API_URL}/table/paymentList?lang=${lang}&paymentID=${paymentID}`, { withCredentials: true, });
            console.log(response);
            const base64Data = response.data.data;
            const arrayBuffer = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0)).buffer;
            import('file-saver').then((module) => {
                if (module && module.default) {
                    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    let EXCEL_EXTENSION = '.xlsx';
                    const data = new Blob([arrayBuffer], {
                        type: EXCEL_TYPE
                    });

                    module.default.saveAs(data, TextLanguage["PaymentList"][lang] + ' ' + response?.data?.date?.split('T')[0] + EXCEL_EXTENSION);
                }
            });


        } catch (error) {
            console.log(error);
        }

}
};
