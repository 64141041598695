import React from "react";
import { Typography } from '@mui/material';

export const formatOutPatientCountPayments = (hospCaseRow) => {


    return (
        <Typography className='RowCountText'>
            {`
            ${hospCaseRow['Outpatient'] ? ((hospCaseRow['Outpatient'] / hospCaseRow.totalCount) * 100).toFixed(1) : ''}${hospCaseRow['Outpatient'] ? '%' : ''}`}

        </Typography>
    );
}