import { Button, FormControl, MenuItem, Select, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useUploadInsDataMutation } from "./hooks/useUploadInsDataMutation";
import { fileUploader } from "../../../../functions/fileUploader";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useUploadInsDataConfirmMutation } from "./hooks/useUploadInsDataConfirmMutation";
import { BorderLinearProgress } from "../PatientInfoPage/components/BorderLinearProgress";
import { useInsuredPatientListQuery } from "./hooks/useInsuredPatientListQuery";

export const DataTableInsuranceHeader = ({ setParsedList, setsnackbarStatus, selectedInsData, setSelectedInsData, uploadMode, setUploadMode }) => {

    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadInsDataMutation = useUploadInsDataMutation({ setsnackbarStatus, setUploadProgress, setParsedList });

    const uploadInsDataConfirmMutation = useUploadInsDataConfirmMutation({ setsnackbarStatus, setParsedList });

    const [selectedInsCo, setSelectedInsCo] = useState('');


    const theme = useTheme();

    const handleTabSelection = (e, newVal) => {
        setSelectedInsData(newVal);
        uploadMode && setUploadMode(false);
    }

    const handleUploader = () => {
        if (!uploadMode) {
            setUploadMode(true);
        }
        fileUploader(uploadInsDataMutation, '.xlsx', { insuranceCo: selectedInsCo })();
    }



    const handleUploaderClear = () => {
        uploadInsDataMutation.reset();
        setUploadProgress(0);
        setParsedList([]);
    }

    const handleUploaderConfirm = () => {
        uploadInsDataConfirmMutation.mutate({ updaterData: uploadInsDataMutation.data.data });
    }




    return (<div className="InsuranceHeaderContainer">

        <div className="InsuranceUploadHeader">
            <Tabs value={selectedInsData} onChange={handleTabSelection} onClick={() => { uploadMode && setUploadMode(false) }}>
                <Tab label="VUSO" value={'VUSO'} />
                <Tab label="Donaris VIG" value={'Donaris_VIG'} />
                <Tab label="ETI Ukraine" value={'ETI_Ukraine'} />
            </Tabs>
        </div>

        {uploadInsDataMutation.isPending && <div style={{
            display: uploadInsDataMutation.isPending ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.5rem',
            width: '100%',
            maxWidth: '25rem',
            height: '2rem',


        }} className="InsuranceUploadProgress">
            <BorderLinearProgress variant="determinate" value={uploadProgress} />
        </div>}



        <div className="InsuranceUploadButtonGroup">
            <Button
                sx={{ borderRadius: '0px', padding: '10px 15px', width: 'auto', height:'100%' }}
                onClick={handleUploader}
            >
                Upload Insurance Info
            </Button>
            <div style={{height:'66%', width:'2px', background:'white'}}>

            </div>
            <FormControl
                variant='standard'
                sx={{
                    height: '100%',
                    border: 'none !important',
                    outline: 'none !important',
                    '& .MuiInputBase-root': {
                        padding: '0px 8px',
                        height: "100%", width: '10rem',
                        border: 'none !important',
                        outline: 'none !important',
                        background: 'linear-gradient(0deg, #2f2f2fc2, #5455617a)',
                        '&:before': {
                            border: 'none !important',
                            outline: 'none !important',
                        },
                    }
                }}>
                <Select
                    className="LangSelect"
                    labelId="insSelect-label"
                    id="insSelect"
                    value={selectedInsCo}
                    onChange={(e) => setSelectedInsCo(e.target.value)}
                >
                    <MenuItem value={"VUSO"}>
                        <Typography>
                            VUSO
                        </Typography>
                    </MenuItem>
                    <MenuItem value={"Donaris_VIG"}>
                        <Typography>
                            DONARIS
                        </Typography>
                    </MenuItem>
                    <MenuItem value={"ETI_Ukraine"}>
                        <Typography>
                            ETI Ukraine
                        </Typography>
                    </MenuItem>
                </Select>
            </FormControl>

            {uploadMode && <div style={{ display: uploadInsDataMutation.isSuccess ? 'flex' : 'none' }}>
                <Button sx={{
                    borderRadius: '4px 0px 0px 4px', background: theme.palette.successColor.dark,
                    '&:hover': { background: theme.palette.successColor.main, border: 'none', outline: 'none', boxShadow: 'none' }
                }}
                    onClick={handleUploaderConfirm}
                >
                    <CheckIcon />
                </Button>
                <Button sx={{
                    borderRadius: '0px 4px 4px 0px', background: theme.palette.errorColor.dark,
                    '&:hover': { background: theme.palette.errorColor.main, border: 'none', outline: 'none', boxShadow: 'none' }
                }}
                    onClick={handleUploaderClear}
                >
                    <ClearIcon />
                </Button>
            </div>}



        </div>

    </div>);
}

