import React from "react";
import { IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fileUploader } from "../../../functions/fileUploader";

import uploadTypes from "../../../data/uploadTypes";

export const CompletionBar = ({
    docProgress, RBKCaseNo, index, hospCaseAmount, barUserID, uploadShortcutMutation
}) => {

    const errorColor = "#ebc50596";
    const pendingColor = "#0062f5a1";
    const completeColor = "#0c4eb045";

    return (<div className='progressBar'>

        {
            /*
                Patient Docs Segment - Direct Upload Button or Upload Page allowing multiple file uploads, viewing files, version control etc.
            */
        }
        <div className='progressBarSegment' style={{
            borderRight: '0px', background: `${(docProgress[0] === 2) ? errorColor : (docProgress[0] === 1) ? pendingColor : completeColor}`,
            borderRadius: `${hospCaseAmount === 1 ? '20px 0px 0px 20px' : (index === 0 ? '20px 0px 0px 0px' : index === hospCaseAmount - 1 ? '0px 0px 0px 20px' : '0px')}`,
            borderBottom: `${hospCaseAmount === 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : (index === hospCaseAmount - 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : '0px')}`
        }}>
            {(docProgress[0] === 2) ? // Error
                <ErrorIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                :
                (docProgress[0] === 1) ? // Complete
                    <CheckCircleIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                    : // Pending
                    <IconButton disableRipple={true}
                        onClick={fileUploader(uploadShortcutMutation, uploadTypes, { uploadingFileType: "patientDoc", clickedRowCaseNo: RBKCaseNo, selectedhospCaseID: barUserID })}
                        className='progressBarButton'>
                        <UploadIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                    </IconButton>}


        </div>

        {
            /*
                GOP Segment -
                Hosp Account - Download Button
                Operator Account - GOP Price Page
            */
        }

        <div className='progressBarSegment' style={{
            borderRight: '0px', background: `${(docProgress[1] === 2) ? errorColor : (docProgress[1] === 1) ? pendingColor : completeColor}`,
            borderBottom: `${hospCaseAmount === 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : (index === hospCaseAmount - 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : '0px')}`
        }}>
            {(docProgress[1] === 2) ? // Error
                <ErrorIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                :
                (docProgress[1] === 1) && // Complete
                    <CheckCircleIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />

                    }
        </div>

        {
            /*
                Proforma Segment
                Hosp Account - Button to proforma creation page
                Operator Account - Display proforma invoice + patient docs (examination) for comparison/confirmation
            */
        }

        <div className='progressBarSegment' style={{
            borderRight: '0px', background: `${(docProgress[2] === 2) ? errorColor : (docProgress[2] === 1) ? pendingColor : completeColor}`,
            borderBottom: `${hospCaseAmount === 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : (index === hospCaseAmount - 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : '0px')}`
        }}>
            {(docProgress[2] === 2) ?
                <ErrorIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                :
                (docProgress[2] === 1) ?
                    <CheckCircleIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                    :
                    <IconButton disableRipple={true}
                            onClick={fileUploader(uploadShortcutMutation, uploadTypes, { uploadingFileType: "proforma", clickedRowCaseNo: RBKCaseNo, selectedhospCaseID: barUserID })}
                            className='progressBarButton'>
                            <UploadIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                        </IconButton>

                    }
        </div>

        {
            /*
                Invoice Segment
                Hosp Account - Invoice Upload
                Operator Account -  Download-preview?
            */
        }

        <div className='progressBarSegment' style={{
            background: `${(docProgress[3] === 2) ? errorColor : (docProgress[3] === 1) ? pendingColor : completeColor}`,
            borderRadius: `${hospCaseAmount === 1 ? '0px 20px 20px 0px' : (index === 0 ? '0px 20px 0px 0px' : index === hospCaseAmount - 1 ? '0px 0px 20px 0px' : '0px')}`,
            borderBottom: `${hospCaseAmount === 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : (index === hospCaseAmount - 1 ? 'solid rgba(255, 255, 255, 0.33) 1px' : '0px')}`
        }}>
            {(docProgress[3] === 2) ?
                <ErrorIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                :
                (docProgress[3] === 1) ?
                    <CheckCircleIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                    :
                        <IconButton disableRipple={true}
                            onClick={fileUploader(uploadShortcutMutation, uploadTypes, { uploadingFileType: "invoice", clickedRowCaseNo: RBKCaseNo, selectedhospCaseID: barUserID })}
                            className='progressBarButton'>
                            <UploadIcon htmlColor='white' fontSize='small' className='progressBarButtonIcon' />
                        </IconButton>
                        }
        </div>

    </div>);

}
