import React from "react";
import { Typography } from '@mui/material';

export const formatOutPatientInClinicCountPayments = (hospCaseRow) => {


    return (
        <Typography className='RowCountText'>

            {`
            ${hospCaseRow['Outpatient (In Clinic)'] ? ((hospCaseRow['Outpatient (In Clinic)'] / hospCaseRow.totalCount) * 100).toFixed(1) : ''}${hospCaseRow['Outpatient (In Clinic)'] ? '%' : ''}`}

        </Typography>
    );
}