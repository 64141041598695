import React, { memo, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import CompLogo from '../resources/RBKLogo.png';
import CompLogoSmall from '../resources/RBKLogoS.png';
import TextLanguage from '../data/TextLanguage.json';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ListIcon from '@mui/icons-material/List';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { PaymentListSelector } from './PaymentListSelector';

import { useTheme } from '@mui/material/styles';
import {
    Button,
    Tooltip,
    Typography
} from '@mui/material';
import useLocalStorage from '../hooks/useLocalStorage';
import { NavBarSwitchButton } from './NavBarSwitchButton';
import { useQueryClient } from '@tanstack/react-query';
import { useLogoutMutation } from '../hooks/useLogoutMutation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



const SideNavBar = styled(List)({
    minWidth: '70px',
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,

    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

const StyledButton = styled(ListItemButton)(({ theme }) => ({
    color: 'white',
    background: 'transparent',
    transitionDuration: '220ms',
    borderRadius: 0,
    padding: '0px 1rem',
    height: '40px',
    minHeight: '40px',
    width: '100%',
    minWidth: '8rem !important',
    alignItems: 'center',
    alignSelf: 'center',

    fontWeight: 'bold',
    fontSize: '0.75rem',
    borderBottom: '1px solid rgba(22, 22, 22, 0.5)',
    boxShadow: 'inset 0px 0px 20px 0px rgba(38, 38, 38, 0.45),0px 0px 12px -2px rgba(22, 22, 22, 0.6)',
    zIndex: 200,
    justifyContent: 'center',

    transitionProperty: 'background',
    maxHeight: '45px',

    '& .Mui-disabled': {
        backgroundColor: `red`,
    },
    '&:hover, &.Mui-focusVisible': {
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.8), rgba(66, 151, 255, 0.78))',
        boxShadow: 'inset 0px 0px 18px -2px rgb(125,188,255)',
    },


    '& .Mui-selected': {
        color: `red !important`,
        background: `white`,
        '&:hover': {
            color: `red !important`,
            background: `white`,
        }
    },

}));

const userTypesRBK = ['Admin', 'Operator', 'Expertise']

const CollapsedIcon = styled(ListItemIcon)(({ theme }) => ({
    margin: '0px !important',
    //color: currentPage === 'fileshare' ? selectionColorBlue : 'white',
    height: 45,
    alignItems: "center"

}));

const NonMemoNavBar = ({
    lang,
    currentPage,
    archivePaymentListGroupQuery,
    setselectedPaymentID = () => { },
    selectedPaymentID,
    selectedFilterUserID,
    setselectedFilterUserID = () => { },
    disablePaymentList,
    opMode,
    notificationState,
    setNotificationState,
    toggleDrawer = () => { },
    isLightMode = false
}) => {

    const theme = useTheme();
    //const selectionColor = '#80bdff';
    const selectionColorBlue = 'rgb(91, 122, 215)';
    const selectionColorRed = 'rgb(221, 53, 34)';

    const queryClient = useQueryClient();

    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const validateData = queryClient.getQueryData(['validate']);

    const AdminMode = (validateData?.data?.userID === 'Admin');

    const ClientMode = (!userTypesRBK.includes(validateData?.data?.userID));


    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    const handleListItemClick = (index) => {
        setSearchParams(prev => {
            prev.set('currentTab', index);
            return prev;
        });
    };

    const [expanded, setExpanded] = useLocalStorage('navBar', false);

    const navigate = useNavigate();

    const handleMainPaymentListButton = () => {
        navigate('/payment?currentTab=0');
    };

    const handleSizeChange = () => {
        setExpanded(!expanded);
    };


    const logoutMutation = useLogoutMutation();

    const handleLogoutButton = () => {
        console.log('logout');
        logoutMutation.mutate();

    };


    const handleNotificationsToggle = () => {
        setNotificationState(!notificationState);
    };

    useEffect(() => { // initialize selected ID first time the tab is opened
        if (currentTab === 3 && archivePaymentListGroupQuery?.data?.data?.length > 0 && selectedPaymentID === null) {
            setselectedPaymentID(archivePaymentListGroupQuery?.data?.data[0].paymentID);
        }
    }, [currentTab])


    const [selectHospitalFilterOpen, setSelectHospitalFilterOpen] = useState(false);

    const userIDLabel = selectedFilterUserID ? hospitalUserListData?.data?.find((hospital) => hospital?.value === selectedFilterUserID)?.label : 'All Hospitals';
    const handleSelectHospitalFilter = (userID) => () => {
        setSelectHospitalFilterOpen(false);
        setselectedFilterUserID(userID);
    };

    const handleClientPaymentListButton = () => {
        (currentPage !== 'payment') && navigate('/payment?currentTab=3');

    };

    return (!expanded ? <div className='NavBarBackground'>

        <SideNavBar component="nav" aria-label="PaymentList" disablePadding>

            <ListItem sx={{ justifyContent: 'center' }} >
                <div style={{ padding: '4px' }}>
                    <img src={CompLogo} alt="RBK Assistance" style={{ height: '2rem', width: 'auto', }} />
                </div>
            </ListItem>

            <Divider />

            <div className='NavBarButtonContainerHorizontal'>

                <NavBarSwitchButton
                    onClick={() => { }}
                    disableButton={true}
                    label={(isLightMode) ? TextLanguage["DarkMode"][lang] : TextLanguage["LightMode"][lang]}
                >
                    {(isLightMode) ?
                        <DarkModeIcon sx={{ fontSize: '1.5rem !important' }} />
                        :
                        <LightModeIcon sx={{ fontSize: '1.5rem !important' }} />
                    }
                </NavBarSwitchButton>

                <NavBarSwitchButton
                    onClick={() => { }}
                    disableButton={true}
                    label={TextLanguage["UserOnboardingTour"][lang]}
                >
                    <HelpOutlineIcon sx={{ fontSize: '1.5rem !important' }} />
                </NavBarSwitchButton>

                <NavBarSwitchButton
                    onClick={toggleDrawer(true)}
                    label={TextLanguage["RecentActivity"][lang]}
                    disableButton={currentPage !== 'fileshare'}
                >
                    <InfoIcon sx={{ fontSize: '1.5rem !important' }} />
                </NavBarSwitchButton>


                <NavBarSwitchButton
                    onClick={handleNotificationsToggle}
                    label={TextLanguage["Notifications"][lang]}
                    disableButton={currentPage === 'payment'}
                >
                    {(notificationState || currentPage === 'payment') ?
                        <NotificationsActiveIcon sx={{ fontSize: '1.5rem !important' }} />
                        :
                        <NotificationsOffIcon sx={{ fontSize: '1.5rem !important' }} />
                    }
                </NavBarSwitchButton>

            </div>

            <Divider />

            {AdminMode && <ListItemButton
                disableRipple
                selected={currentPage === 'admin'}
                onClick={() => { navigate('/admin') }}
            >

                <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'admin' ? selectionColorBlue : 'white' }}
                    primary={TextLanguage["AdminPanel"][lang]} />
            </ListItemButton>}



            {AdminMode && <ListItemButton
                disableRipple
                selected={currentPage === 'dashboard'}
                onClick={() => { navigate('/dashboard') }}
            >

                <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'dashboard' ? selectionColorBlue : 'white' }}
                    primary={TextLanguage["Dashboard"][lang]} />
            </ListItemButton>}

            {!ClientMode && <ListItemButton
                disableRipple
                disabled={!userTypesRBK.includes(validateData?.data?.userID)}
                selected={currentPage === 'insuranceInfo'}
                onClick={() => { navigate('/insuranceInfo') }}
            >

                <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'insuranceInfo' ? selectionColorBlue : 'white' }}
                    primary={TextLanguage["InsuranceInformation"][lang]} />
            </ListItemButton>}

            {!ClientMode && <ListItemButton
                disableRipple
                disabled={!userTypesRBK.includes(validateData?.data?.userID)}
                selected={currentPage === 'chat'}
                onClick={() => { navigate('/chat') }}
            >
                <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'chat' ? selectionColorBlue : 'white' }}
                    primary={TextLanguage["WhatsApp"][lang]} />
            </ListItemButton>}

            <ListItemButton
                disableRipple
                selected={currentPage === 'fileshare'}
                onClick={() => { navigate('/fileshare') }}
            >

                <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'fileshare' ? selectionColorBlue : 'white' }}
                    primary={TextLanguage["Fileshare"][lang]} />{ }
            </ListItemButton>

            {opMode ?
                <>
                    <ListItemButton
                        disableRipple
                        onClick={handleMainPaymentListButton}
                    >
                        <ListItemText primaryTypographyProps={{ fontSize: '0.85rem', fontWeight: 'bold', color: currentPage === 'payment' ? selectionColorBlue : 'white' }}
                            primary={TextLanguage["PaymentList"][lang]} />
                        {
                            currentPage === 'payment' && <ListItemIcon sx={{ margin: '0px !important' }}>
                                <ArrowRightIcon sx={{
                                    color: selectionColorRed,
                                    fontSize: '1.5rem',
                                    transform: 'rotate(90deg)',
                                }} />
                            </ListItemIcon>
                        }
                    </ListItemButton>
                    {currentPage === 'payment' &&
                        <List>
                            <ListItemButton
                                disableRipple
                                selected={currentTab === 0}
                                onClick={() => handleListItemClick(0)}
                            >
                                <ListItemIcon>
                                    <ChecklistIcon sx={{ color: currentTab === 0 ? selectionColorRed : 'white' }} />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                    fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 0 ? selectionColorBlue : 'white'
                                }} primary={TextLanguage["FinalizedCases"][lang]} />
                            </ListItemButton>
                            <ListItemButton
                                disableRipple
                                selected={currentTab === 1}
                                onClick={() => handleListItemClick(1)}
                            >
                                <ListItemIcon>
                                    <ListIcon sx={{ color: currentTab === 1 ? selectionColorRed : 'white' }} />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                    fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 1 ? selectionColorBlue : 'white'
                                }} primary={TextLanguage["ProcessedCases"][lang]} />
                            </ListItemButton>
                            <ListItemButton
                                disabled={disablePaymentList}
                                disableRipple
                                selected={currentTab === 2}
                                onClick={() => handleListItemClick(2)}
                            >
                                <ListItemIcon>
                                    <ReceiptLongIcon sx={{ color: currentTab === 2 ? selectionColorRed : 'white' }} />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                    fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 2 ? selectionColorBlue : 'white'
                                }} primary={TextLanguage["ActivePaymentList"][lang]} />
                            </ListItemButton>
                            <ListItemButton
                                disableRipple
                                selected={currentTab === 3}
                                onClick={() => handleListItemClick(3)}
                            >
                                <ListItemIcon>
                                    <ArchiveIcon sx={{ color: currentTab === 3 ? selectionColorRed : 'white' }} />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                    fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 3 ? selectionColorBlue : 'white'
                                }} primary={TextLanguage["CompletedPaymentList"][lang]} />
                                {currentTab === 3 &&
                                    <ListItemIcon sx={{ margin: '0px !important' }}>
                                        <ArrowRightIcon sx={{
                                            color: selectionColorRed,
                                            fontSize: '1.5rem',
                                        }} />
                                    </ListItemIcon>
                                }
                            </ListItemButton>
                            <ListItemButton
                                disableRipple
                                selected={currentTab === 4}
                                onClick={() => handleListItemClick(4)}
                            >
                                <ListItemIcon>
                                    <ArchiveTwoToneIcon sx={{ color: currentTab === 4 ? selectionColorRed : 'white' }} />
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{
                                    fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 4 ? selectionColorBlue : 'white'
                                }} primary={TextLanguage["OldPaymentList"][lang]} />
                                {currentTab === 4 &&
                                    <ListItemIcon sx={{ margin: '0px !important' }}>
                                        <ArrowRightIcon sx={{
                                            color: selectionColorRed,
                                            fontSize: '1.5rem',
                                        }} />
                                    </ListItemIcon>
                                }
                            </ListItemButton>
                        </List>
                    }

                </>
                :
                <ListItemButton
                    disableRipple
                    selected={currentPage === 'payment' && currentTab === 3}
                    onClick={handleClientPaymentListButton}

                >
                    <ListItemIcon>
                        <ArchiveIcon sx={{ color: currentTab === 3 ? selectionColorRed : 'white' }} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                        fontSize: '0.85rem', fontWeight: 'bold', color: currentTab === 3 ? selectionColorBlue : 'white'
                    }} primary={TextLanguage["CompletedPaymentList"][lang]} />
                    {currentTab === 3 &&
                        <ListItemIcon sx={{ margin: '0px !important' }}>
                            <ArrowRightIcon sx={{
                                color: selectionColorRed,
                                fontSize: '1.5rem',
                            }} />
                        </ListItemIcon>
                    }
                </ListItemButton>
            }
            <Divider />

            <div className='NavBarBottomContainer'>

                <Button sx={{
                    background: 'darkred', minWidth: '30px !important', padding: '8px 12px', borderRadius: '0px',
                    maxHeight: '58px', minHeight: '58px',
                    gap: '0.25rem',
                    '&:hover': {
                        background: 'darkred',
                        boxShadow: 'none',
                        outline: 'none',
                        border: 'none',
                    }
                }}
                    onClick={handleLogoutButton}>
                    <LogoutIcon sx={{ fontSize: '1.5rem !important' }} />
                </Button>


                <Button
                    onClick={handleSizeChange}

                    sx={{
                        margin: '0px !important', padding: '0px !important',
                        maxHeight: '58px', minHeight: '58px', minWidth: '58px', maxWidth: '58px',
                        borderRadius: '0px',
                        background: theme.palette.grey[800], '&:hover': {
                            background: theme.palette.grey[750],
                            boxShadow: 'none',
                            outline: 'none',
                            border: 'none',
                        }

                    }}>
                    <ArrowRightIcon sx={{
                        maxHeight: '58px', minHeight: '58px',
                        color: selectionColorRed,
                        fontSize: '2rem !important',
                        transform: 'rotate(180deg)',
                    }} />
                </Button>

            </div>

        </SideNavBar>


        {(currentTab === 3 && archivePaymentListGroupQuery) && <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: '12rem', minWidth: '12rem', maxWidth: '12rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                {opMode &&
                    <div style={{
                        background: 'transparent', justifyContent: 'space-between',
                        borderBottom: '1px solid rgb(61, 61, 63)',
                        display: 'flex', alignItems: 'center', width: '100%', paddingInline: '1rem',
                        height: '61px'
                    }}>

                        <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '0.75rem', paddingRight: '0.5rem' }}>
                            {userIDLabel}
                        </Typography>

                        <Button
                            onClick={() => { setSelectHospitalFilterOpen(!selectHospitalFilterOpen) }}
                            sx={{
                                '&:hover': {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    outline: 'none',
                                    border: 'none',
                                },
                                background: 'transparent !important',
                                padding: '0.5rem !important', zIndex: 1400, justifyContent: 'center',
                                margin: '0px', minWidth: '1.5rem !important', width: '1.5rem', height: '2.5rem'
                            }}
                            disableRipple={true}
                        >
                            <FilterAltIcon sx={{
                                color: 'white',
                                fontSize: '1.5rem',
                            }} />
                        </Button>


                    </div>

                }
                <PaymentListSelector
                    key={`paymentSelector-${selectedFilterUserID}`}
                    archivePaymentListGroupQuery={archivePaymentListGroupQuery}
                    setselectedPaymentID={setselectedPaymentID}
                    selectedPaymentID={selectedPaymentID}
                />
            </div>
            {selectHospitalFilterOpen &&
                <List sx={{
                    minWidth: '15rem', maxWidth: '15rem', position: 'absolute', top: '0px', left: '12rem', zIndex: 14000, background: 'rgb(37, 38, 40)',
                    borderBottom: '12px solid rgb(61, 61, 63)',
                    height: '100vh', overflowY: 'scroll', boxShadow: 'inset 0px -20px 20px -20px rgba(0, 0, 0, 0.65), 8px 0px 8px 0px rgba(0, 0, 0, 0.25)',
                }}>
                    <StyledButton

                        onClick={handleSelectHospitalFilter(null)}
                    >{"All Hospitals"}</StyledButton>
                    {hospitalUserListData?.data?.map((hospital) => {
                        return (<StyledButton
                            onClick={handleSelectHospitalFilter(hospital?.value)}
                        >{hospital?.label}</StyledButton>)
                    })}
                </List>
            }

        </div>
        }





    </div>
        :
        <div className='NavBarBackground' >

            <SideNavBar component="nav" disablePadding>

                <ListItem alignItems='flex-start' sx={{ padding: '0px', width: '100%', justifyContent: "center", height: '60px', alignItems: 'center' }}>
                    <div style={{}}>
                        <img src={CompLogoSmall} style={{ height: '2rem', width: 'auto', }} />
                    </div>
                </ListItem>
                <Divider />

                <div className='NavBarButtonContainerVertical'>

                    <NavBarSwitchButton
                        onClick={() => { }}
                        disableButton={true}
                        label={(isLightMode) ? TextLanguage["DarkMode"][lang] : TextLanguage["LightMode"][lang]}
                    >
                        {(isLightMode) ?
                            <DarkModeIcon sx={{ fontSize: '1.5rem !important' }} />
                            :
                            <LightModeIcon sx={{ fontSize: '1.5rem !important' }} />
                        }
                    </NavBarSwitchButton>

                    <NavBarSwitchButton
                        onClick={() => { }}
                        disableButton={true}
                        label={TextLanguage["UserOnboardingTour"][lang]}
                    >
                        <HelpOutlineIcon sx={{ fontSize: '1.5rem !important' }} />
                    </NavBarSwitchButton>

                    <NavBarSwitchButton
                        onClick={toggleDrawer(true)}
                        label={TextLanguage["RecentActivity"][lang]}
                        disableButton={currentPage !== 'fileshare'}
                    >
                        <InfoIcon sx={{ fontSize: '1.5rem !important' }} />
                    </NavBarSwitchButton>

                    <NavBarSwitchButton
                        onClick={handleNotificationsToggle}
                        label={TextLanguage["Notifications"][lang]}
                        disableButton={currentPage === 'payment'}
                    >
                        {(notificationState || currentPage === 'payment') ?
                            <NotificationsActiveIcon sx={{ fontSize: '1.5rem !important' }} />
                            :
                            <NotificationsOffIcon sx={{ fontSize: '1.5rem !important' }} />
                        }
                    </NavBarSwitchButton>

                </div>

                <Divider />

                {AdminMode && <Tooltip
                    placement="right"
                    title={TextLanguage["AdminPanel"][lang]}
                >

                    <span>
                        <ListItemButton
                            disableRipple
                            sx={{
                                minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                            }}
                            selected={currentPage === 'admin'}
                            onClick={() => { navigate('/admin') }}
                        >
                            <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'admin' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                                <AdminPanelSettingsIcon sx={{ fontSize: '1.5rem !important' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </span>


                </Tooltip>}



                {AdminMode && <Tooltip
                    placement="right"
                    title={TextLanguage["Dashboard"][lang]}
                >

                    <span>
                        <ListItemButton
                            disableRipple
                            sx={{
                                minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                            }}
                            selected={currentPage === 'dashboard'}
                            onClick={() => { navigate('/dashboard') }}
                        >
                            <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'dashboard' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                                <DashboardIcon sx={{ fontSize: '1.5rem !important' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </span>


                </Tooltip>}

               {!ClientMode &&  <Tooltip
                    placement="right"
                    title={TextLanguage["InsuranceInformation"][lang]}
                >

                    <span>
                        <ListItemButton
                            disableRipple
                            disabled={!userTypesRBK.includes(validateData?.data?.userID)}
                            sx={{
                                minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                            }}
                            selected={currentPage === 'insuranceInfo'}
                            onClick={() => { navigate('/insuranceInfo') }}
                        >
                            <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'insuranceInfo' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                                <AssignmentIcon sx={{ fontSize: '1.5rem !important' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </span>


                </Tooltip>}


               {!ClientMode && <Tooltip
                    placement="right"
                    title={TextLanguage["WhatsApp"][lang]}
                >

                    <span>
                        <ListItemButton
                            disableRipple
                            disabled={!userTypesRBK.includes(validateData?.data?.userID)}
                            sx={{
                                minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                            }}
                            selected={currentPage === 'chat'}
                            onClick={() => { navigate('/chat') }}
                        >
                            <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'chat' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                                <WhatsAppIcon sx={{ fontSize: '1.9rem !important' }} />
                            </ListItemIcon>
                        </ListItemButton>
                    </span>


                </Tooltip>}


                <Tooltip
                    placement="right"
                    title={TextLanguage["Fileshare"][lang]}
                >

                    <ListItemButton
                        disableRipple
                        sx={{
                            minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                        }}
                        selected={currentPage === 'fileshare'}
                        onClick={() => { navigate('/fileshare') }}
                    >
                        <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'fileshare' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                            <FormatListBulletedIcon sx={{ fontSize: '1.5rem !important' }} />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>



                <Tooltip
                    placement="right"
                    title={TextLanguage["PaymentList"][lang]}
                >
                    <ListItemButton
                        disableRipple
                        sx={{
                            minWidth: '30px !important', padding: '0px !important', justifyContent: "center", alignItems: "center"
                        }}
                        onClick={handleMainPaymentListButton}
                    >
                        <ListItemIcon sx={{ margin: '0px !important', color: currentPage === 'payment' ? selectionColorBlue : 'white', height: 45, alignItems: "center" }}>
                            {
                                currentPage === 'payment' ?
                                    <ArrowRightIcon sx={{ fontSize: '1.5rem !important', transform: 'rotate(90deg)' }} />
                                    :
                                    <ChecklistIcon sx={{ fontSize: '1.5rem !important' }} />
                            }
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>

                {currentPage === 'payment' &&

                    <List>
                        {
                            opMode && <>
                                <ListItemButton
                                    disableRipple
                                    selected={currentTab === 0}
                                    onClick={(event) => handleListItemClick(0)}
                                >
                                    <CollapsedIcon>
                                        <ChecklistIcon sx={{ color: currentTab === 0 ? selectionColorRed : 'white' }} />
                                    </CollapsedIcon>

                                </ListItemButton>
                                <ListItemButton
                                    disableRipple
                                    selected={currentTab === 1}
                                    onClick={(event) => handleListItemClick(1)}
                                >
                                    <CollapsedIcon>
                                        <ListIcon sx={{ color: currentTab === 1 ? selectionColorRed : 'white' }} />
                                    </CollapsedIcon>

                                </ListItemButton>
                                <ListItemButton
                                    disabled={disablePaymentList}
                                    disableRipple
                                    selected={currentTab === 2}
                                    onClick={(event) => handleListItemClick(2)}
                                >
                                    <CollapsedIcon>
                                        <ReceiptLongIcon sx={{ color: currentTab === 2 ? selectionColorRed : 'white' }} />
                                    </CollapsedIcon>

                                </ListItemButton>
                                <ListItemButton
                                    disableRipple
                                    selected={currentTab === 3}
                                    onClick={() => handleListItemClick(3)}
                                >
                                    <CollapsedIcon>
                                        <ArchiveIcon sx={{ color: currentTab === 3 ? selectionColorRed : 'white' }} />
                                    </CollapsedIcon>



                                </ListItemButton>
                                <ListItemButton
                                    disableRipple
                                    selected={currentTab === 4}
                                    onClick={(event) => handleListItemClick(4)}
                                >
                                    <CollapsedIcon>
                                        <ArchiveTwoToneIcon sx={{ color: currentTab === 4 ? selectionColorRed : 'white' }} />
                                    </CollapsedIcon>



                                </ListItemButton>
                            </>
                        }
                    </List>
                }

                <Divider />

                <div className='NavBarBottomContainer'>
                    <Button
                        onClick={handleSizeChange}
                        sx={{
                            margin: '0px !important',
                            width: '100%',
                            padding: '0px !important',
                            borderRadius: '0px',
                            background: theme.palette.grey[800], '&:hover': {
                                background: theme.palette.grey[750],
                                boxShadow: 'none',
                                outline: 'none',
                                border: 'none',
                            }
                        }}>
                        <ArrowRightIcon sx={{
                            maxHeight: '58px', minHeight: '58px',
                            color: selectionColorRed,
                            fontSize: '2rem !important',
                        }} />

                    </Button>
                </div>

            </SideNavBar>

            {(currentTab === 3 && archivePaymentListGroupQuery) && <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: '12rem', minWidth: '12rem', maxWidth: '12rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    {opMode &&
                        <div style={{
                            background: 'transparent', justifyContent: 'space-between',
                            borderBottom: '1px solid rgb(61, 61, 63)',
                            display: 'flex', alignItems: 'center', width: '100%', paddingInline: '1rem',
                            height: '61px'
                        }}>

                            <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '0.75rem', paddingRight: '0.5rem' }}>
                                {userIDLabel}
                            </Typography>

                            <Button
                                onClick={() => { setSelectHospitalFilterOpen(!selectHospitalFilterOpen) }}
                                sx={{
                                    '&:hover': {
                                        background: 'transparent',
                                        boxShadow: 'none',
                                        outline: 'none',
                                        border: 'none',
                                    },
                                    background: 'transparent !important',
                                    padding: '0.5rem !important', zIndex: 1400, justifyContent: 'center',
                                    margin: '0px', minWidth: '1.5rem !important', width: '1.5rem', height: '2.5rem'
                                }}
                                disableRipple={true}
                            >
                                <FilterAltIcon sx={{
                                    color: 'white',
                                    fontSize: '1.5rem',
                                }} />
                            </Button>


                        </div>

                    }
                    <PaymentListSelector
                        archivePaymentListGroupQuery={archivePaymentListGroupQuery}
                        setselectedPaymentID={setselectedPaymentID}
                        selectedPaymentID={selectedPaymentID}
                    />
                </div>
                {selectHospitalFilterOpen &&
                    <List sx={{
                        minWidth: '15rem', maxWidth: '15rem', position: 'absolute', top: '0px', left: '12rem', zIndex: 14000, background: 'rgb(37, 38, 40)',
                        borderBottom: '12px solid rgb(61, 61, 63)',
                        height: '100vh', overflowY: 'scroll', boxShadow: 'inset 0px -20px 20px -20px rgba(0, 0, 0, 0.65), 8px 0px 8px 0px rgba(0, 0, 0, 0.25)',
                    }}>
                        <StyledButton onClick={handleSelectHospitalFilter(null)}>
                            {"All Hospitals"}
                        </StyledButton>
                        {hospitalUserListData?.data?.map((hospital) => {
                            return (<StyledButton
                                onClick={handleSelectHospitalFilter(hospital?.value)}
                            >{hospital?.label}</StyledButton>)
                        })}
                    </List>
                }

            </div>
            }
        </div>
    );
}
const NavigationBar = memo(NonMemoNavBar);
export default NavigationBar;


