import React from "react";
import { Typography } from '@mui/material';
import numeral from "numeral";

export const formatAvgUSDPayments = (hospCaseRow) => {

    const parsedPriceAvg = numeral(hospCaseRow.GOPTotalUSD / hospCaseRow.totalCount).format('0,0.00');

    return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

        <Typography className='RowPriceText'>
            {`$ ${parsedPriceAvg}`}
        </Typography>
    </div>
    );
}
