import React from "react";
import { Divider, Typography } from '@mui/material';
import numeral from "numeral";


export const formatGOPPriceGroup = (patientRow) => {
        return (
            patientRow?.hospCases?.map(

                (hospCase, index) => {

                    const parsedPriceUSD = numeral(hospCase.GOPPriceUSD).format('0,0.00');
                    const parsedPriceTL = numeral(hospCase.GOPPriceTL / 100).format('0,0.00');

                    return (<div className="GOPPriceRowGroup">


                        <Typography className='RowPriceText'>
                            {`$ ${parsedPriceUSD}`}
                        </Typography>
                    <Divider orientation="vertical" flexItem />

                        <Typography className='RowPriceText'>
                        {`${((hospCase.GOPPriceTL / 100) / hospCase.GOPPriceUSD).toFixed(1)}`}
                    </Typography>

                    <Divider orientation="vertical" flexItem />

                    <Typography className='RowPriceText'>
                        {`${parsedPriceTL} ₺`}
                    </Typography>
                    </div>
                    )
                }
            )


        );
    }

