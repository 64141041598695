
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useCreatePaymentListMutation = ({ setsnackbarStatus, setPaymentListModalOpen, setmodalPosition }) => {

    const queryClient = useQueryClient();

    return useMutation({

        mutationFn: ({ updaterData }) => {


            return axios.post(
                `${config.API_URL}/payment/createActivePaymentList`,
                updaterData,
                {
                    withCredentials: true

                }

            );
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'] });
            queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'] });



            queryClient.invalidateQueries({ queryKey: ['ActivePaymentListAggr'] });
            queryClient.invalidateQueries({ queryKey: ['ActivePaymentListGroup'] });
            setPaymentListModalOpen(false);
            setmodalPosition('100%');


            setsnackbarStatus(
                {
                    open: true,
                    message: `Create Active PaymentList Successful`,
                    severity: 'success'
                }
            )

        },
        onError: (error, variables, context) => {
            console.log(error);


            if (error?.response?.status === 406) {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Payment List Already Active`,
                        severity: 'error'
                    }
                )
            } else {
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Failed to Create Active Payment List`,
                        severity: 'error'
                    }
                )
            }
        }
    })




}