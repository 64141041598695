
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useFileTokensQuery = ({selectedRow, selectedhospCaseID}) => {



    async function updateTokensFn({ queryKey }) {
        const [_key, CaseNoObj, hospIDObj] = queryKey;
        console.log('qKey:', queryKey)

        return await axios.get(`${config.API_URL}/data/fileTokens?RBKCaseNo=${CaseNoObj.RBKCaseNo}&selectedUserID=${hospIDObj.selectedhospCaseID}`, {
            withCredentials: true
        })

    }

    return  useQuery(
        {
            queryKey: ['fileTokens',
                { RBKCaseNo: selectedRow?.RBKCaseNo },
                { selectedhospCaseID: selectedhospCaseID },
            ],
            queryFn: updateTokensFn,
            enabled: !!selectedhospCaseID,
            retry: (failureCount, error) => {
                return (failureCount < 4 && error.response?.status !== 404)
             },

        }
    )




}