import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CommentTextField = styled(TextField)(({ theme }) => ({
    height: '100%',

    padding: '5px 10px',
    width: '100%',
    borderRadius: '8px 8px 0px 0px',
    color: 'white',
    boxShadow: 'inset 0px 0px 12px 0px rgba(0, 0, 0, 0.67)',

    '& .MuiFormControl-root:hover .MuiInput-underline::before': {
        borderBottom: 'none',
    },

    '& .MuiInput-underline:before': {
        border: 'none'
    },
    '& .MuiInput-underline:hover': {
        borderBottom: 'none',
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottom: 'none',
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
}));
