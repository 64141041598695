import { styled } from '@mui/material/styles';
import {  Switch } from '@mui/material';
const CheckSwitch = styled(Switch)(({ theme }) => ({
    marginBlock: 'auto',
    width: 62,
    height: 34,
    padding: 8,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#50f755',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {

                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
                    '#22fc96',
                )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>')`,

            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#50575ed4' : '#40454ad4',
                border: '2px solid rgb(3, 140, 253)'
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        border: '1px solid rgb(194, 213, 229)',
        backgroundColor: theme.palette.mode === 'dark' ? '#333638' : '#1b1f22',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
                '#fabd05',
            )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        border: '2px solid rgb(3, 140, 253)',
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#50575ed4' : '#40454ad4',
        borderRadius: 20 / 2,
    },
}));


export default CheckSwitch;