import React from 'react';
import { Typography, Button } from '@mui/material';
import TextLanguage from '../../../data/TextLanguage.json';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';

const BreadcrumbButton = styled(Button)(({ theme }) => ({
    fontSize: '1.5rem',
    color: 'white',
    background: 'transparent',
    border: 'none',
    padding: '0px',
    margin: '0px',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',

    zIndex: 200,


    '&:hover, &.Mui-focusVisible': {
        background: 'transparent !important',
        color: 'white !important',
        boxShadow: 'none',
        outline: 'none',
        border: 'none',

        textDecoration: 'underline',

    },


    '& .Mui-selected': {
        color: `red !important`,
        background: `white`,
        '&:hover': {
            color: `red !important`,
            background: `white`,
        }
    },

}));




export const BreadcrumbPayment = ({
    rootLabel,
    selectedItem,
    clearSelectedItem,
    lang
}) => {

    const queryClient = useQueryClient();
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);


    const label = rootLabel ? rootLabel : TextLanguage['PaymentList'][lang];


    return <div className='breadcrumbPaymentContainer'>

        <BreadcrumbButton
            disableRipple
            sx={{
                fontSize: '1rem',
                color: !!selectedItem ? 'lightgray' : 'white',
                textDecoration: !!selectedItem ? 'underline' : 'none', '&:hover, &.Mui-focusVisible': {
                    textDecoration: !!selectedItem ? 'underline' : 'none'
                }
            }}
            onClick={clearSelectedItem}
        >
            {`${label}`}
        </BreadcrumbButton>
        {!!selectedItem && <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'center', alignItems: 'center', }}>
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: '1rem' }} />
            <Typography sx={{ fontSize: '1rem' }}>
                {hospitalUserListData?.data?.find((hosp) => hosp.value === selectedItem)?.label}
            </Typography>
        </div>}
    </div>;
}
