import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../../../config';

const useUpdatePatientMutation = ({setformEditmodeDisplay, setselectedRow}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ patientForm, appendedData }) => {


            return axios.post(
                `${config.API_URL}/data/updatePatient`,
                {
                    ...patientForm,
                    ...appendedData
                },
                {
                    withCredentials: true
                }
            );
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['patients'] });
            queryClient.invalidateQueries({ queryKey: ['activityLog'] });
            setTimeout(() => { setformEditmodeDisplay(true); }, 1000);
            setselectedRow(data.data);
        }
    });
}

export { useUpdatePatientMutation };