import React from "react";
import { Typography } from '@mui/material';

import { interpolateColor } from "../../../functions/interpolateColor";
import TextLanguage from "../../../data/TextLanguage.json";

export const formatDateDeadline =  ({lang}) => {
return (patientRow) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {patientRow?.hospCases?.map(hospCase => {

                const parsedInvDate = new Date(hospCase.Invoice_Date);
                const day = parsedInvDate.getDate().toString().padStart(2, '0');
                const month = (parsedInvDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const year = parsedInvDate.getFullYear();
                const reformattedDate = `${month}/${day}/${year}`
                const Deadline = new Date(parsedInvDate.setDate(parsedInvDate.getDate() + 60)); // 60 days from invoice date or 45?
                const deadlineDays = Math.floor((Deadline - Date.now()) / (1000 * 60 * 60 * 24))
                return (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 1rem 0 0' }}>
                        <Typography sx={{ fontSize: '1rem' }}>
                            {reformattedDate}
                        </Typography>
                        <Typography sx={{ fontSize: '1rem', color: interpolateColor(deadlineDays, 1, 60, "hsl(0, 100%, 44%)", "hsl(98, 99%, 44%)"), fontWeight: 'bold' }}>

                            {`${deadlineDays} ${TextLanguage["Days"][lang]}`}
                        </Typography>
                    </div>
                )


            })}
        </div>
    );


};

}