import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DateFilter = ({ onFilter, startLabel, endLabel }) => {
    const [selectedDateStart, setselectedDateStart] = useState(null);
    const [selectedDateEnd, setselectedDateEnd] = useState(null);
    const handleDateChangeStart = (newDate) => {
        setselectedDateStart(newDate);
        onFilter({
            admissionDateStart:  newDate.$d
        });
    };

    const handleDateChangeEnd = (newDate) => {
        setselectedDateEnd(newDate);
        onFilter({
            admissionDateEnd: newDate.$d
        });
    };

    return (
        <div className='CustomFilterContainer' style={{marginRight:'40px'}}>
            <DatePicker

                label={startLabel}
                value={selectedDateStart}
                onChange={handleDateChangeStart}
                sx={{ width: `${startLabel?.length*1.25}ch`, minWidth: '150px' }}
                TextFieldComponent={TextField}

            />
            <div>
            <ChevronRightIcon/>
            </div>
            <DatePicker
                label={endLabel}
                value={selectedDateEnd}
                sx={{width: `${endLabel?.length*1.25}ch`, minWidth: '150px', '& .MuiInputBase-root':{
                  background:'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',

                  width:'200px !important'

                } }}
                onChange={handleDateChangeEnd}
                TextFieldComponent={TextField}

            />
            <Button
             onClick={()=>{
                setselectedDateStart(null);
                setselectedDateEnd(null);
                onFilter({
                    admissionDateStart:  null,
                    admissionDateEnd: null
                });
            }

        } sx={{

                background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                height: '56px !important',
                left:'38px',
                borderRadius: '0px',
                minWidth: '50px !important',
                border:'1px solid #7c7e86',
                borderLeft:'none',
                width: '50px !important',
                zIndex: 100,
                '&:hover': {
                    border:'2px solid #000',
                    outline:'none',
                    borderLeft:'none',
                    boxShadow: 'none',
                    background: 'linear-gradient(to top, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 36%) 60%)',
                    filter:'none'
                },
            }}>
            <CancelIcon/>
            </Button>
        </div>
    );
};

export default DateFilter;
