import React, { useState, useRef } from 'react';
import PatientEditForm from './components/PatientEditForm';
import CreatePatientForm from './components/CreatePatientForm';
import PatientInfoComponent from './components/PatientInfoComponent';
import { useUploadMutation } from './hooks/useUploadMutation';
import { fileUploader } from '../../../../functions/fileUploader';




const PatientInfoPageComponent = ({

    formCreateMode,
    selectedRow, opMode,
    lang,
    selectedhospCaseID, setselectedhospCaseID,
    setselectedRow, setsnackbarStatus, patientForm, dispatch,

    handleFieldChange,


    onRowFileURLs,
    setformOpen,


}) => {


    const currencies = [
        {
            value: '25$',
            label: '25 $',
        },
        {
            value: '30$',
            label: '30 $',
        },
        {
            value: '25€',
            label: '25 €',
        },
        {
            value: '30€',
            label: '30 €',
        },
    ];










    const [formEditmodeDisplay, setformEditmodeDisplay] = useState(true); // in display mode show the retrieved values





    return (<>



        {formCreateMode ?
            // CREATE NEW ITEM MODE - FORM
            <CreatePatientForm
                lang={lang}
                patientForm={patientForm}
                handleFieldChange={handleFieldChange}
                currencies={currencies}
                setformOpen={setformOpen}
                setselectedRow={setselectedRow}
                setsnackbarStatus={setsnackbarStatus}
                dispatch={dispatch}
            />
            :

            (
                formEditmodeDisplay ?
                    <PatientInfoComponent
                        selectedRow={selectedRow}
                        opMode={opMode}
                        setformEditmodeDisplay={setformEditmodeDisplay}
                        lang={lang}
                        selectedhospCaseID={selectedhospCaseID}
                        setselectedhospCaseID={setselectedhospCaseID}
                        setselectedRow={setselectedRow}
                        setsnackbarStatus={setsnackbarStatus}
                        onRowFileURLs={onRowFileURLs}
                        patientForm={patientForm}
                    />
                    : // EDIT MODE - FORM

                    <PatientEditForm
                        dispatch={dispatch}
                        setformEditmodeDisplay={setformEditmodeDisplay}
                        selectedRow={selectedRow}
                        setselectedRow={setselectedRow}
                        opMode={opMode}
                        lang={lang}
                        patientForm={patientForm}
                        handleFieldChange={handleFieldChange}
                        currencies={currencies}
                    />
            )
}

    </>);
}

export default PatientInfoPageComponent;



