
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useFileFetchQuery = ({selectedRow, selectedhospCaseID, fetchedFileType, displayfileIndex, onRowFileURLs}) => {


    async function fileFetchFn({ queryKey }) {
        const [_key, QuerySelection] = queryKey;
        console.log(QuerySelection);

        return await axios.get(`${config.API_URL}/pdf/fetchFile?filetype=${QuerySelection.fetchedFileType}&RBKCaseNo=${QuerySelection.RBKCaseNo}&selectedUserID=${QuerySelection.selectedhospCaseID}&fileIndex=${QuerySelection.displayfileIndex}`, {
            responseType: 'blob',
            withCredentials: true
        });



    }

    return useQuery({
        queryKey: ['fileFetch',
            {
                RBKCaseNo: selectedRow?.RBKCaseNo,
                selectedhospCaseID: selectedhospCaseID,
                fetchedFileType: fetchedFileType,
                displayfileIndex: displayfileIndex
            }

        ],
        queryFn: fileFetchFn,
        retry: (failureCount, error ) => {
            return (failureCount < 2 && (error.response?.status !== 404 || error.response?.status !== 401))
        },
        enabled: (!!selectedhospCaseID && selectedRow?.hospCases?.find(hospCase => ((hospCase?.userID === selectedhospCaseID)))?.files?.length > 0),
        refetchOnWindowFocus: false,



    });




}