import React from "react";
import { Typography } from '@mui/material';


export const formatDateAdm = (patientRow) => {
        const parsedDate = new Date(patientRow.admissionDate);
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = parsedDate.getFullYear();
        const reformattedDate = `${month}/${day}/${year}`
        return (
            <Typography sx={{ fontSize: '1rem' }}>
                {reformattedDate}
            </Typography>);

};

